<script lang="ts">
  import { createEventDispatcher, getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { RoleModel } from '../../../models/role.model';
  import { roleService } from '../../../services/role.service';
  import { Roles } from '../../../stores/roles.store';

  export let deletedRole: RoleModel;
  const dispatch = createEventDispatcher();
  const { close } = getContext('simple-modal');

  function deleteJob(deletedRole: RoleModel) {
    void roleService.delete(deletedRole.id)?.then(res => {
      if (res) {
        Roles.update(roles =>
          roles.filter((role: RoleModel) => role.id !== deletedRole.id)
        );
        close();
      }
    });
  }
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('role.deletion.title')} - {deletedRole?.nameTranslated}
  </h2>
</div>

<h3 class="vtmn-p-4">{$_('role.deletion.subtitle')}</h3>

<div class="vtmn-flex vtmn-flex-row vtmn-justify-evenly vtmn-mt-3 modal-content">
  <button
    class=" vtmn-w-2/5 vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
    on:click|preventDefault={close}>
    {$_('global.buttons.cancel')}
  </button>
  <button
    class="vtmn-w-2/5 vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small"
    on:click|preventDefault={deleteJob(deletedRole)}>
    {$_('role.deletion.confirm')}
  </button>
</div>
