import ApiService from './api.service';
import { BranchContributorModel } from '../models/branchContributor.model';
class BranchContributorService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/branch_contributors';
        this.entity = BranchContributorModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const branchContributorService = new BranchContributorService();
