import { JobTranslationModel } from './jobTranslation.model';
import { BranchModel } from './branch.model';
import { CountryModel } from './country.model';
export class JobModel {
    constructor(data = {}) {
        var _a, _b, _c, _d, _e;
        this.internalTranslationTranslated = '';
        this.externalTranslationTranslated = '';
        this.id = data.id;
        this.shortName = data.short_name;
        this.status = data.status;
        this.translations = [];
        this.branches = [];
        this.countries = [];
        this.responsibilityCount = (_a = data === null || data === void 0 ? void 0 : data.responsibility_count) !== null && _a !== void 0 ? _a : 0;
        this.ownersCount = (_b = data === null || data === void 0 ? void 0 : data.job_owner_count) !== null && _b !== void 0 ? _b : 0;
        if (data.countries && ((_c = data.countries) === null || _c === void 0 ? void 0 : _c.length) > 0) {
            data.countries.forEach((country) => {
                const countryModel = new CountryModel();
                countryModel.code = country;
                this.countries.push(countryModel);
            });
        }
        if (data.job_translations) {
            data.job_translations.forEach((translation) => {
                var _a;
                const jobTranslation = new JobTranslationModel(translation);
                (_a = this.translations) === null || _a === void 0 ? void 0 : _a.push(jobTranslation);
            });
        }
        if (data.job_translated) {
            this.internalTranslationTranslated = (_d = data.job_translated) === null || _d === void 0 ? void 0 : _d.internal_translation;
            this.externalTranslationTranslated = (_e = data.job_translated) === null || _e === void 0 ? void 0 : _e.external_translation;
        }
        if (data.branches) {
            data.branches.forEach((branch) => {
                var _a;
                const jobBranch = new BranchModel();
                jobBranch.id = branch;
                (_a = this.branches) === null || _a === void 0 ? void 0 : _a.push(jobBranch);
            });
        }
    }
}
