import { branchesService } from '../../../services/branches.service';
import { skillsService } from '../../../services/skills.service';
import { BranchModel } from '../../../models/branch.model';
import { SkillModel } from '../../../models/skill.model';
export async function retrieveDataTranslation(id, isBranch) {
    return isBranch ? retrieveBranchTranslationById(id) : retrieveSkillTranslationById(id);
}
export async function retrieveBranchTranslationById(id) {
    var _a;
    let branch = new BranchModel();
    await ((_a = branchesService === null || branchesService === void 0 ? void 0 : branchesService.get(id)) === null || _a === void 0 ? void 0 : _a.then(b => {
        branch = b;
    }));
    return branch === null || branch === void 0 ? void 0 : branch.labelTranslated;
}
export async function retrieveSkillTranslationById(id) {
    var _a;
    let skill = new SkillModel();
    await ((_a = skillsService === null || skillsService === void 0 ? void 0 : skillsService.get(id)) === null || _a === void 0 ? void 0 : _a.then(s => {
        skill = s;
    }));
    return skill === null || skill === void 0 ? void 0 : skill.labelTranslated;
}
