<script lang="ts">
  import AutoComplete from 'simple-svelte-autocomplete';
  import { useForm } from 'svelte-use-form';
  import { _ } from 'svelte-i18n';
  import { getContext } from 'svelte';
  import { ResponsibilityModel } from '../../../models/responsibility.model';
  import { SkillModel } from '../../../models/skill.model';
  import { skillsService } from '../../../services/skills.service';
  import { responsibilitySkillService } from '../../../services/responsibilitySkill.service';
  import {
    ResponsibilitySkillsKey,
    ResponsibilitySkillsNotKey,
  } from '../../../stores/responsibilitySkill.store';
  import { ResponsibilitySkillModel } from '../../../models/responsibilitySkill.model';

  export let currentResponsibility: ResponsibilityModel;
  let selectedSkill: SkillModel;
  let isKey = false;

  const { close } = getContext('simple-modal');
  const jobForm = useForm();

  function createLink() {
    void responsibilitySkillService
      .create({
        skill_id: selectedSkill?.id,
        responsibility_id: currentResponsibility?.id,
        skill_key: isKey,
      })
      .then(res => {
        if (res) {
          let responsibilitySkillCreated = new ResponsibilitySkillModel(res);
          responsibilitySkillCreated.skill = selectedSkill;
          responsibilitySkillCreated.responsibility = currentResponsibility;
          isKey
            ? ($ResponsibilitySkillsKey = [
                ...$ResponsibilitySkillsKey,
                responsibilitySkillCreated,
              ])
            : ($ResponsibilitySkillsNotKey = [
                ...$ResponsibilitySkillsNotKey,
                responsibilitySkillCreated,
              ]);
          close();
        }
      });
  }

  async function searchSkill(keyword: string) {
    let listSkills = [];
    listSkills = await skillsService?.getAllWithPagination({
      params: { label: keyword },
    });
    let filteredListSkills = listSkills[0].filter((res: SkillModel[]) => {
      return (
        !$ResponsibilitySkillsKey.find(element => res.id === element.skill.id) &&
        !$ResponsibilitySkillsNotKey.find(element => res.id === element.skill.id)
      );
    });
    return filteredListSkills;
  }
</script>

<form use:jobForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {$_('skill.modification.link')}
    </h2>
  </div>

  <div class="vtmn-flex vtmn-flex-col vtmn-justify-around vtmn-h-4/6 modal-content">
    <p class="control-label">
      {$_('responsibility.name.single')} : {currentResponsibility?.labelTranslated}
    </p>

    <div class="input-wrapper">
      <label for="skillToLink" class="control-label">{$_('skill.name.single')}</label>
      <AutoComplete
        searchFunction={searchSkill}
        bind:selectedItem={selectedSkill}
        labelFieldName="labelTranslated"
        placeholder={$_('skill.name.single')}
        delay="200"
        localFiltering="false"
        inputId="skillToLink"
        name="skillToLink">
        <div slot="item" let:item>
          <div>{item?.labelTranslated}</div>
          <div class="vtmn-text-grey-light-1">{item?.fullLabelTranslated}</div>
        </div>
      </AutoComplete>
    </div>

    <div class="input-wrapper">
      <input
        bind:checked={isKey}
        id="isKey"
        class="control-label vtmn-w-auto vtmn-my-auto"
        type="checkbox"
        name="isKey" />
      <label for="isKey" class="control-label vtmn-w-auto vtmn-my-auto"
        >{$_('skill.key')} ?</label>
    </div>

    <div
      class="vtmn-p-5 vtmn-w-full vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-between">
      <button
        class="vtmn-float-left vtmn-btn vtmn-btn_variant--secondary vtmn-border vtmn-btn_size--small
            vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        on:click|preventDefault={close}>
        {$_('job.cancel')}
      </button>
      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        disabled={!$jobForm.valid}
        on:click|preventDefault={createLink}>
        {$_('job.validate')}
      </button>
    </div>
  </div>
</form>
