import ApiService from './api.service';
import { JobResponsibilityModel } from '../models/jobResponsibility.model';
export class JobResponsibilityService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/job_responsibility';
        this.entity = JobResponsibilityModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const jobResponsibilityService = new JobResponsibilityService();
