<script lang="ts">
  import { getContext, onMount } from 'svelte';
  import SortableList from 'svelte-sortable-list';
  import { _ } from 'svelte-i18n';
  import { JobResponsibility } from '../../../stores/jobResponsibility.store';
  import { JobResponsibilityModel } from '../../../models/jobResponsibility.model';
  import { jobResponsibilityService } from '../../../services/jobResponsibility.service';
  import RawResponsibilityOrder from './RawResponsibilityOrder.svelte';

  const { close } = getContext('simple-modal');
  let loading = true;
  export let jobResponsibilityList;
  export let countryCode;

  function handleList(ev = undefined) {
    if (ev) {
      jobResponsibilityList = ev.detail;
    }
    jobResponsibilityList.forEach((jobResponsibility, i) => {
      jobResponsibility.order = i + 1;
      if (i === jobResponsibilityList.length - 1) {
        loading = false;
      }
    });
  }

  function patchNewOrder() {
    let body = [];
    jobResponsibilityList.forEach(function (value: JobResponsibilityModel) {
      let object = {
        id: value.id,
        responsibility_order: value.order,
      };
      body.push(object);
    });
    const countryToSend = countryCode !== '' ? countryCode : 'UNITED';
    jobResponsibilityService.patch('country_id/' + countryToSend, body).then(() => {
      $JobResponsibility[countryCode] = jobResponsibilityList;
      close();
    });
  }

  onMount(() => {
    handleList();
  });
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('global.buttons.order')}
  </h2>
</div>

{#if loading === false}
  <div class="vtmn-flex vtmn-flex-col vtmn-justify-around vtmn-h-4/6 modal-content">
    <SortableList list={jobResponsibilityList} key="order" on:sort={handleList} let:item>
      <RawResponsibilityOrder jobResponsibility={item} />
    </SortableList>

    <div class="vtmn-p-2">
      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
        on:click|preventDefault={patchNewOrder}>
        {$_('global.buttons.validate')}
      </button>
    </div>
  </div>
{/if}
