<script lang="ts">
  import { UserInfos } from '../../../stores/userInfo.store';
  import { onMount } from 'svelte';
  import { rightsUserService } from '../../../services/rightsUser.service';
  import { RightsUserModel } from '../../../models/rightsUserModel';
  import FlatTable from '../../../components/Generics/Table/FlatTable.svelte';
  import { _ } from 'svelte-i18n';
  import { branchesService } from '../../../services/branches.service';
  import RawBranch from '../../../components/Contents/Branches/RawBranch.svelte';
  import { BranchModel } from '../../../models/branch.model';
  import { Branches } from '../../../stores/branches.store';
  import {
    loadBranchLeadersNames,
    sortBranches,
  } from '../../../components/Contents/Branches/branches.utils';

  let branches: BranchModel[] = [];
  let loading = true;
  let tableBranchesHeaders = [
    { size: '1/6', title: 'branches.table.code' },
    { size: '1/4', title: 'branches.table.name' },
    { size: '2/12', title: 'skill.modification.leader' },
    { size: '1/12', title: 'job.name.plural' },
    { size: '1/12', title: 'role.name.plural' },
    { size: '1/12', title: 'skill.name.plural' },
    { size: '1/4', title: 'global.actions' },
  ];

  function loadBranches() {
    void branchesService.getAll().then((res: Array<BranchModel>) => {
      Branches.set(sortBranches(res));
      void loadBranchLeadersNames($Branches, $_('branches.table.noresponsible'));
      void retrievesUserRights();
    });
  }

  function retrievesUserRights() {
    rightsUserService.getAll()?.then(res => {
      // We check user rights again even if we already did it at the start of the app, because rights can change anytime between pages
      if (res.length > 0 && res[0] instanceof RightsUserModel) {
        $UserInfos.rights = res;
        void displayBranchesDependingOnRights();
      }
    });
  }

  function displayBranchesDependingOnRights() {
    branches = [];
    for (const right: RightsUserModel of $UserInfos.rights) {
      if (right?.branch_id) {
        const branch = $Branches?.find(b => b.id === right?.branch_id);
        branches = [...branches, branch];
      }
    }
    loading = false;
  }

  onMount(() => {
    if ($Branches.length === 0) {
      // If branches have never been loaded (in another page for example), we load them
      void loadBranches();
    } else {
      // Else, we load contributors only
      void loadBranchLeadersNames($Branches, $_('branches.table.noresponsible'));
      void retrievesUserRights();
    }
    Branches.subscribe(() => void displayBranchesDependingOnRights()); // When Branches store changes, we update the table displayed
  });
</script>

{#if $UserInfos}
  <h1 class="vtmn-text-xl vtmn-ml-0 vtmn-my-auto vtmn-mr-auto">
    {$_('rights.branches')}
  </h1>
  <FlatTable tableHeaders={tableBranchesHeaders} bind:loading row={3}>
    <span slot="body" class="vtmn-contents">
      {#if branches.length > 0}
        {#each branches as branch}
          {#if branch?.id}
            <RawBranch bind:branch i={$Branches.findIndex(b => b.id === branch?.id)} />
          {/if}
        {/each}
      {:else}
        <td colspan="6" class="vtmn-p-4 vtmn-text-center"> {$_('search.none')} </td>
      {/if}
    </span>
  </FlatTable>
{/if}
