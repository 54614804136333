<script lang="ts">
  import { UserInfos } from '../../../stores/userInfo.store';
  import { Skills } from '../../../stores/skills.store';
  import { _ } from 'svelte-i18n';
  import RawSkill from './RawSkill.svelte';
  import { isAdmin } from '../../Auth/rights.utils';

  let openModal;
  $: hasSkillsRights = isAdmin($UserInfos);
</script>

{#if $Skills?.length > 0}
  {#each $Skills as skill, i}
    <RawSkill bind:skill />
  {/each}
{:else}
  <td colspan="5" class="vtmn-p-4 vtmn-text-center">
    {$_('search.none')}
  </td>
{/if}
