<style>
  select {
    background-color: white;
    padding: var(--vtmn-spacing_2) var(--vtmn-spacing_3);
    font-size: 0.875rem;
    line-height: 1.25rem;
    height: unset;
  }
</style>

<script lang="ts">
  import { onMount, setContext } from 'svelte';
  import Modal from 'svelte-simple-modal';
  import { _ } from 'svelte-i18n';
  import FlatTable from '../../Generics/Table/FlatTable.svelte';
  import ModalButton from '../../Generics/Modal/ModalButton.svelte';
  import Pagination from '../../Generics/Paginator/Pagination.svelte';
  import { PaginationModel } from '../../../models/pagination.model';
  import { BranchModel } from '../../../models/branch.model';
  import { params, goto } from '@roxi/routify';
  import { UserInfos } from '../../../stores/userInfo.store';
  import { Branches } from '../../../stores/branches.store';
  import { branchesService } from '../../../services/branches.service';
  import { sortBranches } from '../Branches/branches.utils';
  import { Roles } from '../../../stores/roles.store';
  import { roleService } from '../../../services/role.service';
  import ListRoles from './ListRoles.svelte';
  import CreateRole from './CreateRole.svelte';
  import { isAdmin } from '../../Auth/rights.utils';
  import BranchFilter from '../Branches/BranchFilter.svelte';
  import BottomPageLogo from '../../Navigation/BottomPageLogo.svelte';

  export let currentBranch: BranchModel = undefined;
  export let title: string;
  export let selectedBranches: BranchModel[] = [];
  export let createOption = true;
  let timer;
  let translationFilter = '';
  let preferredLanguage = $UserInfos.preferredLanguage;
  $: slug = $params.slug;
  let pagination: PaginationModel = new PaginationModel();
  let current = 1;
  let loading = true;
  let openModal;
  const colorIcon = '#404040';
  const sizeIcon = '1.5em';
  $: hasRolesRights = isAdmin($UserInfos);
  setContext('roles', Roles);

  function retrieveRoles(evt = undefined): void {
    loading = true;
    if (evt != undefined) {
      current = evt.detail;
    }
    let params = {
      params: {
        page: current - 1,
        translation: translationFilter,
      },
    };
    if (slug != undefined) {
      params.params['branch_id'] = slug;
    }
    roleService.getAllWithPagination(params).then(res => {
      Roles.set(res[0]);
      pagination = res[1];
      loading = false;
      retrievesBranches();
    });
  }

  function retrieveBranchesLinkedToRoles() {
    $Roles?.forEach(role => {
      role?.branches?.forEach(branchRole => {
        branchRole.labelTranslated = $Branches.find(
          branch => branch.id === branchRole.id
        )?.labelTranslated;
      });
    });
    Roles.set($Roles);
  }

  const handleBranchFilter = (branchToRedirect = undefined) => {
    branchToRedirect?.id ? $goto('/roles/' + branchToRedirect.id) : $goto('/roles/');
  };

  function onInputSearches() {
    if (translationFilter === '') {
      void retrieveRoles();
    } else {
      //Timeout to avoid multiple API calls
      clearTimeout(timer);
      timer = setTimeout(() => {
        void retrieveRoles();
      }, 500);
    }
  }

  function retrievesBranches() {
    if ($Branches.length === 0) {
      void branchesService.getAll().then((res: Array<BranchModel>) => {
        Branches.set(sortBranches(res));
        retrieveBranchesLinkedToRoles();
      });
    } else {
      retrieveBranchesLinkedToRoles();
    }
  }

  onMount(() => {
    void retrieveRoles();
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  {#if title != undefined}
    <h1 class="vtmn-font-semi-bold vtmn-text-5xl vtmn-text-center">{title}</h1>
  {/if}
  {#if hasRolesRights && createOption}
    <Modal>
      <ModalButton>
        <button
          class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
          on:click={openModal(CreateRole, { selectedBranches })}>
          {$_('role.create')}
        </button>
      </ModalButton>
    </Modal>
  {/if}
</div>
<FlatTable bind:loading col="3">
  <span slot="headers" class="vtmn-contents">
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-6/12">
      <input
        type="search"
        placeholder={$_('branches.table.name')}
        bind:value={translationFilter}
        on:input={onInputSearches}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <BranchFilter bind:currentBranch {handleBranchFilter} size="2/12" />
    <th class="vtmn-px-3 vtmn-py-2 vtmn-font-thin vtmn-w-2/12"
      >{$_('responsibility.name.plural')}</th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12">{$_('global.actions')}</th>
  </span>

  <span slot="body" class="vtmn-contents">
    <ListRoles />
  </span>
</FlatTable>

<div class="vtmn-flex">
  <BottomPageLogo />
  <div class="vtmn-ml-auto vtmn-mr-2">
    <Pagination
      small
      bind:current
      num_items={pagination?.numItems}
      fromElement={pagination?.from}
      toElement={pagination?.to}
      per_page={pagination?.perPage}
      on:navigate={retrieveRoles} />
  </div>
</div>

<Modal>
  <ModalButton bind:openModal />
</Modal>
