import { _, init, locale, register } from 'svelte-i18n';
import '../../public/lang/en.json';
import '../../public/lang/en.json';
function registerLanguages() {
    register('en', () => import('../../public/lang/en.json'));
    register('fr', () => import(`../../public/lang/fr.json`));
}
function initI18n(locale) {
    init({
        fallbackLocale: 'en',
        initialLocale: locale === null || locale === void 0 ? void 0 : locale.toLowerCase(),
    });
}
registerLanguages();
export { _, locale, initI18n };
