<script lang="ts">
  import { branchContributorService } from '../../../../services/branchContributor.service';
  import type { BranchContributorModel } from '../../../../models/branchContributor.model';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import FlatTr from '../../../Generics/Table/FlatTr.svelte';
  import FlatTable from '../../../Generics/Table/FlatTable.svelte';
  import FlatTd from '../../../Generics/Table/FlatTd.svelte';
  import ValidationQuestion from '../../../Generics/Question/ValidationQuestion.svelte';
  import CreateContributor from './CreateContributor.svelte';
  import { userService } from '../../../../services/user.service';
  import type { UserInfo } from '../../../../models/userInfo.model';
  import { BranchContributors } from '../../../../stores/branchContributors.store';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import type { BranchModel } from '../../../../models/branch.model';
  import { Countries } from '../../../../stores/countries.store';
  import { countryService } from '../../../../services/country.service';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { isAdmin, isBranchLeader } from '../../../Auth/rights.utils';
  import { getCountryFromCode } from '../../Countries/countries.util';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import EditContributor from './EditContributor.svelte';
  import UserImage from '../../User/UserImage.svelte';

  export let branch: BranchModel;
  $: hasBranchesRights = isAdmin($UserInfos) || isBranchLeader($UserInfos, [branch]);
  let loading = true;
  let contributorToEdit: BranchContributorModel;
  let contributorToRemove: BranchContributorModel;
  let listBranchContributors: BranchContributorModel[] = [];
  let tableHeaders = [
    { size: '2/7', title: 'branch_contributor.table.name' },
    { size: '3/7', title: 'branch_contributor.table.country' },
    { size: '2/7', title: 'global.actions' },
  ];

  function removeContributor() {
    if (contributorToRemove) {
      void branchContributorService.delete(contributorToRemove?.id).then(value => {
        if (value) {
          $BranchContributors = $BranchContributors.filter(
            contrib => contrib.id != contributorToRemove.id
          );
          contributorToRemove = undefined;
        }
      });
    }
  }

  onMount(() => {
    $BranchContributors = [];
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
      });
    }

    branchContributorService
      .getAll({ params: { branch_id: branch.id } })
      ?.then((res: BranchContributorModel[]) => {
        if (res.length !== 0) {
          for (const branchContrib of res) {
            if (branchContrib.contributor.uuid != undefined) {
              userService
                .getAll({ params: { ldap_uuid: branchContrib.contributor.uuid } })
                ?.then((users: UserInfo[]) => {
                  if (users.length > 0) {
                    branchContrib.contributor = users[0];
                  } else {
                    branchContrib.contributor.displayName = $_('global.unknown_user');
                  }
                  BranchContributors.update(contributors => [
                    ...contributors,
                    branchContrib,
                  ]);
                  loading = false;
                });
            }
          }
        } else {
          loading = false;
        }
      });
  });
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('branch_contributor.title')} - {branch.labelTranslated}
  </h2>
</div>

<div class="modal-content">
  <FlatTable {tableHeaders} bind:loading row={3}>
    <span slot="body" class="vtmn-contents">
      {#if hasBranchesRights}
        <CreateContributor branchId={branch.id} />
      {/if}
      {#if $BranchContributors.length > 0}
        {#each $BranchContributors as branchContributor, i}
          <FlatTr>
            <FlatTd>
              {#if branchContributor.contributor?.fullName}
                <div class="vtmn-flex vtmn-flex-row vtmn-items-center">
                  <UserImage uid={branchContributor.contributor.uid} />
                  <div>{branchContributor.contributor?.fullName}</div>
                </div>
              {/if}
            </FlatTd>
            {#if contributorToEdit?.contributor.uuid === branchContributor.contributor.uuid}
              <FlatTd>
                <EditContributor bind:contributorToEdit />
              </FlatTd>
            {:else}
              <FlatTd>
                {#if branchContributor.united || branchContributor?.countries?.length === 0}
                  United
                {:else}
                  {#each branchContributor.countries as country}
                    <span class="countryFlag"
                      >{getCountryFromCode(country?.code)?.flag}</span
                    >{' ' + getCountryFromCode(country?.code)?.name} <br />
                  {/each}
                {/if}
              </FlatTd>
            {/if}
            {#if hasBranchesRights}
              <FlatTd>
                <div
                  class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
                  {#if contributorToRemove?.contributor?.uuid === branchContributor?.contributor?.uuid}
                    <ValidationQuestion
                      on:validate={() => {
                        removeContributor(contributorToRemove);
                      }}
                      on:cancel={() => {
                        contributorToRemove = undefined;
                      }} />
                  {:else}
                    <button
                      class="vtmn-cursor-pointer vtmn-my-auto vtmn-ml-2 vtmn-mr-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full"
                      on:click={() => {
                        contributorToEdit = branchContributor;
                      }}>
                      <Pencil color="#404040" size="1.5em" />
                    </button>
                    <button
                      class="vtmn-cursor-pointer vtmn-my-auto vtmn-ml-auto vtmn-mr-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full"
                      on:click={() => {
                        contributorToRemove = branchContributor;
                      }}>
                      <Delete color="#404040" size="1.5em" />
                    </button>
                  {/if}
                </div>
              </FlatTd>
            {/if}
          </FlatTr>
        {/each}
      {:else}
        <div class="vtmn-p-4 vtmn-text-base">{$_('branch_contributor.empty')}</div>
      {/if}
    </span>
  </FlatTable>
</div>
