<script lang="ts">
  import { maxLength, required, useForm, validators } from 'svelte-use-form';
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { responsibilityService } from '../../../services/responsibility.service';
  import { ResponsibilityModel } from '../../../models/responsibility.model';
  import { Responsibilities } from '../../../stores/responsibilities.store';
  import { ResponsibilityCreationStep } from '../../../stores/responsibilityCreationJourney.store';

  const { close } = getContext('simple-modal');
  let label: string;
  let description: string;
  const responsibilityForm = useForm();

  function createResponsibility() {
    void responsibilityService
      .create({
        responsibility_translations: [
          { language_id: 'en-001', label: label, full_label: description },
        ],
      })
      .then(res => {
        if (res) {
          let responsibilityCreated = new ResponsibilityModel(res);
          $Responsibilities = [...$Responsibilities, responsibilityCreated];
          $ResponsibilityCreationStep = 2;
        }
      });
  }
</script>

<form use:responsibilityForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {$_('responsibilities.modification.create')}
    </h2>
  </div>

  <div
    class="vtmn-flex vtmn-flex-col vtmn-text-center vtmn-justify-around vtmn-h-4/6 modal-content">
    <div class="input-wrapper">
      <label for="label" class="control-label">{$_('responsibilities.table.name')}</label>
      <input
        bind:value={label}
        class="vtmn-block vtmn-w-full form-control vtmn-sm:text-sm"
        id="label"
        name="label"
        placeholder={$_('responsibilities.modification.name')}
        type="text"
        use:validators={[required, maxLength(120)]} />
    </div>
    <div
      class="vtmn-flex vtmn-justify-end {label?.length > 120
        ? 'control-label-danger'
        : 'control-label'}">
      {label?.length || 0}/120
    </div>

    <div class="input-wrapper">
      <label for="description" class="control-label"
        >{$_('responsibilities.table.description')}</label>
      <textarea
        bind:value={description}
        class="vtmn-block vtmn-w-full form-control vtmn-sm:text-sm"
        id="description"
        name="description"
        placeholder={$_('responsibilities.modification.description')}
        type="text"
        use:validators={[required, maxLength(250)]} />
    </div>
    <div
      class="vtmn-flex vtmn-justify-end {description?.length > 250
        ? 'control-label-danger'
        : 'control-label'}">
      {description?.length || 0}/250
    </div>

    <div class="vtmn-p-2">
      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
        disabled={!$responsibilityForm.valid}
        on:click|preventDefault={createResponsibility}>
        {$_('global.buttons.validate')}
      </button>
    </div>
  </div>
</form>
