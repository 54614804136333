<style>
  .border-blue {
    border-color: lightsteelblue;
  }
</style>

<script lang="ts">
  import AutoComplete from 'simple-svelte-autocomplete';
  import { maxLength, required, validators, useForm } from 'svelte-use-form';
  import { JobService, jobService } from '../../../services/job.service';
  import { JobModel } from '../../../models/job.model';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import type { BranchModel } from '../../../models/branch.model';
  import { Branches } from '../../../stores/branches.store';
  import type { CountryModel } from '../../../models/country.model';
  import { countryService } from '../../../services/country.service';
  import { getContext } from 'svelte';
  import Close from 'svelte-material-icons/Close.svelte';
  import { branchesService } from '../../../services/branches.service';
  import { Jobs } from '../../../stores/job.store';
  import { Countries } from '../../../stores/countries.store';
  import { JobLength } from '../../../shared/job.shared';
  import { StatusList } from '../../../stores/status.store';
  import { isAdmin } from '../../Auth/rights.utils';
  import { UserInfos } from '../../../stores/userInfo.store';
  const { close } = getContext('simple-modal');

  export let selectedBranches: BranchModel[] = [];
  export let jobToEdit: JobModel;
  const statusEditable = $StatusList.filter(s => s.status !== 'DRAFT');
  let statusSelected =
    $StatusList.find(s => s.status === jobToEdit?.status) ??
    $StatusList.filter(s => s.status === 'DRAFT');
  let perimeter = 'UNITED';
  let jobCode: string = jobToEdit?.shortName;
  let jobTranslationExt: string;
  let jobTranslationIn: string;
  let listBranches: BranchModel[] = [];
  let listCountries: CountryModel[];
  let selectedCountries: CountryModel[] = [];
  let serviceRolesOrJob: JobService;
  $: canEditMainInformationsJob = isAdmin($UserInfos);

  const jobForm = useForm();

  function prepareData() {
    let countries = [];
    if (selectedCountries.length > 0 && perimeter === 'LOCAL') {
      for (const country of selectedCountries) {
        countries.push(country.code);
      }
    }
    let branches = [];
    if (selectedBranches.length > 0) {
      for (const branch of selectedBranches) {
        branches.push(branch.id);
      }
    }

    const body = {
      short_name: jobCode,
      countries: countries,
      branches: branches,
      status: statusSelected?.status ?? 'DRAFT',
    };

    if (jobToEdit) {
      void modifyJob(body);
    } else {
      void createJob(body);
    }
  }
  function createJob(body) {
    // Initialize translations
    body.job_translations = [
      {
        language_id: 'en-001',
        external_translation: jobTranslationExt,
        internal_translation: jobTranslationIn,
      },
    ];

    void jobService.create(body).then(res => {
      if (res) {
        let jobCreated = new JobModel(res);
        jobCreated.branches?.forEach(branchJob => {
          branchJob.labelTranslated = $Branches.find(
            branch => branch.id == branchJob.id
          )?.labelTranslated;
        });
        $Jobs = [jobCreated, ...$Jobs];
        close();
      }
    });
  }

  function modifyJob(body) {
    void jobService.patch(jobToEdit.id, body).then(res => {
      if (res) {
        let jobToEdit = new JobModel(res);
        jobToEdit.branches?.forEach(branchJob => {
          branchJob.labelTranslated = $Branches.find(
            branch => branch.id == branchJob.id
          )?.labelTranslated;
        });
        const index = $Jobs.findIndex(jobs => jobs.id === jobToEdit.id);
        $Jobs[index] = jobToEdit;
        close();
      }
    });
  }

  async function formInit() {
    // Get countries if not loaded
    if ($Countries.length === 0) {
      await countryService?.getAll()?.then(res => {
        Countries.set(res);
      });
    }
    listCountries = $Countries;

    // Get Branches if not loaded
    if ($Branches.length === 0) {
      await branchesService?.getAll()?.then(res => {
        Branches.set(res);
      });
    }
    listBranches = $Branches;

    // Init autocomplete
    if (selectedBranches.length === 0 && jobToEdit) {
      selectedBranches = jobToEdit.branches;
    }
    if (jobToEdit && jobToEdit.countries.length !== 0) {
      perimeter = 'LOCAL';
      selectedCountries = jobToEdit.countries;
    }

    // Remove existing branches to branches input if exists
    selectedBranches?.forEach(branch => {
      listBranches = listBranches.filter(branches => branches.id !== branch.id);
    });
    selectedCountries?.forEach((country, index) => {
      selectedCountries[index] = $Countries.find(
        countries => countries.code == country.code
      );
      listCountries = listCountries.filter(countries => countries.code !== country.code);
    });
  }

  function actionBranch() {
    void prepareData();
  }

  onMount(() => {
    void formInit();
  });
</script>

<form use:jobForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {jobToEdit ? $_('job.modification.edit') : $_('job.create')}
    </h2>
  </div>

  <div class="vtmn-flex vtmn-flex-col vtmn-justify-around vtmn-h-4/6 modal-content">
    {#if !jobToEdit || canEditMainInformationsJob}
      <div class="input-wrapper">
        <label for="jobCode" class="control-label">{$_('job.table.code')}</label>
        <input
          bind:value={jobCode}
          class="vtmn-block vtmn-w-full vtmn-rounded vtmn-sm:text-sm form-control"
          inputId="jobCode"
          name="jobCode"
          placeholder={$_('job.modification.code_job_roles_placeholder')}
          type="text"
          use:validators={[required, maxLength(12)]} />
      </div>
      <div
        class="vtmn-flex vtmn-justify-end {jobCode?.length > 12
          ? 'control-label-danger'
          : 'control-label'}">
        {jobCode?.length || 0}/12
      </div>
    {/if}
    {#if !jobToEdit}
      <div class="input-wrapper">
        <label for="jobTranslationIn" class="control-label"
          >{$_('job.table.decath_name')}</label>
        <input
          bind:value={jobTranslationIn}
          class="vtmn-block vtmn-w-full vtmn-rounded vtmn-sm:text-sm form-control"
          id="jobTranslationIn"
          name="jobTranslationIn"
          placeholder={$_('job.modification.internal_translation')}
          type="text"
          use:validators={[required, maxLength(JobLength)]} />
      </div>
      <div
        class="vtmn-flex vtmn-justify-end {jobTranslationIn?.length > JobLength
          ? 'control-label-danger'
          : 'control-label'}">
        {jobTranslationIn?.length || 0}/{JobLength}
      </div>

      <div class="input-wrapper">
        <label for="jobTranslationExt" class="control-label"
          >{$_('job.table.outside_decath_name')}</label>
        <input
          bind:value={jobTranslationExt}
          class="vtmn-block vtmn-w-full vtmn-rounded vtmn-sm:text-sm form-control"
          id="jobTranslationExt"
          name="jobTranslationExt"
          placeholder={$_('job.modification.external_translation')}
          type="text"
          use:validators={[required, maxLength(JobLength)]} />
      </div>
      <div
        class="vtmn-flex vtmn-justify-end {jobTranslationExt?.length > JobLength
          ? 'control-label-danger'
          : 'control-label'}">
        {jobTranslationExt?.length || 0}/{JobLength}
      </div>
    {/if}

    {#if !jobToEdit || canEditMainInformationsJob}
      <div class="input-wrapper">
        <label for="jobBranches" class="control-label"
          >{$_('job.modification.branches')}</label>
        <AutoComplete
          multiple="true"
          bind:items={listBranches}
          bind:selectedItem={selectedBranches}
          labelFieldName="labelTranslated"
          delay="200"
          localFiltering="false"
          placeholder={$_('job.modification.branches_placeholder')}
          maxItemsToShowInList="1000"
          inputId="jobBranches"
          inputClassName="form-control">
          <div
            slot="tag"
            class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start vtmn-bg-grey-light-6 vtmn-rounded-full"
            let:label
            let:item
            let:unselectItem>
            <span class="vtmn-p-1">{label}</span>
            <button
              class="vtmn-p-1 vtmn-border-none"
              on:click|preventDefault={unselectItem(item)}>
              <Close color="#404040" />
            </button>
          </div>
        </AutoComplete>
      </div>
    {/if}

    <div class="input-wrapper">
      <label class="control-label">{$_('job.table.perimeter')}</label>
      <label class="vtmn-pr-2">
        <input type="radio" bind:group={perimeter} name="perimeter" value={'UNITED'} />
        UNITED
      </label>

      <label>
        <input type="radio" bind:group={perimeter} name="perimeter" value={'LOCAL'} />
        LOCAL
      </label>
    </div>

    {#if perimeter === 'LOCAL'}
      <div class="input-wrapper">
        <label for="jobCountries" class="control-label"
          >{$_('job.modification.countries')}</label>
        <AutoComplete
          multiple="true"
          items={listCountries}
          bind:selectedItem={selectedCountries}
          labelFieldName="name"
          maxItemsToShowInList="1000"
          placeholder={$_('job.modification.countries_placeholder')}
          inputId="jobCountries"
          inputClassName="form-control">
          <div let:item slot="item">
            <span class="countryFlag">{item?.flag}</span>{' ' + item.name}
          </div>
          <div
            slot="tag"
            class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start vtmn-bg-grey-light-6 vtmn-rounded-full"
            let:label
            let:item
            let:unselectItem>
            <span class="vtmn-p-1"
              ><span class="countryFlag">{item?.flag}</span>{' ' + item?.name}</span>
            <button
              class="vtmn-p-1 vtmn-border-none"
              on:click|preventDefault={unselectItem(item)}>
              <Close color="#404040" />
            </button>
          </div>
        </AutoComplete>
      </div>
    {/if}

    {#if jobToEdit && canEditMainInformationsJob}
      <div class="input-wrapper">
        <label for="jobStatus" class="control-label">Status</label>
        <AutoComplete
          inputId="jobStatus"
          inputClassName="form-control"
          items={statusEditable}
          labelFieldName="translation"
          valueFieldName="status"
          bind:selectedItem={statusSelected} />
      </div>
      {#if statusSelected?.status !== jobToEdit?.status}
        <div
          class="vtmn-bg-blue-100 vtmn-border-t vtmn-border-b vtmn-text-blue-700 vtmn-px-4 vtmn-py-3 border-blue"
          role="alert">
          <p class="vtmn-font-semi-bold">
            {$_('job.status.change', {
              values: {
                jobName: jobToEdit?.internalTranslationTranslated,
                oldStatus: $_('validation.' + jobToEdit?.status?.toLowerCase()),
                newStatus: statusSelected?.translation,
              },
            })}
          </p>
          <div class="vtmn-text-sm vtmn-mt-1">
            <p>
              {$_('validation.warning.' + statusSelected?.status?.toLowerCase())}
            </p>
          </div>
        </div>
      {/if}
    {/if}

    <div
      class="vtmn-p-5 vtmn-w-full vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-between">
      <button
        class="vtmn-float-left vtmn-btn vtmn-btn_variant--secondary vtmn-border vtmn-btn_size--small
            vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        on:click|preventDefault={close}>
        {$_('job.cancel')}
      </button>

      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        disabled={!$jobForm.valid ||
          (selectedCountries?.length <= 0 && perimeter !== 'UNITED')}
        on:click|preventDefault={actionBranch}>
        {$_('job.validate')}
      </button>
    </div>
  </div>
</form>
