<style>
  .vtmn-btn {
    color: white !important;
  }

  .vtmn-btn:hover {
    text-decoration: none;
  }

  svg {
    margin: auto;
  }
</style>

<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { branchesService } from '../../services/branches.service';
  import { onMount } from 'svelte';
  import type { BranchModel } from '../../models/branch.model';
  import FlatTable from '../../components/Generics/Table/FlatTable.svelte';
  import Modal from 'svelte-simple-modal';
  import CreateBranch from '../../components/Contents/Branches/CreateBranch.svelte';
  import ModalButton from '../../components/Generics/Modal/ModalButton.svelte';
  import { Branches } from '../../stores/branches.store';
  import { UserInfos } from '../../stores/userInfo.store';
  import {
    loadBranchLeadersNames,
    sortBranches,
  } from '../../components/Contents/Branches/branches.utils';
  import { isAdmin } from '../../components/Auth/rights.utils';
  import RawBranch from '../../components/Contents/Branches/RawBranch.svelte';

  $: hasBranchesAdminRights = isAdmin($UserInfos);
  let openModal;
  let loading = true;
  let listBranches: BranchModel[] = []; // Due to filters, we need another list to display results. The stores $Branches will contain all the branches, and listBranches only the branches displayed
  let counter = 0;
  let codeSearch = '';
  let responsibleSearch = '';
  let nameSearch = '';
  let timer;

  function loadBranches() {
    void branchesService.getAll().then((res: Array<BranchModel>) => {
      Branches.set(sortBranches(res));
      void loadBranchLeadersNames($Branches, $_('branches.table.noresponsible'));
      loading = false;
    });
  }

  function loadBranchesByName() {
    void branchesService
      .getAll({ params: { label: nameSearch } })
      .then((branchesResult: Array<BranchModel>) => {
        listBranches = [];
        for (const branch of branchesResult) {
          listBranches.push($Branches.find(b => b.id === branch.id));
        }
        loading = false;
        localFilter(); // Add other filters if necessary
      });
  }

  function localFilter() {
    listBranches = listBranches.filter((branch: BranchModel) => {
      // Second, filter with javascript filter
      return (
        branch.shortName.toLowerCase().includes(codeSearch.toLowerCase()) &&
        branch.responsibleUser?.fullName
          ?.toLowerCase()
          .includes(responsibleSearch.toLowerCase())
      );
    });
  }

  // This function is called everytime the store is updated (new translations etc...) or when a user makes a search
  function updateBranchesDisplay() {
    listBranches = $Branches; // We start by reinitialize previous filters
    if ($Branches.length > 0) {
      if (nameSearch !== '') {
        // Filter with API
        // Timeout to avoid multiple API calls
        clearTimeout(timer);
        timer = setTimeout(() => {
          loading = true;
          void loadBranchesByName();
        }, 500);
      } else {
        localFilter(); // Filter with javascript filters
      }
    }
  }

  onMount(() => {
    if ($Branches.length === 0) {
      // If branches have never been loaded (in another page for example), we load them
      void loadBranches();
    } else {
      // Else, we load contributors only
      void loadBranchLeadersNames($Branches, $_('branches.table.noresponsible'));
      loading = false;
    }
    Branches.subscribe(() => updateBranchesDisplay());
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-font-semi-bold vtmn-text-5xl vtmn-text-center">
    {$_('branches.title')}
  </h1>

  {#if hasBranchesAdminRights}
    <Modal>
      <ModalButton>
        <button
          class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
          on:click={openModal(CreateBranch)}>
          {$_('branches.modification.create')}
        </button>
      </ModalButton>
    </Modal>
  {/if}
</div>

<FlatTable bind:loading col="6">
  <span slot="headers" class="vtmn-contents">
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/6">
      <input
        type="search"
        placeholder={$_('branches.table.code')}
        bind:value={codeSearch}
        on:input={updateBranchesDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('branches.table.name')}
        bind:value={nameSearch}
        on:input={updateBranchesDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12">
      <input
        type="search"
        placeholder={$_('skill.modification.leader')}
        bind:value={responsibleSearch}
        on:input={updateBranchesDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12"
      >{$_('job.name.plural')}</th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12"
      >{$_('role.name.plural')}</th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12 vtmn-text-xs"
      >{$_('skill.name.plural')}</th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">{$_('global.actions')}</th>
  </span>
  <span slot="body" class="vtmn-contents">
    {#if listBranches?.length > 0}
      {#each listBranches as branch, i}
        <RawBranch bind:branch {i} />
      {/each}
    {:else}
      <td colspan="6" class="vtmn-p-4 vtmn-text-center">
        {$_('search.none')}
      </td>
    {/if}
  </span>
</FlatTable>
<div class="vtmn-flex">
  <img class="vtmn-mr-auto digitalPeople" src="/img/digitalforpeople.png" />
</div>
<Modal>
  <ModalButton bind:openModal />
</Modal>
