<script lang="ts">
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { params } from '@roxi/routify';
  import { roleTranslationsService } from '../../../../services/roleTranslations.service';
  import { RoleTranslationModel } from '../../../../models/roleTranslation.model';

  export let translationToDelete: RoleTranslationModel;
  export let roleName: string;
  let CurrentRole = getContext('CurrentRole');
  const { close } = getContext('simple-modal');
  $: slug = $params.slug;

  function deleteTranslation(): void {
    void roleTranslationsService.delete(translationToDelete.id).then(value => {
      if (value) {
        $CurrentRole.translations = $CurrentRole.translations.filter(
          translation => translation.id !== translationToDelete.id
        );
      }
      close();
    });
  }
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {`${$_('branches.translations.deletion.title')} - ${translationToDelete.name} (${
      translationToDelete.language_id
    })`}
  </h2>
</div>

<div class="modal-content">
  <h3>{$_('branches.translations.deletion.subtitle')}</h3>
  <div class="vtmn-flex vtmn-flex-row vtmn-justify-evenly vtmn-mt-3 modal">
    <button
      class=" vtmn-w-2/5 vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
      on:click|preventDefault={close}>
      {$_('global.buttons.cancel')}
    </button>
    <button
      class="vtmn-w-2/5 vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small"
      on:click|preventDefault={deleteTranslation}>
      {$_('branches.translations.deletion.confirm')}
    </button>
  </div>
</div>
