<script lang="ts">
  import AutoComplete from 'simple-svelte-autocomplete';
  import type { UserInfo } from '../../../models/userInfo.model';
  import { maxLength, required, useForm, validators } from 'svelte-use-form';
  import { userService } from '../../../services/user.service';
  import { getContext, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { skillsService } from '../../../services/skills.service';
  import { BranchModel } from '../../../models/branch.model';
  import { Branches } from '../../../stores/branches.store';
  import { branchesService } from '../../../services/branches.service';
  import { SkillModel } from '../../../models/skill.model';
  import { Skills } from '../../../stores/skills.store';
  import { UserInfos } from '../../../stores/userInfo.store';
  import {
    inferSkillType,
    checkDigitalSkillRights,
    checkHumanSkillRights,
    getBranchesUnitedContributorOrLeader,
    isAdmin,
  } from '../../Auth/rights.utils';
  import UserImage from '../User/UserImage.svelte';

  const { close } = getContext('simple-modal');

  export let skillToEdit: SkillModel = undefined;

  let label: string;
  let description: string;
  let selectedLeader: UserInfo;
  let isHuman = false;
  let isDigital = false;
  let selectedBranch: BranchModel;
  let listBranches: BranchModel[] = [];
  let canSelectHumanSkill = false;
  let canSelectDigitalSkill = false;
  const skillForm = useForm();

  function handleCreationOrEdit() {
    skillToEdit ? void editSkill() : void createSkill();
  }

  function editSkill() {
    void skillsService
      .patch(skillToEdit?.id, {
        branch_id: selectedBranch?.id,
        type: inferSkillType(isHuman, isDigital),
      })
      .then(res => {
        if (res) {
          let skillToEdit = new SkillModel(res);
          skillToEdit.branch = $Branches.find(branch => branch.id == selectedBranch?.id);
          const index = $Skills.findIndex(skill => skill.id === skillToEdit.id);
          $Skills[index] = skillToEdit;
          close();
        }
      });
  }

  function createSkill() {
    void skillsService
      .create({
        skill_translations: [
          {
            language_id: 'en-001',
            label: label,
            full_label: description,
          },
        ],
        branch_id: selectedBranch?.id,
        type: inferSkillType(isHuman, isDigital),
        leaders: [
          {
            member_id: selectedLeader?.uuid,
          },
        ],
      })
      .then(res => {
        if (res) {
          let skillCreated = new SkillModel(res);
          $Skills = [...$Skills, skillCreated];
          close();
        }
      });
  }

  async function retrieveDataFormInit() {
    listBranches = $Branches.length === 0 ? await branchesService.getAll() : $Branches;
    if (!isAdmin($UserInfos)) {
      const branches_rights = getBranchesUnitedContributorOrLeader($UserInfos);
      listBranches = listBranches.filter(branch => branches_rights.includes(branch.id));
    }
    if (skillToEdit) {
      selectedBranch = $Branches?.find(branch => branch.id == skillToEdit?.branch?.id);
      isHuman = skillToEdit.type === 'Human';
      isDigital = skillToEdit.type === 'Digital';
    }
  }

  function checkCanSelectSpecificType() {
    canSelectHumanSkill = checkHumanSkillRights($UserInfos, selectedBranch);
    canSelectDigitalSkill = checkDigitalSkillRights($UserInfos, selectedBranch);
  }

  onMount(() => {
    void retrieveDataFormInit();
    void checkCanSelectSpecificType();
  });
</script>

<form use:skillForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {skillToEdit ? $_('skill.modification.edit') : $_('skill.modification.create')}
    </h2>
  </div>

  <div
    class="vtmn-flex vtmn-flex-col vtmn-text-center vtmn-justify-around vtmn-h-4/6 modal-content">
    {#if !skillToEdit}
      <div class="input-wrapper">
        <label for="label" class="control-label">{$_('skill.table.name')}</label>
        <input
          bind:value={label}
          class="vtmn-block vtmn-w-full form-control vtmn-sm:text-sm"
          id="label"
          name="label"
          placeholder={$_('skill.modification.name')}
          type="text"
          use:validators={[required, maxLength(60)]} />
      </div>
      <div
        class="vtmn-flex vtmn-justify-end {label?.length > 60
          ? 'control-label-danger'
          : 'control-label'}">
        {label?.length || 0}/60
      </div>

      <div class="input-wrapper">
        <label for="description" class="control-label"
          >{$_('skill.table.description')}</label>
        <textarea
          bind:value={description}
          class="vtmn-block vtmn-w-full form-control vtmn-sm:text-sm"
          id="description"
          name="description"
          placeholder={$_('skill.modification.description')}
          type="text"
          use:validators={[required, maxLength(250)]} />
      </div>
      <div
        class="vtmn-flex vtmn-justify-end {description?.length > 250
          ? 'control-label-danger'
          : 'control-label'}">
        {description?.length || 0}/250
      </div>
    {/if}
    <div class="input-wrapper vtmn-text-left">
      <label for="skillBranches" class="control-label"
        >{$_('branches.name.single')}</label>
      <AutoComplete
        bind:items={listBranches}
        bind:selectedItem={selectedBranch}
        labelFieldName="labelTranslated"
        delay="200"
        localFiltering="false"
        placeholder={$_('job.modification.branches_placeholder')}
        maxItemsToShowInList="1000"
        inputId="skillBranches"
        inputClassName="form-control"
        onChange={checkCanSelectSpecificType} />
    </div>

    {#if !skillToEdit}
      <div class="input-wrapper">
        <label for="skillLeaders" class="control-label"
          >{$_('skill.modification.leader')}</label>
        <AutoComplete
          bind:selectedItem={selectedLeader}
          delay="200"
          labelFieldName="fullName"
          localFiltering="false"
          maxItemsToShowInList="10"
          placeholder={$_('skill.modification.leader')}
          searchFunction={userService.searchUser}
          lock={true}
          inputId="skillLeaders"
          inputClassName="form-control">
          <div
            class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start"
            let:item
            slot="item">
            <UserImage uid={item.uid} />
            <div class="vtmn-flex vtmn-items-center vtmn-text-center">
              {item.fullName}
            </div>
          </div>
        </AutoComplete>
      </div>
    {/if}

    {#if canSelectHumanSkill}
      <div class="input-wrapper">
        <input
          bind:checked={isHuman}
          id="isHuman"
          class="control-label vtmn-w-auto vtmn-my-auto vtmn-checkbox"
          type="checkbox"
          name="isHuman" />
        <label for="isHuman" class="control-label vtmn-w-auto vtmn-my-auto vtmn-p-0"
          >{$_('skill.table.human')} ?</label>
      </div>
    {/if}
    {#if canSelectDigitalSkill}
      <div class="input-wrapper">
        <input
          bind:checked={isDigital}
          id="isDigital"
          class="control-label vtmn-w-auto vtmn-my-auto vtmn-checkbox"
          type="checkbox"
          name="isDigital" />
        <label for="isDigital" class="control-label vtmn-w-auto vtmn-my-auto vtmn-p-0"
          >{$_('skill.table.digital')} ?</label>
      </div>
    {/if}

    <div class="vtmn-p-2">
      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
        disabled={!$skillForm.valid ||
          (!skillToEdit &&
            (selectedLeader?.uuid === undefined || selectedBranch?.id === undefined))}
        on:click|preventDefault={handleCreationOrEdit}>
        {$_('global.buttons.validate')}
      </button>
    </div>
  </div>
</form>
