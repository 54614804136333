<script lang="ts">
  import { BranchTranslationModel } from '../../../../models/branchTranslation.model';
  import { branchTranslationsService } from '../../../../services/branchTranslations.service';
  import { Hint, required, useForm, validators } from 'svelte-use-form';
  import { getContext, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { Branches } from '../../../../stores/branches.store';
  import { params } from '@roxi/routify';
  import { failure } from '../../../Toastr/toastr';
  import LanguageIdSelector from '../../../Generics/TranslationUtilities/LanguageIdSelector.svelte';
  import * as localeCodes from 'locale-codes';

  $: slug = $params.slug;
  const modifyTranslationForm = useForm();
  const { close } = getContext('simple-modal');
  export let translationToModify: BranchTranslationModel;
  export let languagesList: Array<localeCodes.ILocale> = [];
  let defaultTranslation: BranchTranslationModel;
  let selectedLanguage: localeCodes.ILocale;
  let creationMode: boolean;
  let CurrentBranch = getContext('CurrentBranch');

  if (translationToModify) {
    creationMode = false;
  } else {
    creationMode = true;
    translationToModify = new BranchTranslationModel();
  }

  function modifyTranslation(): void {
    void branchTranslationsService
      .patch(translationToModify.id, { label: $modifyTranslationForm.translation.value })
      .then((value: BranchTranslationModel) => {
        if (value && $CurrentBranch) {
          const index = $CurrentBranch.branchTranslations.findIndex(
            translation => translation.id === value?.id
          );
          $CurrentBranch.branchTranslations[index] = new BranchTranslationModel(value);
        }
        close();
      });
  }

  function addTranslation(): void {
    if (
      $CurrentBranch.branchTranslations.findIndex(
        translation => translation.language_id === selectedLanguage.tag
      ) !== -1
    ) {
      failure($_('global.translation_error') + ' (' + selectedLanguage?.name + ')');
    } else {
      void createTranslation();
    }
  }

  function createTranslation(): void {
    void branchTranslationsService
      .create({
        label: $modifyTranslationForm.translation.value,
        language_id: selectedLanguage.tag,
        branch_id: slug,
      })
      .then((response: BranchTranslationModel) => {
        if (response) {
          $CurrentBranch.branchTranslations = [
            ...$CurrentBranch.branchTranslations,
            new BranchTranslationModel(response),
          ];
          translationToModify = new BranchTranslationModel();
        }
        close();
      });
  }

  onMount(() => {
    defaultTranslation =
      $CurrentBranch.branchTranslations.find(
        translation => translation.language_id === 'en-001'
      ) ??
      $CurrentBranch.branchTranslations.find(
        translation => translation.language_id === 'en'
      );
  });
</script>

<form use:modifyTranslationForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {#if creationMode}
        {`${$_('translations.create')} - ${$CurrentBranch.shortName}`}
      {:else}
        {`${$_('branches.translations.modify.title')} - ${$CurrentBranch.shortName} (${
          translationToModify.language_id
        })`}
      {/if}
    </h2>
  </div>

  <div class="modal-content">
    {#if creationMode}
      <div class="input-wrapper">
        <label class="control-label"
          >{$_('branches.translations.placeholders.language')}</label>
        <LanguageIdSelector bind:selectedLanguage bind:languagesList />
      </div>
    {/if}

    <div class="input-wrapper">
      <label for="translation" class="control-label">
        {$_('branches.modification.translation')}
        {#if defaultTranslation}
          <span class="vtmn-italic vtmn-text-grey"> ({defaultTranslation?.label}) </span>
        {/if}
      </label>
      <input
        bind:value={translationToModify.label}
        class="vtmn-block vtmn-w-full vtmn-p-2 vtmn-mt-2 vtmn-sm:text-smnvtmn-rounded-md"
        id="translation"
        name="translation"
        placeholder={$_('branches.translations.placeholders.translation')}
        type="text"
        use:validators={[required]} />
      <Hint for="translation" on:required>{$_('global.validators.required')}</Hint>
    </div>

    <div class="vtmn-flex-row vtmn-justify-end vtmn-mt-3 vtmn-mr-3 vtmn-text-right">
      <button
        class="vtmn-btn vtmn-btn_size--small"
        disabled={!$modifyTranslationForm.valid}
        on:click|preventDefault={!creationMode
          ? void modifyTranslation()
          : void addTranslation()}>
        {!creationMode ? $_('global.buttons.modify') : $_('global.buttons.create')}
      </button>
    </div>
  </div>
</form>
