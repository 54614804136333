<script lang="ts">
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { _ } from 'svelte-i18n';
  import FlatTable from '../../../../components/Generics/Table/FlatTable.svelte';
  import { onMount } from 'svelte';
  import { params } from '@roxi/routify';
  import ModalButton from '../../../../components/Generics/Modal/ModalButton.svelte';
  import Modal from 'svelte-simple-modal';
  import { responsibilityService } from '../../../../services/responsibility.service';
  import { jobService } from '../../../../services/job.service';
  import { JobModel } from '../../../../models/job.model';
  import { jobResponsibilityService } from '../../../../services/jobResponsibility.service';
  import { JobResponsibilityModel } from '../../../../models/jobResponsibility.model';
  import { JobResponsibility } from '../../../../stores/jobResponsibility.store';
  import RawResponsibility from '../../../../components/Contents/Responsibilities/RawResponsibility.svelte';
  import { Countries } from '../../../../stores/countries.store';
  import { countryService } from '../../../../services/country.service';
  import LinkJobResponsibility from '../../../../components/Contents/JobResponsibility/LinkJobResponsibility.svelte';
  import EditResponsibilityOrder from '../../../../components/Contents/JobResponsibility/EditResponsibilityOrder.svelte';
  import {
    isAdmin,
    isBranchContributor,
    isBranchLeader,
    isBranchLocalContributor,
    isBranchUnitedContributor,
  } from '../../../../components/Auth/rights.utils';
  import { writable } from 'svelte/store';
  import { CountryModel } from '../../../../models/country.model';
  import { getCountryFromCode } from '../../../../components/Contents/Countries/countries.util';

  $: slug = $params.slug;
  let currentJob = writable(new JobModel({ id: slug }));
  $: canCreateLink =
    isAdmin($UserInfos) ||
    isBranchLeader($UserInfos, $currentJob.branches) ||
    isBranchContributor($UserInfos, $currentJob.branches);
  let loading = false;
  let openModal;
  let arrayJobResponsibilityByCountry = [];

  $JobResponsibility = [];

  function canEditOrder(countryCode: string): boolean {
    return (
      isAdmin($UserInfos) ||
      isBranchLeader($UserInfos, $currentJob.branches) ||
      isBranchUnitedContributor($UserInfos, $currentJob.branches) ||
      (countryCode
        ? isBranchLocalContributor($UserInfos, $currentJob.branches, [
            new CountryModel({ country_code: countryCode }),
          ])
        : false)
    );
  }

  function retrieveResponsibilityInfos(jobResponsibilityList: JobResponsibilityModel[]) {
    let count = 0;
    jobResponsibilityList.forEach(jobResponsibility => {
      responsibilityService.get(jobResponsibility.responsibility.id).then(res => {
        jobResponsibility.job = $currentJob;
        jobResponsibility.responsibility = res;
        count++;
        Array.isArray($JobResponsibility[jobResponsibility.country.code])
          ? $JobResponsibility[jobResponsibility.country.code].push(jobResponsibility)
          : ($JobResponsibility[jobResponsibility.country.code] = [jobResponsibility]);

        if (count == jobResponsibilityList.length) {
          // When all data has been fetch, order array by order
          for (let country in $JobResponsibility) {
            $JobResponsibility[country].sort(function (a, b) {
              // Sort by order
              return a.order - b.order;
            });
          }
          JobResponsibility.set($JobResponsibility);
        }
      });
    });
  }

  onMount(() => {
    jobService.get(slug).then(res => {
      if (res) {
        $currentJob = res;
      }
    });

    jobResponsibilityService.getAll({ params: { job_id: slug } })?.then(res => {
      if (res?.length > 0) {
        retrieveResponsibilityInfos(res);
      }
    });

    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
      });
    }

    JobResponsibility.subscribe(j => {
      arrayJobResponsibilityByCountry = Object.entries($JobResponsibility);
      arrayJobResponsibilityByCountry.sort(function (a, b) {
        // Sort by country
        return a[0] < b[0] ? -1 : 1;
      });
    });
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-font-semi-bold vtmn-text-5xl">
    {$_('job.name.single')} :
    {$currentJob?.internalTranslationTranslated}
  </h1>
  {#if canCreateLink}
    <button
      class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
      on:click={openModal(LinkJobResponsibility, { selectedJob: $currentJob })}>
      {$_('responsibilities.link')}
    </button>
  {/if}
</div>

{#if arrayJobResponsibilityByCountry?.length > 0}
  {#each arrayJobResponsibilityByCountry as [country, jobResponsibilities]}
    <div class="vtmn-flex vtmn-flex-wrap vtmn-my-5">
      <h1 class="vtmn-text-xl vtmn-ml-0 vtmn-my-auto vtmn-mr-auto">
        {$_('responsibility.name.plural')}
        {#if country}
          (<span class="countryFlag">{getCountryFromCode(country)?.flag}</span>{' ' +
            getCountryFromCode(country)?.name}) :
        {:else}
          (United) :
        {/if}
      </h1>
      {#if canEditOrder(country)}
        <button
          class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-2"
          on:click={openModal(EditResponsibilityOrder, {
            jobResponsibilityList: jobResponsibilities,
            countryCode: country,
          })}>
          {$_('global.buttons.order')}
        </button>
      {/if}
    </div>

    <FlatTable bind:loading col="5">
      <span slot="headers" class="vtmn-contents">
        <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12"
          >{$_('job.modification.countries')}</th>
        <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
          >{$_('responsibilities.table.name')}</th>
        <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
          >{$_('responsibilities.table.description')}</th>
        <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12"
          >{$_('job.name.plural')}</th>
        <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12"
          >{$_('responsibilities.table.skills')}</th>
        <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
          >{$_('global.actions')}</th>
      </span>

      <span slot="body" class="vtmn-contents">
        {#each jobResponsibilities as jobResponsibility}
          <RawResponsibility
            bind:responsibility={jobResponsibility.responsibility}
            {jobResponsibility} />
        {/each}
      </span>
    </FlatTable>
  {/each}
{:else}
  <p class="vtmn-font-thin vtmn-text-white">{$_('responsibility.not_found.job')}</p>
{/if}

<Modal>
  <ModalButton bind:openModal />
</Modal>
