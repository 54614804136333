<script lang="ts">
  import { maxLength, required, useForm, validators } from 'svelte-use-form';
  import { getContext, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { params } from '@roxi/routify';
  import * as localeCodes from 'locale-codes';
  import { failure } from '../../../Toastr/toastr';
  import LanguageIdSelector from '../../../Generics/TranslationUtilities/LanguageIdSelector.svelte';
  import { RoleTranslationModel } from '../../../../models/roleTranslation.model';
  import { roleTranslationsService } from '../../../../services/roleTranslations.service';
  import { RoleLength } from '../../../../shared/role.shared';

  $: slug = $params.slug;
  const modifyTranslationForm = useForm();
  const { close } = getContext('simple-modal');
  export let translationToModify: RoleTranslationModel;
  export let languagesList: Array<localeCodes.ILocale> = [];
  let defaultTranslation: RoleTranslationModel;
  let CurrentRole = getContext('CurrentRole');
  let selectedLanguage: localeCodes.ILocale;
  let creationMode: boolean;

  if (translationToModify) {
    creationMode = false;
  } else {
    creationMode = true;
    translationToModify = new RoleTranslationModel();
  }

  function modifyTranslation(): void {
    void roleTranslationsService
      .patch(translationToModify.id, {
        translation: $modifyTranslationForm.translation.value,
      })
      .then((value: RoleTranslationModel) => {
        if (value) {
          const index = $CurrentRole.translations.findIndex(
            trad => trad.id === translationToModify?.id
          );
          $CurrentRole.translations[index] = new RoleTranslationModel(value);
          CurrentRole.set($CurrentRole);
          close();
        }
      });
  }

  function addTranslation(): void {
    if (
      $CurrentRole?.translations.findIndex(
        translation => translation.language_id === selectedLanguage.tag
      ) !== -1
    ) {
      failure($_('global.translation_error') + ' (' + selectedLanguage?.name + ')');
    } else {
      void createTranslation();
    }
  }

  function createTranslation(): void {
    void roleTranslationsService
      .create({
        translation: $modifyTranslationForm.translation.value,
        language_id: selectedLanguage.tag,
        additional_role_id: slug,
      })
      .then((response: RoleTranslationModel) => {
        if (response) {
          $CurrentRole.translations = [
            ...$CurrentRole.translations,
            new RoleTranslationModel(response),
          ];
          translationToModify = new RoleTranslationModel();
          close();
        }
      });
  }

  onMount(() => {
    defaultTranslation =
      $CurrentRole?.translations.find(
        translation => translation?.language_id === 'en-001'
      ) ??
      $CurrentRole?.translations.find(translation => translation?.language_id === 'en');
  });
</script>

<form use:modifyTranslationForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {#if creationMode}
        {`${$_('translations.create')} - ${$CurrentRole.nameTranslated}`}
      {:else}
        {`${$_('branches.translations.modify.title')} - (${
          translationToModify.language_id
        })`}
      {/if}
    </h2>
  </div>
  <div class="modal-content">
    {#if creationMode}
      <div class="input-wrapper">
        <label class="control-label"
          >{$_('branches.translations.placeholders.language')}</label>
        <LanguageIdSelector bind:selectedLanguage bind:languagesList />
      </div>
    {/if}
    <div class="input-wrapper">
      <label for="translation" class="control-label">
        {$_('role.translation')}
        {#if defaultTranslation}
          <span class="vtmn-italic vtmn-text-grey"> ({defaultTranslation?.name})</span>
        {/if}
      </label>
      <input
        bind:value={translationToModify.name}
        class="vtmn-block vtmn-w-full vtmn-p-2 vtmn-sm:text-smnvtmn-rounded-md"
        id="translation"
        name="translation"
        placeholder={$_('branches.translations.placeholders.translation')}
        type="text"
        use:validators={[required, maxLength(RoleLength)]} />
    </div>
    <div
      class="vtmn-flex vtmn-justify-end {translationToModify?.name?.length > RoleLength
        ? 'control-label-danger'
        : 'control-label'}">
      {translationToModify?.name?.length || 0}/{RoleLength}
    </div>

    <div class="vtmn-flex-row vtmn-justify-end vtmn-mt-3 vtmn-mr-3 vtmn-text-right">
      <button
        class="vtmn-btn vtmn-btn_size--small"
        disabled={!$modifyTranslationForm.valid}
        on:click|preventDefault={!creationMode
          ? void modifyTranslation()
          : void addTranslation()}>
        {!creationMode ? $_('global.buttons.modify') : $_('global.buttons.create')}
      </button>
    </div>
  </div>
</form>
