<script lang="ts">
  import InsideNavigation from '../../components/Navigation/InsideNavigation.svelte';
  import OwnDecorator from '../../components/OwnDecorator.svelte';
  import { _ } from 'svelte-i18n';

  const links = [
    ['/me/IA', 'Identity Access'],
    ['/me/branches', 'branches.name.plural'],
    ['/me/skills', 'skill.name.plural'],
  ];
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <div class="vtmn-flex vtmn-m-auto">
    <h1 class="vtmn-font-semi-bold vtmn-text-5xl">
      {$_('rights.title')}
    </h1>
  </div>
  <InsideNavigation {links} />
</div>
<div>
  <slot decorator={OwnDecorator} />
</div>
