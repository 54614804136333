<script lang="ts">
  import { createEventDispatcher, getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { ResponsibilitySkillModel } from '../../../models/responsibilitySkill.model';
  import { toogleSkillKey } from './responsibilitySkill';

  export let responsibilitySkill: ResponsibilitySkillModel;
  const dispatch = createEventDispatcher();
  const { close } = getContext('simple-modal');

  function onToogleSkillKey() {
    toogleSkillKey(responsibilitySkill);
    close();
  }
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {responsibilitySkill?.skill?.labelTranslated}
  </h2>
</div>

<h3 class="vtmn-p-4">
  {responsibilitySkill.key
    ? $_('skill.modification.notkey.question')
    : $_('skill.modification.key.question')}
</h3>

<div class="vtmn-flex vtmn-flex-row vtmn-justify-evenly vtmn-mt-3 modal-content">
  <button
    class=" vtmn-w-2/5 vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
    on:click|preventDefault={close}>
    {$_('global.buttons.cancel')}
  </button>
  <button
    class="vtmn-w-2/5 vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small"
    on:click|preventDefault={onToogleSkillKey}>
    {$_('global.buttons.validate')}
  </button>
</div>
