<script lang="ts">
  export let uid;
  let fallback = '../../../../img/figure.png';
  const handleError = ev => (ev.target.src = fallback);
</script>

<img
  class="vtmn-rounded-full vtmn-h-8 vtmn-w-8 vtmn-p-2 vtmn-object-cover"
  src={'https://iam.subsidia.org/photos/users/' + uid + '.jpg'}
  alt="user"
  on:error={handleError} />
