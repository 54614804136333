<!--Code from https://github.com/fouita/svelte-tw-pagination-->
<style>
</style>

<script>
  import ChevronLeftIcon from './ChevronLeft.svelte';
  import ChevronRightIcon from './ChevronRight.svelte';
  import { createEventDispatcher } from 'svelte/internal';
  const dispatch = createEventDispatcher();
  export let current = 1;
  export let num_items = 20;
  export let per_page = 20;
  export let fromElement = (current - 1) * per_page;
  export let toElement = current * per_page;
  export let rounded = false;
  export let small = false;
  export let color = 'indigo';
  export let light = true;
  $: s = small ? 8 : 12;
  $: num_pages = Math.ceil(num_items / per_page);
  let arr_pages = [];
  function buildArr(c, n) {
    if (n <= 7) {
      return [...Array(n)].map((_, i) => i + 1);
    } else {
      if (c < 3 || c > n - 2) {
        return [1, 2, 3, '...', n - 2, n - 1, n];
      } else {
        return [1, '...', c - 1, c, c + 1, '...', n];
      }
    }
  }
  function setArrPages() {
    arr_pages = buildArr(current, num_pages);
  }
  $: if (current) {
    setArrPages();
  }
  $: if (per_page) {
    setArrPages();
    current = 1;
  }
  $: if (num_items) {
    num_pages = Math.ceil(num_items / per_page);
    current = current || 1;
  }
  function setCurrent(i) {
    if (isNaN(i)) return;
    current = i;
    dispatch('navigate', current);
  }
</script>

<div class="vtmn-flex {light ? 'vtmn-text-white' : 'vtmn-text-black'}">
  <div class="vtmn-my-auto vtmn-mr-2 vtmn-ml-auto">
    {fromElement} - {toElement} / {num_items}
  </div>
  <div class="vtmn-flex vtmn-text-gray-700 vtmn-text-{small ? 'base' : 'lg'}">
    <div
      class="vtmn-h-{s} vtmn-w-{s} vtmn-mr-1 vtmn-flex vtmn-justify-center vtmn-items-center {rounded
        ? 'vtmn-rounded-full vtmn-bg-gray-200'
        : ''}
    {current > 1 ? 'vtmn-cursor-pointer' : 'vtmn-text-gray-400'}"
      on:click={() => current > 1 && setCurrent(current - 1)}>
      <ChevronLeftIcon class="vtmn-w-{s / 2} vtmn-h-{s / 2}" />
    </div>
    <div
      class="vtmn-flex vtmn-h-{s} vtmn-font-medium {rounded
        ? 'vtmn-rounded-full vtmn-bg-gray-200'
        : ''}">
      {#each arr_pages as i}
        <div
          class="vtmn-w-{s} sm:vtmn-flex vtmn-justify-center vtmn-items-center vtmn-hidden vtmn-select-none
        vtmn-cursor-pointer vtmn-leading-5 vtmn-transition vtmn-duration-150 vtmn-ease-in {i ==
          current
            ? rounded
              ? `vtmn-rounded-full vtmn-bg-${color}-600 vtmn-text-white`
              : `vtmn-border-t-2 vtmn-border-${color}-600 `
            : rounded
            ? 'vtmn-rounded-full '
            : 'vtmn-border-t-2 vtmn-order-white'}
        "
          on:click={() => setCurrent(i)}>
          {i}
        </div>
      {/each}
      <div
        class="vtmn-w-{s} vtmn-h-{s} sm:vtmn-hidden vtmn-flex vtmn-justify-center vtmn-select-none vtmn-items-center
      vtmn-cursor-pointer vtmn-leading-5 vtmn-transition vtmn-duration-150 vtmn-ease-in {rounded
          ? `vtmn-rounded-full vtmn-bg-${color}-600 vtmn-text-white`
          : `vtmn-border-t-2 vtmn-border-${color}-600`}">
        {current}
      </div>
    </div>
    <div
      class="vtmn-h-{s} vtmn-w-{s} vtmn-ml-1 vtmn-flex vtmn-justify-center vtmn-items-center {rounded
        ? 'vtmn-rounded-full vtmn-bg-gray-200'
        : ''}
    {current < num_pages ? 'vtmn-cursor-pointer' : 'vtmn-text-gray-400'}"
      on:click={() => current < num_pages && setCurrent(current + 1)}>
      <ChevronRightIcon class="vtmn-w-{s / 2} vtmn-h-{s / 2}" />
    </div>
  </div>
</div>
