export function returnCorrectJobTranslation(job, isInternal, preferredLanguage = 'en', preferredLanguageWithoutLocale, notFoundMessage = 'Not found') {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const trad = // 4 cases here : the preferredLanguage (i.e: fr-CA), the preferredLanguage without locale (i.e: fr), english (World) or one english (doesn't matter of the locale)
     (_k = (_h = (_f = (_d = (_b = (_a = job.translations) === null || _a === void 0 ? void 0 : _a.find(translation => translation.language_id.toLowerCase() === preferredLanguage.toLowerCase())) !== null && _b !== void 0 ? _b : (_c = job.translations) === null || _c === void 0 ? void 0 : _c.find(translation => translation.language_id.toLowerCase().split('-')[0] ===
        preferredLanguageWithoutLocale.toLowerCase())) !== null && _d !== void 0 ? _d : (_e = job.translations) === null || _e === void 0 ? void 0 : _e.find(translation => translation.language_id.toLowerCase() === 'en-001')) !== null && _f !== void 0 ? _f : (_g = job.translations) === null || _g === void 0 ? void 0 : _g.find(translation => translation.language_id.toLowerCase().split('-')[0] === 'en')) !== null && _h !== void 0 ? _h : (_j = job.translations) === null || _j === void 0 ? void 0 : _j.find(translation => translation.language_id !== null)) !== null && _k !== void 0 ? _k : undefined;
    return trad
        ? isInternal
            ? trad.internal_translation
            : trad.external_translation
        : notFoundMessage;
}
