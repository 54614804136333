import ApiService from './api.service';
import { ResponsibilityTranslationModel } from '../models/responsibilityTranslation.model';
export class ResponsibilityTranslationsService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/responsibility_translations';
        this.entity = ResponsibilityTranslationModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const responsibilityTranslationService = new ResponsibilityTranslationsService();
