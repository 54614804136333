import ApiService from './api.service';
import { JobModel } from '../models/job.model';
export class JobService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/jobs';
        this.entity = JobModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const jobService = new JobService();
