<script lang="ts">
  import { createEventDispatcher, getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { skillsService } from '../../../services/skills.service';
  import type { SkillModel } from '../../../models/skill.model';
  import { Skills } from '../../../stores/skills.store';

  export let deletedSkill: SkillModel;
  const dispatch = createEventDispatcher();
  const { close } = getContext('simple-modal');

  function deleteSkill(deletedSkill: SkillModel) {
    void skillsService.delete(deletedSkill.id)?.then(res => {
      if (res) {
        Skills.update(skills =>
          skills.filter((skill: SkillModel) => skill.id !== deletedSkill.id)
        );
        close();
      }
    });
  }
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('skill.deletion.title')} - {deletedSkill?.labelTranslated}
  </h2>
</div>

<h3 class="vtmn-p-4">{$_('skill.deletion.subtitle')}</h3>

<div class="vtmn-flex vtmn-flex-row vtmn-justify-evenly vtmn-mt-3 modal-content">
  <button
    class=" vtmn-w-2/5 vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
    on:click|preventDefault={close}>
    {$_('global.buttons.cancel')}
  </button>
  <button
    class="vtmn-w-2/5 vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small"
    on:click|preventDefault={deleteSkill(deletedSkill)}>
    {$_('skill.deletion.confirm')}
  </button>
</div>
