import { UserInfo } from './userInfo.model';
export class JobOwnerModel {
    constructor(data = {}) {
        this.id = data.id;
        this.jobId = data.job_id;
        this.member = new UserInfo();
        if (data.member_id) {
            this.member.uuid = data.member_id;
        }
    }
}
