<style>
  :global(nav) {
    border-radius: 30px;
    margin: auto;
  }
  .logo {
    max-width: 10rem;
    width: 11vw;
  }
</style>

<!--Component used for navigation between the pages-->
<script>
  import { url } from '@roxi/routify';
  import Navigation from './Navigation.svelte';

  export let links;
</script>

<div
  class="vtmn-flex-1 vtmn-flex vtmn-flex-col vtmn-mb-5 vtmn-flex-wrap vtmn-w-11/12 vtmn-mx-auto">
  <nav class="vtmn-flex vtmn-h-10 vtmn-w-full vtmn-text-white">
    <ul
      class="vtmn-flex vtmn-items-center vtmn-text-center vtmn-ml-0 vtmn-mr-auto vtmn-mt-3">
      <li class="vtmn-text-2xl logo">
        <a class="vtmn-m-auto" href={$url('/')}
          ><img src="/img/logo2.png" alt="HR Repository" /></a>
      </li>
    </ul>
    <Navigation {links} topNavigation="true" />
  </nav>
</div>
