<script lang="ts">
  import { params, route } from '@roxi/routify';
  import { _ } from 'svelte-i18n';
  import { branchesService } from '../../../services/branches.service';
  import { BranchModel } from '../../../models/branch.model';
  import { Branches } from '../../../stores/branches.store';
  import InsideNavigation from '../../../components/Navigation/InsideNavigation.svelte';
  import TableJobs from '../../../components/Contents/Jobs/TableJobs.svelte';
  import { onMount } from 'svelte';

  $: slug = $params.slug;
  $: links = [
    ['/jobs/' + slug, 'job.name.plural'],
    ['/roles/' + slug, 'role.name.plural'],
    ['/skills/branch/' + slug, 'skill.name.plural'],
  ];
  $route.meta['param-is-page'] = true; // When slug change, the component is reloaded

  let currentBranch: BranchModel;
  let selectedBranches: BranchModel[] = [];
  let loading = true;

  function retrieveBranchInfo(): void {
    if (!$Branches.find(branch => branch.id === slug)) {
      branchesService.get(slug)?.then(branch => {
        currentBranch = branch;
        selectedBranches.push(currentBranch);
        loading = false;
      });
    } else {
      currentBranch = $Branches.find(branch => branch.id === slug);
      selectedBranches.push(currentBranch);
      loading = false;
    }
  }

  onMount(() => {
    void retrieveBranchInfo();
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <div class="vtmn-flex vtmn-m-auto">
    <h1 class="vtmn-font-semi-bold vtmn-text-5xl">
      {$_('branches.name.single')} :
      {#if currentBranch?.labelTranslated !== undefined}
        {currentBranch.labelTranslated}
      {/if}
    </h1>
  </div>
  <InsideNavigation bind:links />
</div>

<h1 class="vtmn-font-thin vtmn-text-xl">
  {$_('job.name.plural')} :
</h1>
{#if !loading}
  <TableJobs bind:currentBranch isJob={true} bind:selectedBranches createOption={false} />
{/if}
