<script>
  import ButtonExportCSV from './ButtonExportCSV.svelte';
  import { _ } from 'svelte-i18n';
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <div class="vtmn-flex vtmn-ml-0">
    <h1 class="vtmn-font-semi-bold vtmn-text-5xl">
      {$_('export.title')}
    </h1>
  </div>
</div>
<p class="vtmn-text-white">{$_('export.question')}</p>
<div class="vtmn-flex vtmn-flex-wrap vtmn-mt-5 vtmn-h-10">
  <ButtonExportCSV dataType="job" />
  <ButtonExportCSV dataType="skill" />
</div>
