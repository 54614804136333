import { BranchModel } from './branch.model';
import { RoleTranslationModel } from './roleTranslation.model';
export class RoleModel {
    constructor(data = {}) {
        var _a, _b;
        this.nameTranslated = '';
        this.id = data.id;
        this.shortName = data.short_name;
        this.translations = [];
        this.branches = [];
        this.responsibilityCount = (_a = data === null || data === void 0 ? void 0 : data.responsibility_count) !== null && _a !== void 0 ? _a : 0;
        if (data.role_translations) {
            data.role_translations.forEach((translation) => {
                var _a;
                const roleTranslation = new RoleTranslationModel(translation);
                (_a = this.translations) === null || _a === void 0 ? void 0 : _a.push(roleTranslation);
            });
        }
        if (data.role_translated) {
            this.nameTranslated = (_b = data.role_translated) === null || _b === void 0 ? void 0 : _b.translation;
        }
        if (data.branches) {
            data.branches.forEach((branch) => {
                var _a;
                const roleBranch = new BranchModel();
                roleBranch.id = branch;
                (_a = this.branches) === null || _a === void 0 ? void 0 : _a.push(roleBranch);
            });
        }
    }
}
