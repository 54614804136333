<script lang="ts">
  import * as localeCodes from 'locale-codes';
  import { ILocale } from 'locale-codes';
  import AutoComplete from 'simple-svelte-autocomplete';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { getFlagFromLanguage } from '../../Contents/Languages/languages.utils';

  let filteredLanguages: Array<localeCodes.ILocale> = [];
  export let selectedLanguage: localeCodes.ILocale;
  export let languagesList: Array<localeCodes.ILocale>;

  function filterLanguages(input: string): void {
    filteredLanguages = languagesList.filter(
      language => language.name.indexOf(input) !== -1
    );
  }

  function renderCorrectLabelIfNoLocation(language: ILocale): string {
    let label = language.name;
    if (language.location) {
      label += ` (${language.location})`;
    }
    return label;
  }

  onMount(() => filterLanguages(''));
</script>

<AutoComplete
  bind:selectedItem={selectedLanguage}
  hideArrow="true"
  inputClassName="vtmn-block vtmn-sm:text-sm vtmn-rounded-md"
  items={filteredLanguages}
  maxItemsToShowInList="1000"
  labelFunction={language => renderCorrectLabelIfNoLocation(language)}
  placeholder={$_('branches.translations.placeholders.language')}>
  <div let:item slot="item">
    <span class="countryFlag">{getFlagFromLanguage(item.tag)}</span>
    {item.name} ({item?.location})
  </div>
</AutoComplete>
