import countryCodeEmoji from 'country-code-emoji';
export function cleanLanguages(allLanguages) {
    allLanguages.find(language => language.location === 'Turkey').location = 'Turkiye'; // Corrige error in country code
    allLanguages = allLanguages.filter(language => language.tag !== 'es-ES_tradnl'); // Remove language
    allLanguages.push({
        name: 'English',
        local: 'English',
        location: 'Ethiopia',
        tag: 'en-ET',
        lcid: 4096,
        'iso639-2': 'eng',
        'iso639-1': 'en',
    });
    allLanguages.push({
        name: 'English',
        local: 'English',
        location: 'Saudi Arabia',
        tag: 'en-SA',
        lcid: 4096,
        'iso639-2': 'eng',
        'iso639-1': 'en',
    });
    allLanguages.sort(function (a, b) {
        if ((a === null || a === void 0 ? void 0 : a.name) === (b === null || b === void 0 ? void 0 : b.name) && a.location && b.location) {
            return a.location < b.location ? -1 : 1;
        }
        return (a === null || a === void 0 ? void 0 : a.name) < (b === null || b === void 0 ? void 0 : b.name) ? -1 : 1;
    });
    return allLanguages;
}
export function getFlagFromLanguage(languageTag, splitIndex = 1) {
    switch (languageTag) {
        case 'en-001':
            return '🌍';
        case 'en-150':
            return '🇪🇺';
        default: {
            let emoji = '';
            const splitLanguage = languageTag === null || languageTag === void 0 ? void 0 : languageTag.split('-'); // Try to get country from language
            if (splitLanguage[splitIndex]) {
                try {
                    emoji = countryCodeEmoji(splitLanguage[splitIndex]);
                }
                catch (e) {
                    if (splitIndex === 2) {
                        emoji = '';
                    }
                    else {
                        return getFlagFromLanguage(languageTag, 2);
                    }
                }
            }
            return emoji;
        }
    }
}
