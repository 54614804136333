import { get } from 'svelte/store';
import { Countries } from '../../../stores/countries.store';
export function getCountryFromCode(countryCode) {
    var _a;
    return (_a = get(Countries)) === null || _a === void 0 ? void 0 : _a.find(country => (country === null || country === void 0 ? void 0 : country.code) === countryCode);
}
export function cleanCountries(countries) {
    const arrayCountries = countries.filter(country => country.code !== 'ZZ'); // Remove a fake code created by MasterFI
    arrayCountries.find(country => country.code === 'TR').name = 'Turkiye'; // Change Turkey name
    return arrayCountries;
}
