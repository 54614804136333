<style>
  :global(*) {
    font-family: Inter, sans-serif;
  }

  :global(.countryFlag) {
    font-family: 'Noto Color Emoji Compat';
  }

  :global(body) {
    padding-left: 0;
    padding-right: 0;
    color: black;
  }

  :global(a:visited) {
    color: inherit;
  }
</style>

<script lang="ts">
  import { url } from '@roxi/routify';
  import { _ } from 'svelte-i18n';
  import Card from '../components/Generics/Card/Card.svelte';
  import AccountTie from 'svelte-material-icons/AccountTie.svelte';
  import SourceBranch from 'svelte-material-icons/SourceBranch.svelte';
  import AccountStar from 'svelte-material-icons/AccountStar.svelte';
  import AccountKey from 'svelte-material-icons/AccountKey.svelte';
  import AccountDetails from 'svelte-material-icons/AccountDetails.svelte';
  import { Countries } from '../stores/countries.store';
  import { countryService } from '../services/country.service';
  import { onMount } from 'svelte';

  function retrieveCountries() {
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
      });
    }
  }

  onMount(() => {
    void retrieveCountries();
  });
</script>

<div class="vtmn-flex vtmn-mb-6">
  <h1 class="vtmn-font-semi-bold vtmn-text-5xl vtmn-text-center">
    {$_('home.welcome_message')}
  </h1>
</div>

<div class="vtmn-flex vtmn-flex-wrap">
  <div class="vtmn-m-auto vtmn-w-full vtmn-flex vtmn-flex-col">
    <Card title={$_('branches.title')} link={$url('./branches')} icon={SourceBranch} />
    <Card title={$_('job.title')} link={$url('./jobs')} icon={AccountTie} />
    <Card title={$_('role.title')} link={$url('./roles')} icon={AccountDetails} />
    <Card
      title={$_('responsibilities.title')}
      link={$url('./responsibilities')}
      icon={AccountKey} />
    <Card title={$_('skill.title')} link={$url('./skills')} icon={AccountStar} />
  </div>
</div>
<div class="vtmn-flex vtmn-absolute vtmn-left-0 vtmn-bottom-0 vtmn-w-full">
  <img class="vtmn-m-auto digitalPeople" src="/img/digitalforpeople.png" />
</div>
