<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import FlatTr from '../../../Generics/Table/FlatTr.svelte';
  import FlatTable from '../../../Generics/Table/FlatTable.svelte';
  import FlatTd from '../../../Generics/Table/FlatTd.svelte';
  import ValidationQuestion from '../../../Generics/Question/ValidationQuestion.svelte';
  import { userService } from '../../../../services/user.service';
  import type { UserInfo } from '../../../../models/userInfo.model';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { PaginationModel } from '../../../../models/pagination.model';
  import Pagination from '../../../Generics/Paginator/Pagination.svelte';
  import { isAdmin } from '../../../Auth/rights.utils';
  import UserImage from '../../User/UserImage.svelte';
  import { JobModel } from '../../../../models/job.model';
  import { JobOwnerModel } from '../../../../models/jobOwnerModel';
  import { jobOwnersService } from '../../../../services/jobOwners.service';
  import CreateJobOwner from './CreateJobOwner.svelte';

  export let job: JobModel;
  $: canManageJobOwner = isAdmin($UserInfos);
  let listJobOwners: JobOwnerModel[] = [];
  let pagination: PaginationModel = new PaginationModel();
  let loading = true;
  let showPagination = false;
  let current = 1;
  let jobOwnerToRemove: JobOwnerModel;
  let tableHeaders = [
    { size: '5/7', title: 'branch_contributor.table.name' },
    { size: '2/7', title: 'global.actions' },
  ];

  function removeOwner() {
    if (jobOwnerToRemove) {
      void jobOwnersService.delete(jobOwnerToRemove?.id).then(value => {
        if (value) {
          listJobOwners = listJobOwners.filter(owner => owner.id != jobOwnerToRemove.id);
          jobOwnerToRemove = undefined;
        }
      });
    }
  }

  function retrieveJobOwnerData(jobOwner: JobOwnerModel) {
    userService
      .getAll({ params: { ldap_uuid: jobOwner?.member?.uuid } })
      ?.then((users: UserInfo[]) => {
        if (users.length > 0) {
          jobOwner.member = users[0];
        } else {
          jobOwner.member.displayName = $_('global.unknown_user');
        }
        listJobOwners = [...listJobOwners, jobOwner];
        loading = false;
      });
  }

  function retrieveJobOwners(event?: CustomEvent): void {
    if (event) {
      current = event.detail;
    }
    loading = true;
    jobOwnersService
      .getAllWithPagination({ params: { job_id: job.id, page: current - 1 } })
      ?.then(data => {
        if (data.length !== 0) {
          listJobOwners = [];
          pagination = data[1];
          showPagination = true;
          for (const jobLeadersData of data[0]) {
            if (jobLeadersData.member.uuid !== undefined) {
              retrieveJobOwnerData(jobLeadersData);
            }
          }
        } else {
          loading = false;
        }
      });
  }

  onMount(() => {
    listJobOwners = [];
    void retrieveJobOwners();
  });
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('owner.title')} ({job.shortName})
  </h2>
</div>

<div class="modal-content">
  <FlatTable {tableHeaders} bind:loading row={3}>
    <span slot="body" class="vtmn-contents">
      {#if canManageJobOwner}
        <CreateJobOwner {job} bind:listJobOwners />
      {/if}
      {#if listJobOwners.length > 0}
        {#each listJobOwners as jobOwner, i}
          <FlatTr>
            <FlatTd>
              {#if jobOwner.member?.fullName}
                <div class="vtmn-flex vtmn-flex-row vtmn-items-center">
                  <UserImage uid={jobOwner.member.uid} />
                  <div>{jobOwner.member?.fullName}</div>
                </div>
              {/if}
            </FlatTd>
            {#if canManageJobOwner}
              <FlatTd>
                <div
                  class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
                  {#if jobOwnerToRemove?.member.uuid === jobOwner.member.uuid}
                    <ValidationQuestion
                      on:validate={() => {
                        removeOwner(jobOwnerToRemove);
                      }}
                      on:cancel={() => {
                        jobOwnerToRemove = undefined;
                      }}
                      ` />
                  {:else}
                    <button
                      class="vtmn-cursor-pointer vtmn-my-auto vtmn-ml-auto vtmn-mr-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full"
                      on:click={() => {
                        jobOwnerToRemove = jobOwner;
                      }}>
                      <Delete color="#404040" size="1.5em" />
                    </button>
                  {/if}
                </div>
              </FlatTd>
            {/if}
          </FlatTr>
        {/each}
      {:else}
        <div class="vtmn-p-4 vtmn-text-base">{$_('owner.empty')}</div>
      {/if}
    </span>
  </FlatTable>

  {#if showPagination}
    <div class="vtmn-flex">
      <div class="vtmn-ml-auto vtmn-mr-2">
        <Pagination
          small
          bind:current
          num_items={pagination?.numItems}
          fromElement={pagination?.from}
          light={false}
          toElement={pagination?.to}
          per_page={pagination?.perPage}
          on:navigate={retrieveJobOwners} />
      </div>
    </div>
  {/if}
</div>
