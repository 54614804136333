<script>
  import { _ } from 'svelte-i18n';
  import { Branches } from '../../../stores/branches.store';

  export let handleBranchFilter = undefined; // Function which will redirect the user after the click
  export let currentBranch = undefined;
  export let size = '1/12';
</script>

<th class="vtmn-px-4 vtmn-py-2 vtmn-w-{size}">
  <div class="dropdown vtmn-font-normal vtmn-w-full vtmn-inline-block vtmn-relative">
    <div
      class="dropbtn vtmn-flex vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm vtmn-rounded vtmn-border-none vtmn-bg-white">
      {#if currentBranch}
        <span class="vtmn-text-xs vtmn-my-auto"
          >{currentBranch?.labelTranslated
            ? currentBranch.labelTranslated
            : currentBranch?.shortName}</span>
        <button
          class="vtmn-text-black vtmn-m-auto vtmn-mr-0"
          on:click={handleBranchFilter()}>x</button>
      {:else}
        {$_('branches.name.single')}
      {/if}
    </div>
    <div class="dropdown-content vtmn-hidden vtmn-absolute vtmn-bg-grey-light-4">
      {#each $Branches as branch}
        <span
          class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black vtmn-text-xs"
          on:click={handleBranchFilter(branch)}
          >{branch?.labelTranslated ? branch.labelTranslated : branch?.shortName}
        </span>
      {/each}
    </div>
  </div>
</th>
