<script lang="ts">
  import { onMount } from 'svelte';
  import FlatTable from '../../../Generics/Table/FlatTable.svelte';
  import FlatTd from '../../../Generics/Table/FlatTd.svelte';
  import FlatTr from '../../../Generics/Table/FlatTr.svelte';
  import { _ } from 'svelte-i18n';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import ValidationQuestion from '../../../Generics/Question/ValidationQuestion.svelte';
  import { searchSport, sportService } from '../../../../services/sports.service';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import CreateSkillSport from './CreateSkillSport.svelte';
  import { SkillSports } from '../../../../stores/sports.store';
  import { SkillSportModel } from '../../../../models/skillSport.model';
  import { skillSportService } from '../../../../services/skillsports.service';
  import { PaginationModel } from '../../../../models/pagination.model';
  import Pagination from '../../../Generics/Paginator/Pagination.svelte';
  import { SkillModel } from '../../../../models/skill.model';
  import {
    isAdmin,
    isBranchContributor,
    isBranchLeader,
    isSkillLeader,
  } from '../../../Auth/rights.utils';

  export let skill: SkillModel;
  let current = 1;
  let remove: number;
  let pagination: PaginationModel = new PaginationModel();
  let loading = true;
  let showPagination = false;
  let listSports: SkillSportModel[] = [];
  let skillSportToRemove: SkillSportModel;
  let tableHeaders = [
    { size: '8/12', title: 'sport.table.name' },
    { size: '4/12', title: 'global.actions' },
  ];
  $: hasSkillsRights =
    isAdmin($UserInfos) ||
    isBranchLeader($UserInfos, [skill?.branch]) ||
    isBranchContributor($UserInfos, [skill?.branch]) ||
    isSkillLeader($UserInfos, [skill]);

  function deleteSkillSport(): void {
    if (skillSportToRemove) {
      void skillSportService.delete(skillSportToRemove?.id).then(() => {
        $SkillSports = $SkillSports.filter(
          skillSport => skillSport.id != skillSportToRemove.id
        );
        skillSportToRemove = undefined;
      });
    }
  }

  function retrieveSportData(skillSportData: SkillSportModel) {
    if (skillSportData.id != undefined) {
      sportService.get(skillSportData.sport.id).then(res => {
        skillSportData.sport = res;
        SkillSports.update(skillSport => [...skillSport, skillSportData]);
        loading = false;
      });
    }
  }

  function retrieveSkillSports(evt = undefined): void {
    if (evt != undefined) {
      current = evt.detail;
      loading = true;
    }

    skillSportService
      .getAllWithPagination({ params: { skill_id: skill.id, page: current - 1 } })
      ?.then(res => {
        SkillSports.set([]);
        if (res.length !== 0) {
          pagination = res[1];
          showPagination = true;
          for (const skillSportData of res[0]) {
            retrieveSportData(skillSportData);
          }
        } else {
          loading = false;
        }
      });
  }

  onMount(() => {
    loading = true;
    void retrieveSkillSports();
  });
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('sport.name.plural')}
  </h2>
</div>

<div class="modal-content">
  <FlatTable bind:loading {tableHeaders} row={3}>
    <span slot="body" class="vtmn-contents">
      {#if hasSkillsRights}
        <CreateSkillSport bind:skill />
      {/if}
      {#if $SkillSports.length > 0}
        {#each $SkillSports as skillSport}
          <FlatTr>
            <FlatTd>
              {skillSport?.sport?.name ?? 'Name not found'}
              <br />
            </FlatTd>
            {#if hasSkillsRights}
              <FlatTd>
                <div
                  class="vtmn-m-auto vtmn-flex vtmn-justify-center vtmn-p-2 vtmn-rounded-full">
                  {#if skillSportToRemove?.sport && skillSportToRemove?.sport.id === skillSport.sport.id}
                    <ValidationQuestion
                      on:validate={() => {
                        deleteSkillSport();
                      }}
                      on:cancel={() => {
                        skillSportToRemove = undefined;
                      }} />
                  {:else}
                    <button
                      class="vtmn-cursor-pointer vtmn-my-auto vtmn-m-auto vtmn-ml-auto vtmn-border-none vtmn-p-1
                       vtmn-bg-grey-light-4 vtmn-rounded-full"
                      on:click={() => {
                        skillSportToRemove = skillSport;
                      }}>
                      <Delete color="#404040" size="1.5em" />
                    </button>
                  {/if}
                </div>
              </FlatTd>
            {/if}
          </FlatTr>
        {/each}
      {:else}
        <div class="vtmn-p-4 vtmn-text-base">{$_('sport.empty')}</div>
      {/if}
    </span>
  </FlatTable>

  {#if showPagination}
    <div class="vtmn-flex">
      <div class="vtmn-ml-auto vtmn-mr-2">
        <Pagination
          small
          bind:current
          num_items={pagination?.numItems}
          fromElement={pagination?.from}
          light={false}
          toElement={pagination?.to}
          per_page={pagination?.perPage}
          on:navigate={retrieveSkillSports} />
      </div>
    </div>
  {/if}
</div>
