<script lang="ts">
  import { RoleModel } from '../../../../models/role.model';
  import { RoleResponsibilities } from '../../../../stores/roleResponsibility.store';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { _ } from 'svelte-i18n';
  import { RoleResponsibilityModel } from '../../../../models/roleResponsibility.model';
  import { responsibilityService } from '../../../../services/responsibility.service';
  import { onMount } from 'svelte';
  import { roleResponsibilityService } from '../../../../services/roleResponsibility.service';
  import FlatTable from '../../../../components/Generics/Table/FlatTable.svelte';
  import RawResponsibility from '../../../../components/Contents/Responsibilities/RawResponsibility.svelte';
  import Modal from 'svelte-simple-modal';
  import ModalButton from '../../../../components/Generics/Modal/ModalButton.svelte';
  import { params } from '@roxi/routify';
  import { roleService } from '../../../../services/role.service';
  import LinkRoleResponsibility from '../../../../components/Contents/RoleResponsibility/LinkRoleResponsibility.svelte';
  import {
    isAdmin,
    isBranchContributor,
    isBranchLeader,
  } from '../../../../components/Auth/rights.utils';
  import { writable } from 'svelte/store';

  $: slug = $params.slug;
  let currentRole = writable(new RoleModel({ id: slug }));
  $: canCreateLink =
    isAdmin($UserInfos) ||
    isBranchLeader($UserInfos, $currentRole.branches) ||
    isBranchContributor($UserInfos, $currentRole.branches);
  let loading = false;
  let openModal;

  $RoleResponsibilities = [];

  function retrieveResponsibilityInfo(roleResponsibilityList: RoleResponsibilityModel[]) {
    roleResponsibilityList.forEach(roleResponsibility => {
      responsibilityService.get(roleResponsibility.responsibility.id).then(res => {
        roleResponsibility.role = $currentRole;
        roleResponsibility.responsibility = res;
        $RoleResponsibilities = [...$RoleResponsibilities, roleResponsibility];
        if ($RoleResponsibilities.length == roleResponsibilityList.length) {
          // When all data has been fetch, order array by order
          $RoleResponsibilities.sort(function (a, b) {
            // Sort by order
            return a.order - b.order;
          });
        }
      });
    });
  }

  onMount(() => {
    roleService.get(slug).then(res => {
      if (res) {
        $currentRole = res;
      }
    });

    roleResponsibilityService.getAll({ params: { role_id: slug } })?.then(res => {
      if (res?.length > 0) {
        retrieveResponsibilityInfo(res);
      }
    });
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-font-semi-bold vtmn-text-5xl">
    {$_('role.name.single')} : {$currentRole?.nameTranslated}
  </h1>
  {#if canCreateLink}
    <button
      class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
      on:click={openModal(LinkRoleResponsibility, { currentRole: $currentRole })}>
      {$_('responsibilities.link')}
    </button>
  {/if}
</div>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-text-xl">{$_('responsibility.name.plural')} :</h1>
</div>

{#if $RoleResponsibilities?.length > 0}
  <FlatTable bind:loading col="5">
    <span slot="headers" class="vtmn-contents">
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
        >{$_('responsibilities.table.name')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
        >{$_('responsibilities.table.description')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12"
        >{$_('job.name.plural')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12"
        >{$_('responsibilities.table.skills')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
        >{$_('global.actions')}</th>
    </span>
    <span slot="body" class="vtmn-contents">
      {#each $RoleResponsibilities as item}
        <RawResponsibility
          bind:responsibility={item.responsibility}
          bind:roleResponsibility={item} />
      {/each}
    </span>
  </FlatTable>
{:else}
  <p class="vtmn-font-thin vtmn-text-white">{$_('responsibility.not_found.role')}</p>
{/if}

<Modal>
  <ModalButton bind:openModal />
</Modal>
