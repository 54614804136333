<script lang="ts">
  import { branchTranslationsService } from '../../../../services/branchTranslations.service';
  import { goto, params } from '@roxi/routify';
  import Modal from 'svelte-simple-modal';
  import { onMount, setContext } from 'svelte';
  import { BranchTranslationModel } from '../../../../models/branchTranslation.model';
  import { BranchModel } from '../../../../models/branch.model';
  import FlatTable from '../../../../components/Generics/Table/FlatTable.svelte';
  import { branchesService } from '../../../../services/branches.service';
  import FlatTr from '../../../../components/Generics/Table/FlatTr.svelte';
  import FlatTd from '../../../../components/Generics/Table/FlatTd.svelte';
  import ModalButton from '../../../../components/Generics/Modal/ModalButton.svelte';
  import CreateTranslation from '../../../../components/Contents/Branches/Translations/CreateTranslation.svelte';
  import DeleteTranslation from '../../../../components/Contents/Branches/Translations/DeleteTranslation.svelte';
  import { _ } from 'svelte-i18n';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import * as localeCodes from 'locale-codes';
  import { sortTranslations, updateLanguageList } from '../../../../components/utils';
  import { allLanguagesList } from '../../../../stores/languages.store';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { writable } from 'svelte/store';
  import { tooltip } from '../../../../components/Generics/Tooltip/tooltip';
  import {
    isAdmin,
    isBranchContributor,
    isBranchLeader,
    isBranchUnitedContributor,
    isTranslator,
  } from '../../../../components/Auth/rights.utils';
  import { getFlagFromLanguage } from '../../../../components/Contents/Languages/languages.utils';

  let branchTranslationsDisplayed: Array<BranchTranslationModel> = []; //The array with translations filtered depending on filters
  let allLanguages: Array<localeCodes.ILocale> = [];
  let languagesList: Array<localeCodes.ILocale> = [];
  let loading = true;
  let openModal;
  let translateSearch = '';
  let localeSearch = '';
  $: slug = $params.slug;
  let CurrentBranch = writable(new BranchModel());
  $: hasBranchesRights =
    isAdmin($UserInfos) ||
    isTranslator($UserInfos) ||
    isBranchLeader($UserInfos, [$CurrentBranch]) ||
    isBranchContributor($UserInfos, [$CurrentBranch]);
  setContext('CurrentBranch', CurrentBranch);

  // This function is called to retrieve translations for the slug branch
  function getBranch(): void {
    branchesService.get(slug)?.then(res => {
      if (res?.id) {
        CurrentBranch.set(res);
        void branchTranslationsService
          .getAll({ params: { branch_id: slug } })
          .then(translations => {
            $CurrentBranch.branchTranslations = translations;
            languagesList = allLanguages;
            languagesList = updateLanguageList(
              languagesList,
              $CurrentBranch.branchTranslations
            );
            void updateTranslationsDisplay();
            loading = false;
          });
      } else {
        $goto('/error/404');
      }
    });
  }

  // This function is called everytime the store is updated (new translations etc...) or when a user makes a search
  function updateTranslationsDisplay() {
    if (
      translateSearch === '' &&
      localeSearch === '' &&
      $CurrentBranch.branchTranslations.length > 0
    ) {
      // If the search input is empty : we display all the branches
      branchTranslationsDisplayed = $CurrentBranch.branchTranslations;
    } else {
      // Else, we filter the array depending on the search
      branchTranslationsDisplayed = $CurrentBranch.branchTranslations.filter(
        (branchTranslated: BranchTranslationModel) => {
          return (
            branchTranslated.label
              .toLowerCase()
              .includes(translateSearch.toLowerCase()) &&
            allLanguages
              ?.find(country => country.tag === branchTranslated?.language_id)
              ?.name.toLowerCase()
              .includes(localeSearch.toLowerCase())
          );
        }
      );
    }
    sortTranslations(branchTranslationsDisplayed);
  }

  onMount(() => {
    allLanguages = $allLanguagesList;
    // Loading branches in case user want to go directly on the page of the translations without going through prior pages.
    // Can be fixed by calling branches at bootstrap, to be decided later on.
    CurrentBranch.subscribe(() => {
      updateTranslationsDisplay(); //Update translation display everytime the branch changes
    });
    void getBranch();
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-font-semi-bold vtmn-text-5xl vtmn-text-center">
    {$_('branches.name.single')} : {$CurrentBranch?.labelTranslated ?? ''}
  </h1>

  {#if hasBranchesRights}
    <Modal>
      <ModalButton>
        <button
          class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
          on:click={openModal(CreateTranslation, { languagesList })}>
          {$_('translations.create')}
        </button>
      </ModalButton>
    </Modal>
  {/if}
</div>
<h2 class="vtmn-mb-2 vtmn-text-xl vtmn-text-white">
  {$_('global.translations')} :
</h2>
<FlatTable bind:loading col="3">
  <span slot="headers" class="vtmn-contents">
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/5">
      <input
        type="search"
        placeholder={$_('branches.translations.table.language')}
        bind:value={localeSearch}
        on:input={updateTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/5">
      <input
        type="search"
        placeholder={$_('branches.translations.table.translation')}
        bind:value={translateSearch}
        on:input={updateTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/5">{$_('global.actions')}</th>
  </span>
  <span slot="body" class="vtmn-contents">
    {#each branchTranslationsDisplayed as translation}
      <FlatTr>
        <FlatTd>
          {#if allLanguages.length > 0}
            {#if allLanguages?.find(country => country.tag === translation?.language_id) === undefined}
              <span class="countryFlag">{getFlagFromLanguage('XX-XX')}</span>
              {translation?.language_id}
            {:else}
              <span class="countryFlag"
                >{getFlagFromLanguage(translation?.language_id)}</span>
              {allLanguages?.find(country => country.tag === translation?.language_id)
                ?.name || translation?.language_id}
              ({allLanguages?.find(country => country.tag === translation?.language_id)
                ?.location || 'Standard'})
            {/if}
          {/if}
        </FlatTd>
        <FlatTd>{translation?.label}</FlatTd>
        {#if hasBranchesRights}
          <FlatTd>
            <div
              class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
              <Modal>
                <ModalButton>
                  <button
                    class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                    use:tooltip={{ text: $_('global.buttons.modify') }}
                    on:click={openModal(CreateTranslation, {
                      translationToModify: new BranchTranslationModel(translation), // We need to create a copy of the original object in case of API error, modifications are discarded,
                    })}>
                    <Pencil color="#404040" size="1.5em" />
                  </button>
                </ModalButton>
              </Modal>
              {#if translation?.language_id?.toUpperCase() !== 'EN-001'}
                <!--Remove the english world translation is forbidden-->
                <Modal>
                  <ModalButton>
                    <button
                      class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                      use:tooltip={{ text: $_('branches.deletion.button') }}
                      on:click={openModal(
                        DeleteTranslation,
                        {
                          branchName: $CurrentBranch.shortName,
                          translationToDelete: translation,
                        },
                        {},
                        { onClosed: updateTranslationsDisplay }
                      )}>
                      <Delete color="#404040" size="1.5em" />
                    </button>
                  </ModalButton>
                </Modal>
              {/if}
            </div>
          </FlatTd>
        {/if}
      </FlatTr>
    {/each}
  </span>
</FlatTable>
<!--
  Had to instantiate it once at the end of the component to bind it to its functions
  Wanted to put it in a service but a slot is needed. Better solution may exist.
-->
<Modal>
  <ModalButton bind:openModal />
</Modal>
