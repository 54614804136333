<script lang="ts">
  import AutoComplete from 'simple-svelte-autocomplete';
  import { useForm } from 'svelte-use-form';
  import { _ } from 'svelte-i18n';
  import { getContext } from 'svelte';
  import { ResponsibilityModel } from '../../../models/responsibility.model';
  import { responsibilityService } from '../../../services/responsibility.service';
  import { RoleModel } from '../../../models/role.model';
  import { createRoleResponsibility } from './roleResponsibility';

  export let currentRole: RoleModel;
  let selectedResponsibility: ResponsibilityModel;
  const { close } = getContext('simple-modal');
  const jobForm = useForm();

  async function createLink() {
    if (await createRoleResponsibility(selectedResponsibility, currentRole)) {
      close();
    }
  }

  async function searchResponsibilities(keyword: string) {
    let listResponsibilities = await responsibilityService?.getAllWithPagination({
      params: { label: keyword },
    });
    return listResponsibilities[0];
  }
</script>

<form use:jobForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {$_('roles.modification.link')}
    </h2>
  </div>

  <div class="vtmn-flex vtmn-flex-col vtmn-justify-around vtmn-h-4/6 modal-content">
    <p class="control-label">{$_('role.name.single')} : {currentRole?.nameTranslated}</p>
    <div class="input-wrapper">
      <label for="responsibilityToLink" class="control-label"
        >{$_('responsibility.name.single')}</label>
      <AutoComplete
        searchFunction={searchResponsibilities}
        bind:selectedItem={selectedResponsibility}
        labelFieldName="labelTranslated"
        placeholder={$_('responsibility.name.single')}
        delay="200"
        localFiltering="false"
        inputId="responsibilityToLink"
        name="responsibilityToLink">
        <div slot="item" let:item>
          <div>{item?.labelTranslated}</div>
          <div class="vtmn-text-grey-light-1">{item?.fullLabelTranslated}</div>
        </div>
      </AutoComplete>
    </div>

    <div
      class="vtmn-p-5 vtmn-w-full vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-between">
      <button
        class="vtmn-float-left vtmn-btn vtmn-btn_variant--secondary vtmn-border vtmn-btn_size--small
            vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        on:click|preventDefault={close}>
        {$_('job.cancel')}
      </button>
      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        disabled={!$jobForm.valid}
        on:click|preventDefault={createLink}>
        {$_('job.validate')}
      </button>
    </div>
  </div>
</form>
