export class UserInfo {
    constructor(data = {}) {
        var _a;
        // This constructor works for Search API and UserInfo
        if (data) {
            this.fullName = this.calculateName(data.full_name, data.first_name, data.last_name);
            this.givenName = data.givenName;
            this.mail = data.mail;
            this.preferredLanguage = this.adaptPreferredLanguage(data === null || data === void 0 ? void 0 : data.preferredLanguage);
            this.preferredLanguageWithoutLocale = (_a = this.preferredLanguage) === null || _a === void 0 ? void 0 : _a.toLowerCase().split('-')[0];
            this.sn = data.sn;
            this.uid = data.ldap_uid ? data.ldap_uid : data.uid;
            this.uuid = data.ldap_uuid ? data.ldap_uuid : data.uuid;
            this.country = data.c;
            this.displayName = data.full_name ? data.full_name : data.display_name;
            this.title = data.title;
            this.siteName = data.sitename;
            this.role = data.role;
            this.photoUrl = data.google_info ? data.google_info.photo_url : data.photourl;
        }
        this.countries = [];
        this.rights = [];
    }
    calculateName(fullName, firstName, lastName) {
        if (fullName) {
            return fullName;
        }
        else if (firstName && lastName) {
            return firstName + ' ' + lastName;
        }
        return '';
    }
    // Due to difference between LDAP preferredLanguage attribute and iso 639-1, some preferredLanguages need to be edited
    adaptPreferredLanguage(ldapPreferredLanguage) {
        switch (ldapPreferredLanguage === null || ldapPreferredLanguage === void 0 ? void 0 : ldapPreferredLanguage.toUpperCase()) {
            case 'B6': {
                return 'pt-BR';
            }
            case 'ZT': {
                return 'zh-TW';
            }
            case 'M1': {
                return 'ar-MA';
            }
            case 'T1': {
                return 'ar-TN';
            }
            case 'KZ': {
                return 'kk';
            }
            case 'DK': {
                return 'da';
            }
            case 'JP': {
                return 'ja';
            }
            case 'KR': {
                return 'ko';
            }
            case 'VN': {
                return 'vi';
            }
            case 'IN': {
                return 'id';
            }
        }
        return ldapPreferredLanguage;
    }
}
