<script lang="ts">
  import { userService } from '../../../../services/user.service';
  import AutoComplete from 'simple-svelte-autocomplete';
  import { UserInfo } from '../../../../models/userInfo.model';
  import { _ } from 'svelte-i18n';
  import FlatTr from '../../../Generics/Table/FlatTr.svelte';
  import FlatTd from '../../../Generics/Table/FlatTd.svelte';
  import AccountPlus from 'svelte-material-icons/AccountPlus.svelte';
  import UserImage from '../../User/UserImage.svelte';
  import { JobModel } from '../../../../models/job.model';
  import { JobOwnerModel } from '../../../../models/jobOwnerModel';
  import { jobOwnersService } from '../../../../services/jobOwners.service';

  export let job: JobModel;
  export let listJobOwners: JobOwnerModel[];
  let selectedUser: UserInfo;

  function createOwner() {
    const newOwnerData = {
      job_id: job.id,
      member_id: selectedUser?.uuid,
    };
    const newOwner = new JobOwnerModel(newOwnerData);
    newOwner.member = selectedUser;
    void jobOwnersService.create(newOwnerData).then(res => {
      newOwner.id = res?.id;
      if (newOwner.id) {
        listJobOwners = [...listJobOwners, newOwner];
      }
      selectedUser = new UserInfo();
    });
  }
</script>

<FlatTr>
  <FlatTd>
    <AutoComplete
      searchFunction={userService.searchUser}
      bind:selectedItem={selectedUser}
      labelFieldName="fullName"
      maxItemsToShowInList="10"
      placeholder={$_('branch_contributor.table.name')}
      delay="200"
      localFiltering="false"
      inputId="User">
      <div
        slot="item"
        let:item
        class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start">
        <UserImage uid={item.uid} />
        <div class="vtmn-py-4">{item.fullName}</div>
      </div>
    </AutoComplete>
  </FlatTd>

  <FlatTd>
    <div class="vtmn-flex">
      <button
        class="vtmn-btn vtmn-btn_variant--ghost vtmn-m-auto"
        on:click={() => {
          createOwner();
        }}
        disabled={!selectedUser?.uuid}>
        <AccountPlus color="#404040" size="1.5em" />
      </button>
    </div>
  </FlatTd>
</FlatTr>
