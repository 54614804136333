import { SportModel } from './sport.model';
export class SkillSportModel {
    constructor(data = {}) {
        this.skillId = data.skill_id;
        this.id = data.id;
        this.sport = new SportModel();
        if (data.sport_id) {
            this.sport.id = data.sport_id;
        }
    }
}
