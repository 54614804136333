<script lang="ts">
  import { returnCorrectTranslation } from '../../utils.ts';
  import { UserInfos } from '../../../stores/userInfo.store';
  import { _ } from 'svelte-i18n';
  import { ResponsibilitySkillModel } from '../../../models/responsibilitySkill.model';
  import DragVertical from 'svelte-material-icons/DragVertical.svelte';
  import { JobResponsibilityModel } from '../../../models/jobResponsibility.model';

  export let jobResponsibility: JobResponsibilityModel;
</script>

<div class="vtmn-flex vtmn-border-b vtmn-border-grey-light-2 vtmn-h-10">
  <div class="vtmn-mr-10 vtmn-my-auto vtmn-ml-10">{jobResponsibility.order}</div>
  <div class="vtmn-m-auto vtmn-w-full vtmn-text-center">
    {jobResponsibility?.responsibility?.labelTranslated !== ''
      ? jobResponsibility?.responsibility.labelTranslated
      : returnCorrectTranslation(
          jobResponsibility?.responsibility,
          true,
          $UserInfos.preferredLanguage,
          $UserInfos.preferredLanguageWithoutLocale,
          $_('api_call.translation_not_found')
        )}
  </div>
  <div class="vtmn-m-auto">
    <DragVertical size="2em" color="lightgray" />
  </div>
</div>
