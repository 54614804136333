<script lang="ts">
  import { onMount } from 'svelte';
  import { rightsUserService } from '../../../services/rightsUser.service';
  import { RightsUserModel } from '../../../models/rightsUserModel';
  import { UserInfos } from '../../../stores/userInfo.store';
  import FlatTable from '../../../components/Generics/Table/FlatTable.svelte';
  import { _ } from 'svelte-i18n';

  let loading = true;
  let tableHeaders = [{ size: '1/2', title: 'right.name.single' }];

  onMount(() => {
    rightsUserService.getAll()?.then(res => {
      // We check user rights again even if we already did it at the start of the app, because rights can change
      if (res.length > 0 && res[0] instanceof RightsUserModel) {
        $UserInfos.rights = res;
        loading = false;
      }
    });
  });
</script>

{#if $UserInfos}
  <h1 class="vtmn-text-xl vtmn-ml-0 vtmn-my-auto vtmn-mr-auto">
    {$_('rights.ia')}
  </h1>
  <FlatTable {tableHeaders} bind:loading row={3}>
    <span slot="body" class="vtmn-contents">
      {#if $UserInfos.rights.length > 0}
        {#each $UserInfos.rights as right}
          {#if !right?.branch_id && !right?.skill_id && right?.roles?.length > 0}
            <td colspan="1" class="vtmn-p-4"> {right?.roles[0]} </td>
          {/if}
        {/each}
      {:else}
        <td colspan="1" class="vtmn-p-4 vtmn-text-center"> {$_('search.none')} </td>
      {/if}
    </span>
  </FlatTable>
{/if}
