<style>
  .fontLeaders {
    font-size: 6px;
  }
</style>

<script lang="ts">
  import { returnCorrectTranslation } from '../../utils.ts';
  import { UserInfos } from '../../../stores/userInfo.store';
  import Translate from 'svelte-material-icons/Translate.svelte';
  import Bike from 'svelte-material-icons/Bike.svelte';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import Key from 'svelte-material-icons/Key.svelte';
  import Eye from 'svelte-material-icons/Eye.svelte';
  import { tooltip } from '../../Generics/Tooltip/tooltip';
  import AccountGroup from 'svelte-material-icons/AccountGroup.svelte';
  import Modal from 'svelte-simple-modal';
  import FlatTd from '../../Generics/Table/FlatTd.svelte';
  import FlatTr from '../../Generics/Table/FlatTr.svelte';
  import ModalButton from '../../Generics/Modal/ModalButton.svelte';
  import listLeaders from './Leaders/ListSkillLeaders.svelte';
  import listSports from './Sports/ListSkillSports.svelte';
  import DeleteSkill from './DeleteSkill.svelte';
  import { _ } from 'svelte-i18n';
  import { url } from '@roxi/routify';
  import ToogleSkillKey from '../ResponsibilitySkills/ToogleSkillKey.svelte';
  import UnlinkResponsibilitySkill from '../ResponsibilitySkills/UnlinkResponsibilitySkill.svelte';
  import LinkOff from 'svelte-material-icons/LinkOff.svelte';
  import {
    isAdmin,
    isBranchContributorForAtLeastOneBranch,
    isBranchLeader,
    isBranchLeaderForAtLeastOneBranch,
    isBranchUnitedContributor,
  } from '../../Auth/rights.utils';
  import { SkillModel } from '../../../models/skill.model';
  import { Branches } from '../../../stores/branches.store';
  import CreateSkills from './CreateSkills.svelte';
  import { responsibilitySkillService } from '../../../services/responsibilitySkill.service';
  import { writable } from 'svelte/store';
  import { responsibilityService } from '../../../services/responsibility.service';

  export let skill: SkillModel;
  export let responsibilitySkill = undefined;
  let ResponsibilitiesLinked = writable([]); // This store is used to store responsibility linked to a skill (with auto refresh when store is updated)
  let responsibilityLoaded = false; // This boolean avoids multiple API calls if it has already been made
  let responsibilityLoading = false; // This boolean is used to display a spinner if an API call is in progress

  let openModal;
  $: hasSkillsRights = isAdmin($UserInfos);
  $: canDeleteLink =
    isAdmin($UserInfos) ||
    isBranchLeaderForAtLeastOneBranch($UserInfos) ||
    isBranchContributorForAtLeastOneBranch($UserInfos);
  $: canEditSkill =
    isAdmin($UserInfos) ||
    isBranchLeader($UserInfos, [skill?.branch]) ||
    isBranchUnitedContributor($UserInfos, [skill?.branch]);
  $: canEditLink =
    isAdmin($UserInfos) ||
    isBranchLeaderForAtLeastOneBranch($UserInfos) ||
    isBranchContributorForAtLeastOneBranch($UserInfos);

  function getBranchLabel(id) {
    const branch = $Branches.find(br => br.id === id);
    return branch ? branch.labelTranslated : '';
  }

  async function retrieveResponsibilitiesLinked() {
    if (
      !responsibilityLoading &&
      !responsibilityLoaded /*&& skill.responsibilitiesCount > 0*/
    ) {
      // There is a comment here because today, the API is not ready and doesn't return responsibilitiesCount value, but it will in the future
      responsibilityLoading = true;
      await responsibilitySkillService
        .getAll({ params: { skill_id: skill.id } })
        ?.then(async res => {
          if (res?.length > 0) {
            for (const responsibilitySkill of res) {
              await responsibilityService
                .get(responsibilitySkill?.responsibility?.id)
                .then(resp => {
                  if (resp) {
                    $ResponsibilitiesLinked = [...$ResponsibilitiesLinked, resp];
                  }
                });
            }
          }
          responsibilityLoading = false; // The spinner is stopped
        });
    }
    responsibilityLoaded = true; // Even if there is no result, this boolean avoids another API call
  }
</script>

<FlatTr>
  <FlatTd>{skill?.type}</FlatTd>
  <FlatTd>
    {skill?.labelTranslated !== ''
      ? skill?.labelTranslated
      : returnCorrectTranslation(
          skill,
          true,
          $UserInfos.preferredLanguage,
          $UserInfos.preferredLanguageWithoutLocale,
          $_('api_call.translation_not_found')
        )}
  </FlatTd>
  <FlatTd>
    {skill?.fullLabelTranslated !== ''
      ? skill?.fullLabelTranslated
      : returnCorrectTranslation(
          skill,
          false,
          $UserInfos.preferredLanguage,
          $UserInfos.preferredLanguageWithoutLocale,
          $_('api_call.translation_not_found')
        )}
  </FlatTd>
  <FlatTd>
    <div class="vtmn-flex">
      {getBranchLabel(skill?.branch?.id)}
    </div>
  </FlatTd>
  <FlatTd>
    <div class="vtmn-flex">
      {skill?.responsibilityCount}
      <div
        class="has-tooltip vtmn-ml-2 vtmn-mr-auto vtmn-my-auto"
        on:mouseover={retrieveResponsibilitiesLinked}>
        <Eye color="#404040" size="1.5em" />
        <span class="tooltip vtmn-rounded vtmn-shadow-lg vtmn-p-1 vtmn-mt-2">
          {#if responsibilityLoading}
            <div class="vtmn-loader vtmn-loader_size--small vtmn-text-brand-digital">
              <span class="vtmn-sr-only">Loading</span>
            </div>
          {:else if $ResponsibilitiesLinked.length === 0}
            {$_('skill.no_link')}
          {:else}
            <ul>
              {#each $ResponsibilitiesLinked as resp}
                <li>{resp.labelTranslated ?? ''}</li>
              {/each}
            </ul>
          {/if}
        </span>
      </div>
    </div>
  </FlatTd>
  <FlatTd>
    <div class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full ">
      {#if canEditSkill && !responsibilitySkill}
        <!-- Modification is available only in /skills, not /skills/slug -->
        <Modal>
          <ModalButton>
            <button
              class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-relative"
              use:tooltip={{ text: $_('global.buttons.modify') }}
              on:click={openModal(CreateSkills, { skillToEdit: skill })}>
              <Pencil size="1.5em" />
            </button>
          </ModalButton>
        </Modal>
      {/if}

      <a
        class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
        use:tooltip={{ text: $_('global.translations') }}
        href={$url('/skills/' + skill?.id + '/translations')}>
        <Translate size="1.5em" />
      </a>
      {#if canEditLink && responsibilitySkill}
        <Modal>
          <ModalButton>
            <button
              class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
              use:tooltip={{ text: $_('skill.toogle.skill_key') }}
              on:click={openModal(ToogleSkillKey, {
                responsibilitySkill: responsibilitySkill,
              })}>
              <Key size="1.5em" />
            </button>
          </ModalButton>
        </Modal>
      {/if}
      <Modal>
        <ModalButton>
          <button
            class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative vtmn-inline-flex vtmn-items-center"
            use:tooltip={{ text: $_('leader.title') }}
            on:click={openModal(listLeaders, { skill: skill })}>
            <AccountGroup size="1.5em" />
            <div
              class:fontLeaders={skill?.leadersCount > 99}
              class="vtmn-inline-flex vtmn-absolute vtmn--top-0 vtmn--right-0 vtmn-justify-center vtmn-items-center countBadge
                        vtmn-w-3 vtmn-h-3 vtmn-font-semi-bold vtmn-text-white vtmn-rounded-full vtmn-bg-brand-digital vtmn-border-1 vtmn-border-white">
              {skill?.leadersCount}
            </div>
          </button>
        </ModalButton>
      </Modal>
      <button
        class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative vtmn-inline-flex vtmn-items-center"
        use:tooltip={{ text: $_('sport.name.plural') }}
        on:click={openModal(listSports, { skill: skill })}>
        <Bike size="1.5em" />
        <div
          class="vtmn-inline-flex vtmn-absolute vtmn--top-0 vtmn--right-0 vtmn-justify-center vtmn-items-center countBadge
                              vtmn-w-3 vtmn-h-3 vtmn-font-semi-bold vtmn-text-white vtmn-rounded-full vtmn-bg-brand-digital vtmn-border-1 vtmn-border-white">
          {skill?.sportsCount}
        </div>
      </button>
      {#if hasSkillsRights && !responsibilitySkill && skill?.responsibilityCount === 0}
        <!-- Deletion is available only in /skills, not /skills/slug -->
        <Modal>
          <ModalButton>
            <button
              class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-relative"
              use:tooltip={{ text: $_('branches.deletion.button') }}
              on:click={openModal(DeleteSkill, { deletedSkill: skill })}>
              <Delete size="1.5em" />
            </button>
          </ModalButton>
        </Modal>
      {/if}
      {#if canDeleteLink && responsibilitySkill && skill}
        <Modal>
          <ModalButton>
            <button
              class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
              use:tooltip={{ text: $_('global.dissociate') }}
              on:click={openModal(UnlinkResponsibilitySkill, {
                responsibilitySkill: responsibilitySkill,
              })}>
              <LinkOff size="1.5em" />
            </button>
          </ModalButton>
        </Modal>
      {/if}
    </div>
  </FlatTd>
</FlatTr>

<Modal>
  <ModalButton bind:openModal />
</Modal>
