<script lang="ts">
  import Translate from 'svelte-material-icons/Translate.svelte';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import ChevronRight from 'svelte-material-icons/ChevronRight.svelte';
  import Modal from 'svelte-simple-modal';
  import { tooltip } from '../../Generics/Tooltip/tooltip';
  import FlatTd from '../../Generics/Table/FlatTd.svelte';
  import FlatTr from '../../Generics/Table/FlatTr.svelte';
  import ModalButton from '../../Generics/Modal/ModalButton.svelte';
  import DeleteResponsibility from './DeleteResponsibility.svelte';
  import { _ } from 'svelte-i18n';
  import { returnCorrectTranslation } from '../../utils.ts';
  import { UserInfos } from '../../../stores/userInfo.store';
  import { url } from '@roxi/routify';
  import { Countries } from '../../../stores/countries.store';
  import LinkOff from 'svelte-material-icons/LinkOff.svelte';
  import UnlinkJobResponsibility from '../JobResponsibility/UnlinkJobResponsibility.svelte';
  import UnlinkRoleResponsibility from '../RoleResponsibility/UnlinkRoleResponsibility.svelte';
  import {
    isAdmin,
    isBranchContributor,
    isBranchLeader,
    isBranchLeaderForAtLeastOneBranch,
    isBranchLocalContributor,
    isBranchUnitedContributor,
    isBranchUnitedContributorForAtLeastOneBranch,
  } from '../../Auth/rights.utils';
  import Eye from 'svelte-material-icons/Eye.svelte';
  import { writable } from 'svelte/store';
  import { JobResponsibilityModel } from '../../../models/jobResponsibility.model';
  import { RoleResponsibilityModel } from '../../../models/roleResponsibility.model';
  import { ResponsibilityModel } from '../../../models/responsibility.model';
  import { jobResponsibilityService } from '../../../services/jobResponsibility.service';
  import { jobService } from '../../../services/job.service';

  export let responsibility: ResponsibilityModel;
  export let jobResponsibility: JobResponsibilityModel = undefined;
  export let roleResponsibility: RoleResponsibilityModel = undefined;
  $: canDeleteResponsibility =
    isAdmin($UserInfos) ||
    isBranchLeaderForAtLeastOneBranch($UserInfos) ||
    isBranchUnitedContributorForAtLeastOneBranch($UserInfos);
  $: canDeleteRoleResponsibility =
    isAdmin($UserInfos) ||
    isBranchLeader($UserInfos, roleResponsibility?.role?.branches) ||
    isBranchContributor($UserInfos, roleResponsibility?.role?.branches);
  $: canDeleteJobResponsibility =
    isAdmin($UserInfos) ||
    isBranchLeader($UserInfos, jobResponsibility?.job?.branches) ||
    isBranchUnitedContributor($UserInfos, jobResponsibility?.job?.branches) ||
    (jobResponsibility?.country.code
      ? isBranchLocalContributor($UserInfos, jobResponsibility?.job?.branches, [
          jobResponsibility?.country,
        ])
      : false);
  let openModal;
  let JobsLinked = writable([]); // This store is used to store jobs linked to a responsibility (with auto refresh when store is updated)
  let jobsLoaded = false; // This boolean avoids multiple API calls if one has already been made
  let jobsLoading = false; // This boolean is used to display a spinner if an API call is in progress

  async function retrieveJobsLinked() {
    if (!jobsLoading && !jobsLoaded && responsibility.jobCount > 0) {
      jobsLoading = true;
      await jobResponsibilityService
        .getAll({ params: { responsibility_id: responsibility.id } })
        ?.then(async res => {
          if (res?.length > 0) {
            for (const jobResponsibility of res) {
              if (!$JobsLinked.find(job => job?.id === jobResponsibility?.job?.id)) {
                await jobService.get(jobResponsibility?.job?.id).then(job => {
                  if (job) {
                    $JobsLinked = [...$JobsLinked, job];
                  }
                });
              }
            }
          }
          jobsLoading = false; // The spinner is stopped
        });
    }
    jobsLoaded = true; // Even if there is no result, this boolean avoids another API call
  }
</script>

<FlatTr>
  {#if jobResponsibility?.id}
    <FlatTd>
      {#if jobResponsibility.country.code}
        {$Countries?.find(
          findCountry => findCountry?.code === jobResponsibility.country?.code
        )?.name}
      {:else}
        United
      {/if}
    </FlatTd>
  {/if}
  <FlatTd>
    {responsibility.labelTranslated !== ''
      ? responsibility.labelTranslated
      : returnCorrectTranslation(
          responsibility,
          true,
          $UserInfos.preferredLanguage,
          $UserInfos.preferredLanguageWithoutLocale,
          $_('api_call.translation_not_found')
        )}
  </FlatTd>
  <FlatTd>
    {responsibility.fullLabelTranslated !== ''
      ? responsibility.fullLabelTranslated
      : returnCorrectTranslation(
          responsibility,
          false,
          $UserInfos.preferredLanguage,
          $UserInfos.preferredLanguageWithoutLocale,
          $_('api_call.translation_not_found')
        )}
  </FlatTd>
  <FlatTd>
    <div class="vtmn-flex">
      {responsibility?.jobCount}
      <div
        class="has-tooltip vtmn-ml-2 vtmn-mr-auto vtmn-my-auto"
        on:mouseover={retrieveJobsLinked}>
        <Eye color="#404040" size="1.5em" />
        <span class="tooltip vtmn-rounded vtmn-shadow-lg vtmn-p-1 vtmn-mt-2">
          {#if jobsLoading}
            <div class="vtmn-loader vtmn-loader_size--small vtmn-text-brand-digital">
              <span class="vtmn-sr-only">Loading</span>
            </div>
          {:else if $JobsLinked.length === 0}
            {$_('responsibilities.no_link')}
          {:else}
            <ul>
              {#each $JobsLinked as job}
                <li>{job.internalTranslationTranslated ?? ''}</li>
              {/each}
            </ul>
          {/if}
        </span>
      </div>
    </div>
  </FlatTd>
  <FlatTd>{responsibility?.skillCount}</FlatTd>
  <FlatTd>
    <div class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
      <a
        class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
        use:tooltip={{ text: $_('global.translations') }}
        href={$url('/responsibilities/' + responsibility?.id + '/translations')}>
        <Translate color="#404040" size="1.5em" />
      </a>
      {#if canDeleteJobResponsibility && jobResponsibility && responsibility}
        <Modal>
          <ModalButton>
            <button
              class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
              use:tooltip={{ text: $_('global.dissociate') }}
              on:click={openModal(UnlinkJobResponsibility, {
                jobResponsibility: jobResponsibility,
              })}>
              <LinkOff size="1.5em" />
            </button>
          </ModalButton>
        </Modal>
      {/if}
      {#if canDeleteRoleResponsibility && roleResponsibility && responsibility}
        <Modal>
          <ModalButton>
            <button
              class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
              use:tooltip={{ text: $_('global.dissociate') }}
              on:click={openModal(UnlinkRoleResponsibility, {
                roleResponsibility: roleResponsibility,
              })}>
              <LinkOff size="1.5em" />
            </button>
          </ModalButton>
        </Modal>
      {/if}
      {#if canDeleteResponsibility && !jobResponsibility && !roleResponsibility && responsibility.jobCount === 0 && responsibility.skillCount === 0}
        <Modal>
          <ModalButton>
            <button
              class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
              use:tooltip={{ text: $_('branches.deletion.button') }}
              on:click={openModal(DeleteResponsibility, {
                deletedResponsibility: responsibility,
              })}>
              <Delete color="#404040" size="1.5em" />
            </button>
          </ModalButton>
        </Modal>
      {/if}
      <a
        class="vtmn-cursor-pointer vtmn-relative vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full"
        href={$url('/skills/' + responsibility.id)}
        use:tooltip={{ text: $_('skill.name.plural') }}>
        <ChevronRight color="#404040" size="1.5em" />
      </a>
    </div>
  </FlatTd>
</FlatTr>

<Modal>
  <ModalButton bind:openModal />
</Modal>
