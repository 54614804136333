<script lang="ts">
  import AutoComplete from 'simple-svelte-autocomplete';
  import { _ } from 'svelte-i18n';
  import { UserInfo } from '../../../models/userInfo.model';
  import { userService } from '../../../services/user.service';
  import { RightsUserModel } from '../../../models/rightsUserModel';
  import { rightsUserSearchService } from '../../../services/rightsUserSearch.service';
  import DisplayUserRights from './DisplayUserRights.svelte';
  import { onMount } from 'svelte';
  import { SkillLeaders } from '../../../stores/leaders.store';
  import { Countries } from '../../../stores/countries.store';
  import { countryService } from '../../../services/country.service';
  import UserImage from '../User/UserImage.svelte';
  import { isAdmin } from '../../Auth/rights.utils';
  import { UserInfos } from '../../../stores/userInfo.store';
  import { goto } from '@roxi/routify';

  let userSearch: UserInfo = new UserInfo();
  let selectedUser: UserInfo;
  let rights: RightsUserModel[];
  let loading = false;

  function retrieveUserRights() {
    userSearch = new UserInfo();
    userSearch.uuid = selectedUser?.uuid;
    if (selectedUser?.uuid) {
      loading = true;
      rightsUserSearchService
        .getAll({ params: { member_id: selectedUser?.uuid } })
        ?.then(rights => {
          if (rights.length > 0 && rights[0] instanceof RightsUserModel) {
            userSearch.rights = rights;
          }
          loading = false;
        });
    }
  }

  onMount(() => {
    if (!isAdmin($UserInfos)) {
      $goto('/error/403');
    }
    $SkillLeaders = [];
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
      });
    }
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <div class="vtmn-flex vtmn-ml-0">
    <h1 class="vtmn-font-semi-bold vtmn-text-5xl">
      {$_('right.search.title')}
    </h1>
  </div>
  <div class="vtmn-mx-auto vtmn-ml-auto vtmn-mr-0 vtmn-w-2/5">
    <AutoComplete
      bind:selectedItem={selectedUser}
      searchFunction={userService.searchUser}
      onChange={retrieveUserRights}
      delay="200"
      labelFieldName="fullName"
      localFiltering="false"
      maxItemsToShowInList="10"
      placeholder={$_('right.search.title')}
      inputId="searchUserRights"
      inputClassName="form-control">
      <div
        class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start"
        let:item
        slot="item">
        <UserImage uid={item.uid} />
        <div class="vtmn-flex vtmn-items-center vtmn-text-center">{item.fullName}</div>
      </div>
    </AutoComplete>
  </div>
</div>

<DisplayUserRights bind:userSearch bind:loading />
