<script lang="ts">
  import { branchesService } from '../../../services/branches.service';
  import { BranchModel } from '../../../models/branch.model';
  import { createEventDispatcher, getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { Branches } from '../../../stores/branches.store';

  export let deletedBranch: BranchModel;
  const dispatch = createEventDispatcher();
  const { close } = getContext('simple-modal');

  function deleteBranch(deletedBranch: BranchModel) {
    void branchesService.delete(deletedBranch.id).then(() => {
      Branches.update((branches: BranchModel[]) =>
        branches.filter((branch: BranchModel) => branch.id !== deletedBranch.id)
      );
      close();
    });
  }
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {`${$_('branches.deletion.title')} - ${deletedBranch?.labelTranslated}`}
  </h2>
</div>
<h3 class="vtmn-p-4">{$_('branches.deletion.subtitle')}</h3>

<div class="vtmn-flex vtmn-flex-row vtmn-justify-evenly vtmn-mt-3 modal-content">
  <button
    class=" vtmn-w-2/5 vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
    on:click|preventDefault={close}>
    {$_('global.buttons.cancel')}
  </button>
  <button
    class="vtmn-w-2/5 vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small"
    on:click|preventDefault={deleteBranch(deletedBranch)}>
    {$_('branches.deletion.confirm')}
  </button>
</div>
