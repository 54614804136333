export class RightsUserModel {
    constructor(data = {}) {
        var _a, _b, _c;
        this.roles = [];
        this.countries = [];
        if ((_a = data === null || data === void 0 ? void 0 : data.role_type) === null || _a === void 0 ? void 0 : _a.includes(',')) {
            // We are in case of Admin and Translator
            this.roles = (_c = (_b = data.role_type) === null || _b === void 0 ? void 0 : _b.split(',')) !== null && _c !== void 0 ? _c : [];
        }
        else {
            this.branch_id = data === null || data === void 0 ? void 0 : data.branch;
            this.skill_id = data === null || data === void 0 ? void 0 : data.skill;
            this.countries = data === null || data === void 0 ? void 0 : data.countries;
            this.roles = [data === null || data === void 0 ? void 0 : data.role_type];
        }
    }
}
