<script lang="ts">
  import { UserInfo } from '../../../models/userInfo.model';
  import { _ } from 'svelte-i18n';
  import FlatTable from '../../Generics/Table/FlatTable.svelte';
  import FlatTd from '../../Generics/Table/FlatTd.svelte';
  import FlatTr from '../../Generics/Table/FlatTr.svelte';
  import { getCountryFromCode } from '../Countries/countries.util';
  import { retrieveDataTranslation } from './rightsUserSearch.utils';

  export let userSearch: UserInfo;
  export let loading;
  let tableHeaders = [
    { size: '1/3', title: 'right.name.single' },
    { size: '1/3', title: 'right.display.data' },
    { size: '1/3', title: 'job.table.perimeter' },
  ];
</script>

{#if userSearch?.uuid || loading}
  <FlatTable {tableHeaders} bind:loading row={3}>
    <span slot="body" class="vtmn-contents">
      {#if userSearch?.rights?.length > 0}
        {#each userSearch.rights as right}
          <FlatTr>
            <FlatTd>{right?.roles[0]}</FlatTd>
            <FlatTd>
              {#if right?.skill_id || right?.branch_id}
                {#await retrieveDataTranslation(right?.branch_id ? right?.branch_id : right?.skill_id, Boolean(right?.branch_id))}
                  <p
                    class="vtmn-animate-pulse vtmn-bg-grey-light-2 vtmn-h-2 vtmn-w-full" />
                {:then labelTranslated}
                  {labelTranslated}
                {/await}
              {/if}
            </FlatTd>
            <FlatTd>
              {#if right?.countries?.length === 0 || !right.countries}
                United
              {:else}
                {#each right?.countries as country}
                  <span class="countryFlag">{getCountryFromCode(country)?.flag}</span
                  >{' ' + getCountryFromCode(country)?.name} <br />
                {/each}
              {/if}
            </FlatTd>
          </FlatTr>
        {/each}
      {:else}
        <td colspan="3" class="vtmn-p-4 vtmn-text-center"> {$_('search.none')} </td>
      {/if}
    </span>
  </FlatTable>
{/if}
