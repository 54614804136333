import { UserInfo } from './userInfo.model';
import { CountryModel } from './country.model';
export class LeaderModel {
    constructor(data = {}) {
        var _a;
        this.skillId = data.skill_id;
        this.member = new UserInfo();
        if (data.member_id) {
            this.member.uuid = data.member_id;
        }
        this.id = data.id;
        this.countries = [];
        if (data.countries && ((_a = data.countries) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            data.countries.forEach((country) => {
                const countryModel = new CountryModel();
                countryModel.code = country;
                this.countries.push(countryModel);
            });
        }
    }
}
