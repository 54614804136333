<script lang="ts">
  import { onMount } from 'svelte';
  import type { CountryModel } from '../../../../models/country.model';
  import { countryService } from '../../../../services/country.service';
  import { Countries } from '../../../../stores/countries.store';
  import { LeaderModel } from '../../../../models/leader.model';
  import { skillLeaderService } from '../../../../services/skillLeader.service';
  import AutocompleteEditCountries from '../../../Generics/Autocomplete/AutocompleteEditCountries.svelte';
  import { SkillLeaders } from '../../../../stores/leaders.store';

  export let leaderToEdit: LeaderModel;

  let selectedCountries: CountryModel[];

  function editLeader() {
    let dataEditLeader = {
      countries: selectedCountries.map(country => country.code),
    };
    void skillLeaderService.patch(leaderToEdit.id, dataEditLeader).then(response => {
      if (response) {
        let leaderEdited = $SkillLeaders.find(
          skillLeader => skillLeader.member.uuid === leaderToEdit.member.uuid
        );
        leaderEdited.countries = selectedCountries;
        leaderToEdit = new LeaderModel();
      }
    });
  }

  function initCountries() {
    // Mandatory because branchCountributor the API do not returned country name and flag
    for (const country of leaderToEdit.countries) {
      const currentCountry = $Countries.find(
        countries => countries.code === country.code
      );
      if (currentCountry) {
        selectedCountries.push(currentCountry);
      }
    }
  }

  function cancelEdit() {
    leaderToEdit = new LeaderModel();
  }

  onMount(() => {
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(allCountries => {
        Countries.set(allCountries);
        initCountries();
      });
    }
    initCountries();
  });
</script>

<AutocompleteEditCountries
  bind:selectedCountries
  {cancelEdit}
  validateEdit={editLeader} />
