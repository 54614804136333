<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { createEventDispatcher } from 'svelte';
  import CheckboxMarkedCircle from 'svelte-material-icons/CheckboxMarkedCircle.svelte';
  import CloseCircle from 'svelte-material-icons/CloseCircle.svelte';

  const dispatch = createEventDispatcher();

  function sendValidate() {
    dispatch('validate', {});
  }

  function sendCancel() {
    dispatch('cancel', {});
  }
</script>

<div class="vtmn-m-auto">
  <div
    class="vtmn-relative vtmn-flex vtmn-flex-row
            vtmn-text-center vtmn-text-base vtmn-p-1">
    {$_('branch_contributor.question')}
  </div>
  <div class="vtmn-flex">
    <button
      class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-rounded-full"
      on:click={sendCancel}>
      <CloseCircle color="red" size="1.5em" />
    </button>
    <button
      class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-rounded-full"
      on:click={sendValidate}>
      <CheckboxMarkedCircle color="green" size="1.5em" />
    </button>
  </div>
</div>
