<script lang="ts">
  import { maxLength, required, useForm, validators } from 'svelte-use-form';
  import { getContext, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { params } from '@roxi/routify';
  import { jobTranslationsService } from '../../../../services/jobTranslations.service';
  import { JobTranslationModel } from '../../../../models/jobTranslation.model';
  import * as localeCodes from 'locale-codes';
  import { failure } from '../../../Toastr/toastr';
  import LanguageIdSelector from '../../../Generics/TranslationUtilities/LanguageIdSelector.svelte';
  import { JobLength } from '../../../../shared/job.shared';

  $: slug = $params.slug;
  const modifyTranslationForm = useForm();
  const { close } = getContext('simple-modal');
  export let translationToModify: JobTranslationModel;
  export let languagesList: Array<localeCodes.ILocale> = [];
  export let jobName: string;
  let defaultTranslation: JobTranslationModel;
  let CurrentJob = getContext('CurrentJob');
  let selectedLanguage: localeCodes.ILocale;
  let creationMode: boolean;

  if (translationToModify) {
    creationMode = false;
  } else {
    creationMode = true;
    translationToModify = new JobTranslationModel();
  }

  function modifyTranslation(): void {
    void jobTranslationsService
      .patch(translationToModify.id, {
        external_translation: $modifyTranslationForm.external_translation.value,
        internal_translation: $modifyTranslationForm.internal_translation.value,
      })
      .then((value: JobTranslationModel) => {
        if (value) {
          const index = $CurrentJob.translations.findIndex(
            trad => trad.id === translationToModify?.id
          );
          $CurrentJob.translations[index] = new JobTranslationModel(value);
          CurrentJob.set($CurrentJob);
          close();
        }
      });
  }

  function addTranslation(): void {
    if (
      $CurrentJob?.translations.findIndex(
        translation => translation.language_id === selectedLanguage.tag
      ) !== -1
    ) {
      failure($_('global.translation_error') + ' (' + selectedLanguage?.name + ')');
    } else {
      void createTranslation();
    }
  }

  function createTranslation(): void {
    void jobTranslationsService
      .create({
        external_translation: $modifyTranslationForm.external_translation.value,
        internal_translation: $modifyTranslationForm.internal_translation.value,
        language_id: selectedLanguage.tag,
        job_id: slug,
      })
      .then((response: JobTranslationModel) => {
        if (response) {
          $CurrentJob.translations = [
            ...$CurrentJob.translations,
            new JobTranslationModel(response),
          ];
          translationToModify = new JobTranslationModel();
          close();
        }
      });
  }

  onMount(() => {
    defaultTranslation =
      $CurrentJob?.translations.find(
        translation => translation?.language_id === 'en-001'
      ) ??
      $CurrentJob?.translations.find(translation => translation?.language_id === 'en');
  });
</script>

<form use:modifyTranslationForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {#if creationMode}
        {`${$_('translations.create')} - ${jobName}`}
      {:else}
        {`${$_('branches.translations.modify.title')} - ${jobName} (${
          translationToModify.language_id
        })`}
      {/if}
    </h2>
  </div>
  <div class="modal-content">
    {#if creationMode}
      <div class="input-wrapper">
        <label class="control-label"
          >{$_('branches.translations.placeholders.language')}</label>
        <LanguageIdSelector bind:selectedLanguage bind:languagesList />
      </div>
    {/if}
    <div class="input-wrapper">
      <label for="internal_translation" class="control-label">
        {$_('job.table.decath_name')}
        {#if defaultTranslation}
          <span class="vtmn-italic vtmn-text-grey">
            ({defaultTranslation?.internal_translation})</span>
        {/if}
      </label>
      <input
        bind:value={translationToModify.internal_translation}
        class="vtmn-block vtmn-w-full vtmn-p-2 vtmn-sm:text-smnvtmn-rounded-md"
        id="internal_translation"
        name="internal_translation"
        placeholder={$_('branches.translations.placeholders.translation')}
        type="text"
        use:validators={[required, maxLength(JobLength)]} />
    </div>
    <div
      class="vtmn-flex vtmn-justify-end {translationToModify.internal_translation
        ?.length > JobLength
        ? 'control-label-danger'
        : 'control-label'}">
      {translationToModify.internal_translation?.length || 0}/{JobLength}
    </div>

    <div class="input-wrapper">
      <label for="external_translation" class="control-label">
        {$_('job.table.outside_decath_name')}
        {#if defaultTranslation}
          <span class="vtmn-italic vtmn-text-grey">
            ({defaultTranslation?.external_translation})</span>
        {/if}
      </label>
      <input
        bind:value={translationToModify.external_translation}
        class="vtmn-block vtmn-w-full vtmn-p-2 vtmn-sm:text-smnvtmn-rounded-md"
        id="external_translation"
        name="external_translation"
        placeholder={$_('branches.translations.placeholders.translation')}
        type="text"
        use:validators={[required, maxLength(JobLength)]} />
    </div>
    <div
      class="vtmn-flex vtmn-justify-end {translationToModify.external_translation
        ?.length > JobLength
        ? 'control-label-danger'
        : 'control-label'}">
      {translationToModify.external_translation?.length || 0}/{JobLength}
    </div>

    <div class="vtmn-flex-row vtmn-justify-end vtmn-mt-3 vtmn-mr-3 vtmn-text-right">
      <button
        class="vtmn-btn vtmn-btn_size--small"
        disabled={!$modifyTranslationForm.valid}
        on:click|preventDefault={!creationMode
          ? void modifyTranslation()
          : void addTranslation()}>
        {!creationMode ? $_('global.buttons.modify') : $_('global.buttons.create')}
      </button>
    </div>
  </div>
</form>
