import ApiService from './api.service';
import { JobOwnerModel } from '../models/jobOwnerModel';
class JobOwnersService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/job_owners';
        this.entity = JobOwnerModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const jobOwnersService = new JobOwnersService();
