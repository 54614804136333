import ApiService from './api.service';
import { SkillTranslationModel } from '../models/skillTranslation.model';
export class SkillTranslationsService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/skill_translations';
        this.entity = SkillTranslationModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const skillTranslationService = new SkillTranslationsService();
