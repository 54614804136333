import { SkillTranslationModel } from './skillTranslation.model';
import { BranchModel } from './branch.model';
export class SkillModel {
    constructor(data = {}) {
        var _a, _b, _c, _d, _e;
        this.branch = new BranchModel();
        this.labelTranslated = '';
        this.fullLabelTranslated = '';
        this.id = data === null || data === void 0 ? void 0 : data.id;
        this.leaders = data === null || data === void 0 ? void 0 : data.leaders;
        this.translations = [];
        this.type = data === null || data === void 0 ? void 0 : data.type;
        this.responsibilityCount = (_a = data === null || data === void 0 ? void 0 : data.responsibility_count) !== null && _a !== void 0 ? _a : 0;
        this.leadersCount = (_b = data === null || data === void 0 ? void 0 : data.skill_leader_count) !== null && _b !== void 0 ? _b : 0;
        this.sportsCount = (_c = data === null || data === void 0 ? void 0 : data.skill_sport_count) !== null && _c !== void 0 ? _c : 0;
        if (data.skill_translations) {
            data.skill_translations.forEach((translation) => {
                var _a;
                const skillTranslation = new SkillTranslationModel(translation);
                (_a = this.translations) === null || _a === void 0 ? void 0 : _a.push(skillTranslation);
            });
        }
        if (data.branch_id) {
            this.branch = new BranchModel({ id: data.branch_id });
        }
        if (data.skill_translated) {
            this.labelTranslated = (_d = data.skill_translated) === null || _d === void 0 ? void 0 : _d.label;
            this.fullLabelTranslated = (_e = data.skill_translated) === null || _e === void 0 ? void 0 : _e.full_label;
        }
    }
}
