import ApiService from './api.service';
import { BranchTranslationModel } from '../models/branchTranslation.model';
export class BranchTranslationsService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/branch_translations';
        this.entity = BranchTranslationModel;
        this.baseUrl = HR_JOBS_BASE_URL !== null && HR_JOBS_BASE_URL !== void 0 ? HR_JOBS_BASE_URL : '';
        this.apiKey = HR_JOBS_X_API_KEY !== null && HR_JOBS_X_API_KEY !== void 0 ? HR_JOBS_X_API_KEY : '';
    }
}
export const branchTranslationsService = new BranchTranslationsService();
