<script lang="ts">
  import { onMount, setContext } from 'svelte';
  import { returnCorrectJobTranslation } from './jobs.utils';
  import Translate from 'svelte-material-icons/Translate.svelte';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import ChevronRight from 'svelte-material-icons/ChevronRight.svelte';
  import Modal from 'svelte-simple-modal';
  import { _ } from 'svelte-i18n';
  import FlatTd from '../../Generics/Table/FlatTd.svelte';
  import FlatTr from '../../Generics/Table/FlatTr.svelte';
  import ModalButton from '../../Generics/Modal/ModalButton.svelte';
  import CreateJob from './CreateJob.svelte';
  import { PaginationModel } from '../../../models/pagination.model';
  import Eye from 'svelte-material-icons/Eye.svelte';
  import AccountGroup from 'svelte-material-icons/AccountGroup.svelte';
  import { params, url } from '@roxi/routify';
  import { UserInfos } from '../../../stores/userInfo.store';
  import { Jobs } from '../../../stores/job.store';
  import { Countries } from '../../../stores/countries.store';
  import { countryService } from '../../../services/country.service';
  import DeleteJob from './DeleteJob.svelte';
  import { tooltip } from '../../Generics/Tooltip/tooltip';
  import {
    isAdmin,
    isBranchUnitedContributor,
    isBranchLeader,
    isBranchLocalContributor,
  } from '../../Auth/rights.utils';
  import { JobModel } from '../../../models/job.model';
  import { getCountryFromCode } from '../Countries/countries.util';
  import { StatusList } from '../../../stores/status.store';
  import ListJobOwners from './Owners/ListJobOwners.svelte';

  $: slug = $params.slug;
  let pagination: PaginationModel = new PaginationModel();
  let loading = true;
  let openModal;
  const colorIcon = '#404040';
  const sizeIcon = '1.5em';
  $: canDeleteJob = isAdmin($UserInfos);
  setContext('jobs', Jobs);

  onMount(() => {
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
      });
    }
  });

  function canEditJob(Job: JobModel): boolean {
    let canEdit: boolean =
      isAdmin($UserInfos) ||
      isBranchLeader($UserInfos, Job.branches) ||
      isBranchUnitedContributor($UserInfos, Job.branches);
    return Job?.countries?.length > 0
      ? canEdit || isBranchLocalContributor($UserInfos, Job.branches, Job.countries)
      : canEdit;
  }
</script>

{#if $Jobs?.length > 0}
  {#each $Jobs as job}
    {#if job.shortName}
      <FlatTr>
        <FlatTd>{job.shortName}</FlatTd>
        <FlatTd>
          {#if job?.countries?.length === 0}
            🌐 United
          {:else}
            {#each job.countries as country}
              <span class="countryFlag">{getCountryFromCode(country?.code)?.flag}</span
              >{' ' + getCountryFromCode(country?.code)?.name} <br />
            {/each}
          {/if}
        </FlatTd>
        <FlatTd>
          {job.internalTranslationTranslated !== ''
            ? job.internalTranslationTranslated
            : returnCorrectJobTranslation(
                job,
                true,
                $UserInfos.preferredLanguage,
                $UserInfos.preferredLanguageWithoutLocale,
                $_('api_call.translation_not_found')
              )}
        </FlatTd>
        <FlatTd>
          {job.externalTranslationTranslated !== ''
            ? job.externalTranslationTranslated
            : returnCorrectJobTranslation(
                job,
                false,
                $UserInfos.preferredLanguage,
                $UserInfos.preferredLanguageWithoutLocale,
                $_('api_call.translation_not_found')
              )}
        </FlatTd>
        <FlatTd>{$StatusList.find(s => s.status === job.status)?.translation}</FlatTd>
        <FlatTd>
          <div class="vtmn-flex">
            <div class="vtmn-mr-1 vtmn-ml-auto vtmn-my-auto">{job.branches.length}</div>
            <div class="has-tooltip vtmn-ml-1 vtmn-mr-auto vtmn-my-auto">
              <Eye color="#404040" size="1.5em" />
              <span class="tooltip vtmn-rounded vtmn-shadow-lg vtmn-p-1 vtmn-mt-2">
                {#each job.branches as branche}
                  {branche?.labelTranslated ?? ''}<br />
                {/each}
              </span>
            </div>
          </div>
        </FlatTd>
        <FlatTd>{job?.responsibilityCount}</FlatTd>
        <FlatTd>
          <div
            class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
            {#if canEditJob(job)}
              <button
                class="vtmn-m-auto vtmn-cursor-pointer vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                use:tooltip={{ text: $_('global.buttons.modify') }}
                on:click={openModal(CreateJob, {
                  jobToEdit: job,
                })}>
                <Pencil color={colorIcon} size={sizeIcon} />
              </button>
            {/if}
            <a
              class="vtmn-m-auto vtmn-relative"
              use:tooltip={{ text: $_('global.translations') }}
              href={$url('/jobs/' + job.id + '/translations')}>
              <Translate color={colorIcon} size={sizeIcon} />
            </a>
            <Modal>
              <ModalButton>
                <button
                  class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative vtmn-inline-flex vtmn-items-center"
                  use:tooltip={{ text: $_('owner.title') }}
                  on:click={openModal(ListJobOwners, { job: job })}>
                  <AccountGroup size="1.5em" />
                  <div
                    class:fontLeaders={job?.ownersCount > 99}
                    class="vtmn-inline-flex vtmn-absolute vtmn--top-0 vtmn--right-0 vtmn-justify-center vtmn-items-center countBadge
                        vtmn-w-3 vtmn-h-3 vtmn-font-semi-bold vtmn-text-white vtmn-rounded-full vtmn-bg-brand-digital vtmn-border-1 vtmn-border-white">
                    {job?.ownersCount}
                  </div>
                </button>
              </ModalButton>
            </Modal>
            {#if canDeleteJob && !job?.responsibilityCount}
              <Modal>
                <ModalButton>
                  <button
                    class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                    use:tooltip={{ text: $_('branches.deletion.button') }}
                    on:click={openModal(DeleteJob, { deletedJob: job })}>
                    <Delete size="1.5em" />
                  </button>
                </ModalButton>
              </Modal>
            {/if}
            <a
              class="vtmn-m-auto vtmn-relative"
              use:tooltip={{ text: $_('responsibility.name.plural') }}
              href={$url('/responsibilities/' + job.id + '/jobs')}>
              <ChevronRight color={colorIcon} size={sizeIcon} />
            </a>
          </div>
        </FlatTd>
      </FlatTr>
    {/if}
  {/each}
{:else}
  <td colspan="7" class="vtmn-p-4 vtmn-text-center">
    {$_('search.none')}
  </td>
{/if}
<Modal>
  <ModalButton bind:openModal />
</Modal>
