import { ResponsibilityModel } from './responsibility.model';
import { JobModel } from './job.model';
import { CountryModel } from './country.model';
export class JobResponsibilityModel {
    constructor(data = {}) {
        var _a;
        this.country = new CountryModel();
        this.id = data.id;
        this.responsibility = new ResponsibilityModel({ id: data.responsibility_id });
        this.job = new JobModel({ id: data.job_id });
        this.order = (_a = data === null || data === void 0 ? void 0 : data.responsibility_order) !== null && _a !== void 0 ? _a : 0;
        this.country.code = data === null || data === void 0 ? void 0 : data.country_id;
    }
}
