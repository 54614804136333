import countryCodeEmoji from 'country-code-emoji';
export class CountryModel {
    constructor(data = {}) {
        var _a, _b;
        this.flag = '';
        this.code = (_a = data === null || data === void 0 ? void 0 : data.country_code) !== null && _a !== void 0 ? _a : data === null || data === void 0 ? void 0 : data.code;
        this.name = (_b = data === null || data === void 0 ? void 0 : data.country_name) !== null && _b !== void 0 ? _b : data === null || data === void 0 ? void 0 : data.name;
        if (this.code) {
            this.flag = countryCodeEmoji(this.code);
        }
    }
}
