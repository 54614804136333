import ApiService from './api.service';
import { ResponsibilitySkillModel } from '../models/responsibilitySkill.model';
export class ResponsibilitySkillService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/responsibility_skill';
        this.entity = ResponsibilitySkillModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const responsibilitySkillService = new ResponsibilitySkillService();
