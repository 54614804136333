<script lang="ts">
  import { createEventDispatcher, getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { JobModel } from '../../../models/job.model';
  import { jobService } from '../../../services/job.service';
  import { Jobs } from '../../../stores/job.store';

  export let deletedJob: JobModel;
  const dispatch = createEventDispatcher();
  const { close } = getContext('simple-modal');

  function deleteJob(deletedJob: JobModel) {
    void jobService.delete(deletedJob.id)?.then(res => {
      if (res) {
        Jobs.update(jobs => jobs.filter((job: JobModel) => job.id !== deletedJob.id));
        close();
      }
    });
  }
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('job.deletion.title')} - {deletedJob?.internalTranslationTranslated}
  </h2>
</div>

<h3 class="vtmn-p-4">{$_('job.deletion.subtitle')}</h3>

<div class="vtmn-flex vtmn-flex-row vtmn-justify-evenly vtmn-mt-3 modal-content">
  <button
    class=" vtmn-w-2/5 vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
    on:click|preventDefault={close}>
    {$_('global.buttons.cancel')}
  </button>
  <button
    class="vtmn-w-2/5 vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small"
    on:click|preventDefault={deleteJob(deletedJob)}>
    {$_('job.deletion.confirm')}
  </button>
</div>
