import ApiService from './api.service';
import { LeaderModel } from '../models/leader.model';
class SkillLeaderService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/skill_leader';
        this.entity = LeaderModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const skillLeaderService = new SkillLeaderService();
