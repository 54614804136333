import { toast } from '@zerodevx/svelte-toast';
export const success = (message) => toast.push(message, {
    theme: {
        '--toastBackground': 'green',
        '--toastColor': 'white',
        '--toastProgressBackground': 'darkgreen',
    },
    duration: 4000,
    dismissable: true,
    initial: 1,
    progress: 0,
    reversed: false,
    intro: {},
});
export const failure = (message, duration = 4000) => toast.push(message, {
    theme: {
        '--toastBackground': 'red',
        '--toastColor': 'white',
        '--toastProgressBackground': 'darkred',
    },
    duration: duration,
    dismissable: true,
    initial: 1,
    progress: 0,
    reversed: false,
    intro: {},
});
