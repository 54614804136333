import ApiService from './api.service';
import { RoleModel } from '../models/role.model';
export class RoleService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/roles';
        this.entity = RoleModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const roleService = new RoleService();
