<style>
  .card-content {
    height: 4rem;
    width: 30rem;
    padding: 1rem;
    display: flex;
  }
  .card-icon {
    font-size: 1.3rem;
  }
  .card {
    border-radius: 15px;
    margin: 1rem auto auto;
    background-color: white;
    display: block;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
  }

  .card:hover {
    text-decoration: none;
    background-color: var(--vtmn-color_brand-digital);
    color: white;
  }
</style>

<script lang="ts">
  import ChevronRight from 'svelte-material-icons/ChevronRight.svelte';
  export let title;
  export let link;
  export let icon;
</script>

<a class="card" href={link}>
  <div class="card-content vtmn-flex">
    <div class="card-icon vtmn-flex vtmn-my-auto">
      <svelte:component this={icon} />
    </div>
    <div class="card-header vtmn-flex vtmn-my-auto vtmn-ml-2">
      {title}
    </div>
    <div class="card-chevron vtmn-flex vtmn-my-auto vtmn-ml-auto vtmn-mr-1">
      <ChevronRight />
    </div>
  </div>
</a>
