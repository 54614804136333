<script lang="ts">
  import { goto, params } from '@roxi/routify';
  import Modal from 'svelte-simple-modal';
  import {
    returnCorrectTranslation,
    sortTranslations,
  } from '../../../../components/utils.ts';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { onMount, setContext } from 'svelte';
  import FlatTable from '../../../../components/Generics/Table/FlatTable.svelte';
  import FlatTr from '../../../../components/Generics/Table/FlatTr.svelte';
  import FlatTd from '../../../../components/Generics/Table/FlatTd.svelte';
  import ModalButton from '../../../../components/Generics/Modal/ModalButton.svelte';
  import { _ } from 'svelte-i18n';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import * as localeCodes from 'locale-codes';
  import DeleteResponsibilityTranslation from '../../../../components/Contents/Responsibilities/Translations/DeleteResponsibilityTranslation.svelte';
  import CreateResponsibilityTranslation from '../../../../components/Contents/Responsibilities/Translations/CreateResponsibilityTranslation.svelte';
  import { writable } from 'svelte/store';
  import { updateLanguageList } from '../../../../components/utils';
  import { allLanguagesList } from '../../../../stores/languages.store';
  import { ResponsibilityModel } from '../../../../models/responsibility.model';
  import { responsibilityService } from '../../../../services/responsibility.service';
  import { ResponsibilityTranslationModel } from '../../../../models/responsibilityTranslation.model';
  import { responsibilityTranslationService } from '../../../../services/responsibilityTranslations.service';
  import { tooltip } from '../../../../components/Generics/Tooltip/tooltip';
  import {
    isAdmin,
    isBranchContributorForAtLeastOneBranch,
    isBranchLeaderForAtLeastOneBranch,
    isTranslator,
  } from '../../../../components/Auth/rights.utils';
  import { getFlagFromLanguage } from '../../../../components/Contents/Languages/languages.utils';

  $: slug = $params.slug;
  $: hasRespRights =
    isAdmin($UserInfos) ||
    isTranslator($UserInfos) ||
    isBranchLeaderForAtLeastOneBranch($UserInfos) ||
    isBranchContributorForAtLeastOneBranch($UserInfos);
  let selectedLanguage;
  let allLanguages: Array<localeCodes.ILocale> = [];
  let languagesList: Array<localeCodes.ILocale> = [];
  let newTranslationFullLabel = '';
  let newTranslationLabel = '';
  let localeSearch = '';
  let labelSearch = '';
  let fullLabelSearch = '';
  let responsibilityDisplayed = new ResponsibilityModel();
  let loading = true;
  let openModal;
  let tableHeaders = [
    { size: '1/12', title: 'branches.translations.table.language' },
    { size: '2/12', title: 'branches.translations.table.translation' },
    { size: '7/12', title: '' },
    { size: '2/12', title: 'global.actions' },
  ];
  let CurrentResponsibility = writable(new ResponsibilityModel());
  setContext('CurrentResponsibility', CurrentResponsibility);

  // This function is called everytime the store is updated (new translations etc...) or when a user makes a search
  function updateResponsibilityTranslationsDisplay() {
    if (
      labelSearch === '' &&
      fullLabelSearch === '' &&
      localeSearch === '' &&
      $CurrentResponsibility.translations.length > 0
    ) {
      // If the search input is empty : we display all the branches
      responsibilityDisplayed.translations = $CurrentResponsibility.translations;
    } else {
      // Else, we filter the array depending on the search
      responsibilityDisplayed.translations = $CurrentResponsibility.translations.filter(
        (responsibilityTranslated: ResponsibilityTranslationModel) => {
          return (
            responsibilityTranslated.label
              .toLowerCase()
              .includes(labelSearch.toLowerCase()) &&
            responsibilityTranslated.full_label
              .toLowerCase()
              .includes(fullLabelSearch.toLowerCase()) &&
            allLanguages
              ?.find(country => country.tag === responsibilityTranslated?.language_id)
              ?.name.toLowerCase()
              .includes(localeSearch.toLowerCase())
          );
        }
      );
    }
    sortTranslations(responsibilityDisplayed.translations);
  }

  function getResponsibility(): void {
    responsibilityService.get(slug)?.then(res => {
      if (res?.id) {
        CurrentResponsibility.set(res);
        responsibilityTranslationService
          .getAll({ params: { responsibility_id: slug } })
          .then(translations => {
            $CurrentResponsibility.translations = translations;
            languagesList = allLanguages;
            languagesList = updateLanguageList(
              languagesList,
              $CurrentResponsibility.translations
            );
            loading = false;
          });
      } else {
        $goto('/error/404');
      }
    });
  }

  onMount(() => {
    allLanguages = $allLanguagesList;
    CurrentResponsibility.subscribe(() => {
      updateResponsibilityTranslationsDisplay(); //Update translation display everytime the job changes
    });
    void getResponsibility();
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-font-semi-old vtmn-text-5xl vtmn-text-center">
    {$_('responsibility.name.single')} :
    {$CurrentResponsibility.labelTranslated !== ''
      ? $CurrentResponsibility.labelTranslated
      : returnCorrectTranslation(
          $CurrentResponsibility,
          true,
          $UserInfos.preferredLanguage,
          $UserInfos.preferredLanguageWithoutLocale,
          $_('api_call.translation_not_found')
        )}
  </h1>
  {#if hasRespRights}
    <Modal>
      <ModalButton>
        <button
          class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
          on:click={openModal(CreateResponsibilityTranslation, { languagesList })}>
          {$_('translations.create')}
        </button>
      </ModalButton>
    </Modal>
  {/if}
</div>
<h2 class="vtmn-mb-2 vtmn-text-xl vtmn-text-white">
  {$_('global.translations')} :
</h2>

<FlatTable bind:loading {tableHeaders}>
  <span slot="headers" class="vtmn-contents">
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('branches.translations.table.language')}
        bind:value={localeSearch}
        on:input={updateResponsibilityTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('skill.translation.name')}
        bind:value={labelSearch}
        on:input={updateResponsibilityTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('skill.translation.description')}
        bind:value={fullLabelSearch}
        on:input={updateResponsibilityTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">{$_('global.actions')}</th>
  </span>
  <span slot="body" class="vtmn-contents">
    {#each responsibilityDisplayed.translations as translation, i}
      <FlatTr>
        <FlatTd>
          {#if allLanguages.length > 0}
            <span class="countryFlag"
              >{getFlagFromLanguage(translation?.language_id)}</span>
            {allLanguages?.find(country => country.tag === translation.language_id)?.name}
            ({allLanguages?.find(country => country.tag === translation.language_id)
              .location || 'Standard'})
          {/if}
        </FlatTd>
        <FlatTd>{translation.label}</FlatTd>
        <FlatTd>{translation.full_label}</FlatTd>
        {#if hasRespRights}
          <FlatTd>
            <div
              class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
              <Modal>
                <ModalButton>
                  <button
                    class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                    use:tooltip={{ text: $_('global.buttons.modify') }}
                    on:click={openModal(CreateResponsibilityTranslation, {
                      translationToModify: translation,
                    })}>
                    <Pencil color="#404040" size="1.5em" />
                  </button>
                </ModalButton>
              </Modal>
              {#if translation?.language_id?.toUpperCase() !== 'EN-001'}
                <!--Remove the english world translation is forbidden-->
                <Modal>
                  <ModalButton>
                    <button
                      class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                      use:tooltip={{ text: $_('branches.deletion.button') }}
                      on:click={openModal(DeleteResponsibilityTranslation, {
                        translationToDelete: translation,
                      })}>
                      <Delete color="#404040" size="1.5em" />
                    </button>
                  </ModalButton>
                </Modal>
              {/if}
            </div>
          </FlatTd>
        {/if}
      </FlatTr>
    {/each}
  </span>
</FlatTable>

<Modal>
  <ModalButton bind:openModal />
</Modal>
