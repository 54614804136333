<script lang="ts">
  import AutoComplete from 'simple-svelte-autocomplete';
  import { maxLength, required, validators, useForm } from 'svelte-use-form';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import type { BranchModel } from '../../../models/branch.model';
  import { Branches } from '../../../stores/branches.store';
  import { getContext } from 'svelte';
  import Close from 'svelte-material-icons/Close.svelte';
  import { branchesService } from '../../../services/branches.service';
  import { RoleModel } from '../../../models/role.model';
  import { roleService } from '../../../services/role.service';
  import { Roles } from '../../../stores/roles.store';
  import { RoleLength } from '../../../shared/role.shared';

  const { close } = getContext('simple-modal');

  export let selectedBranches: BranchModel[] = [];
  export let roleToEdit: RoleModel;

  let roleTranslation: string;
  let listBranches: BranchModel[] = [];

  const roleForm = useForm();

  function prepareData() {
    let branches = [];
    if (selectedBranches.length > 0) {
      for (const branch of selectedBranches) {
        branches.push(branch.id);
      }
    }

    const body = {
      branches: branches,
    };

    roleToEdit ? void modifyRole(body) : void createRole(body);
    close();
  }

  function createRole(body) {
    // Initialize translations
    body.role_translations = [
      {
        language_id: 'en-001',
        translation: roleTranslation,
      },
    ];

    void roleService.create(body).then(res => {
      if (res) {
        let roleCreated = new RoleModel(res);
        roleCreated.nameTranslated = roleTranslation;
        roleCreated.branches?.forEach(branchJob => {
          branchJob.shortName = $Branches.find(
            branch => branch.id == branchJob.id
          )?.shortName;
        });
        $Roles = [roleCreated, ...$Roles];
      }
    });
  }

  function modifyRole(body) {
    void roleService.patch(roleToEdit.id, body).then(res => {
      if (res) {
        let roleEdited = new RoleModel(res);
        roleEdited.nameTranslated = roleToEdit.nameTranslated;
        roleEdited.branches?.forEach(branchJob => {
          branchJob.shortName = $Branches.find(
            branch => branch.id == branchJob.id
          )?.shortName;
        });
        const index = $Roles.findIndex(roles => roles.id === roleEdited.id);
        $Roles[index] = roleEdited;
      }
    });
  }

  async function formInit() {
    // Get Branches if not loaded
    if ($Branches.length === 0) {
      await branchesService?.getAll()?.then(res => {
        Branches.set(res);
      });
    }
    listBranches = $Branches;

    // Init autocomplete
    if (selectedBranches.length === 0 && roleToEdit) {
      selectedBranches = roleToEdit.branches;
    }

    // Remove existing branches to branches input if exists
    selectedBranches?.forEach(branch => {
      listBranches = listBranches.filter(branches => branches.id !== branch.id);
    });
  }

  function actionBranch() {
    void prepareData();
  }

  onMount(() => {
    void formInit();
  });
</script>

<form use:roleForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {roleToEdit ? $_('role.modification.edit') : $_('role.create')}
    </h2>
  </div>

  <div class="vtmn-flex vtmn-flex-col vtmn-justify-around vtmn-h-4/6 modal-content">
    {#if !roleToEdit}
      <div class="input-wrapper">
        <label for="roleTranslation" class="control-label"
          >{$_('role.translation')}</label>
        <input
          bind:value={roleTranslation}
          class="vtmn-block vtmn-w-full vtmn-rounded vtmn-sm:text-sm form-control"
          id="roleTranslation"
          name="roleTranslation"
          placeholder={$_('role.modification.translation')}
          type="text"
          use:validators={[required, maxLength(RoleLength)]} />
      </div>
      <div
        class="vtmn-flex vtmn-justify-end {roleTranslation?.length > RoleLength
          ? 'control-label-danger'
          : 'control-label'}">
        {roleTranslation?.length || 0}/{RoleLength}
      </div>
    {/if}

    <div class="input-wrapper">
      <label for="roleBranches" class="control-label"
        >{$_('job.modification.branches')}</label>
      <AutoComplete
        multiple="true"
        bind:items={listBranches}
        bind:selectedItem={selectedBranches}
        labelFieldName="labelTranslated"
        delay="200"
        localFiltering="false"
        placeholder={$_('job.modification.branches_placeholder')}
        maxItemsToShowInList="1000"
        inputId="roleBranches"
        inputClassName="form-control">
        <div
          slot="tag"
          class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start vtmn-bg-grey-light-6 vtmn-rounded-full"
          let:label
          let:item
          let:unselectItem>
          <span class="vtmn-p-1">{label}</span>
          <button
            class="vtmn-p-1 vtmn-border-none"
            on:click|preventDefault={unselectItem(item)}>
            <Close color="#404040" />
          </button>
        </div>
      </AutoComplete>
    </div>

    <div
      class="vtmn-p-5 vtmn-w-full vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-between">
      <button
        class="vtmn-float-left vtmn-btn vtmn-btn_variant--secondary vtmn-border vtmn-btn_size--small
            vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        on:click|preventDefault={close}>
        {$_('job.cancel')}
      </button>

      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        disabled={!$roleForm.valid}
        on:click|preventDefault={actionBranch}>
        {$_('job.validate')}
      </button>
    </div>
  </div>
</form>
