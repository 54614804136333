import { branchTranslationsService } from '../../../services/branchTranslations.service';
import { Branches } from '../../../stores/branches.store';
import { userService } from '../../../services/user.service';
import { UserInfo } from '../../../models/userInfo.model';
export function sortBranches(branches) {
    return branches.sort((a, b) => (a.shortName < b.shortName ? -1 : 1));
}
export function getBranchTranslations(branches) {
    for (const branch of branches) {
        branchTranslationsService
            .getAll({ params: { branch_id: branch.id } })
            .then((translations) => {
            branch.branchTranslations = translations;
            Branches.set(branches); //update the store once we got the data for dynamic display
        });
    }
    return branches;
}
export function returnCorrectBranchTranslation(branchTranslations, preferredLanguage, preferredLanguageWithoutLocale, notFoundMessage = 'Not found') {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return (
    // 4 cases here : the preferredLanguage (i.e: fr-CA), the preferredLanguage without locale (i.e: fr), english (World) or one english (doesn't matter of the locale)
    (_k = (_h = (_f = (_d = (_b = (_a = branchTranslations.find(translation => translation.language_id.toLowerCase() === preferredLanguage.toLowerCase())) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : (_c = branchTranslations.find(translation => translation.language_id.toLowerCase().split('-')[0] ===
        preferredLanguageWithoutLocale.toLowerCase())) === null || _c === void 0 ? void 0 : _c.label) !== null && _d !== void 0 ? _d : (_e = branchTranslations.find(translation => translation.language_id.toLowerCase() === 'en-001')) === null || _e === void 0 ? void 0 : _e.label) !== null && _f !== void 0 ? _f : (_g = branchTranslations.find(translation => translation.language_id.toLowerCase().split('-')[0] === 'en')) === null || _g === void 0 ? void 0 : _g.label) !== null && _h !== void 0 ? _h : (_j = branchTranslations.find(translation => translation.language_id !== null)) === null || _j === void 0 ? void 0 : _j.label) !== null && _k !== void 0 ? _k : notFoundMessage);
}
export function loadBranchLeadersNames(branches, displayNameError) {
    var _a, _b, _c;
    for (const [index, branch] of branches.entries()) {
        if (!((_a = branch.responsibleUser) === null || _a === void 0 ? void 0 : _a.fullName)) {
            (_c = userService
                .getAll({ params: { ldap_uuid: (_b = branch.responsibleUser) === null || _b === void 0 ? void 0 : _b.uuid } })) === null || _c === void 0 ? void 0 : _c.then((contributors) => {
                if (contributors.length > 0) {
                    branches[index].responsibleUser = contributors[0];
                }
                else {
                    branches[index].responsibleUser = new UserInfo();
                    branches[index].responsibleUser.fullName = displayNameError;
                }
                Branches.set(branches); //update the store once we got the data for dynamic display
            });
        }
    }
    return branches;
}
