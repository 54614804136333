<script>
  import { isAuthenticated } from '@dopry/svelte-oidc';
  import TopNavigation from '../components/Navigation/TopNavigation.svelte';
  import OwnDecorator from '../components/OwnDecorator.svelte';
  import { Countries } from '../stores/countries.store';
  import { countryService } from '../services/country.service';

  const links = [
    ['./branches', 'branches.name.plural'],
    ['./jobs', 'job.name.plural'],
    ['./roles', 'role.name.plural'],
    ['./responsibilities', 'responsibility.name.plural'],
    ['./skills', 'skill.name.plural'],
  ];

  if ($isAuthenticated && $Countries.length === 0) {
    countryService?.getAll()?.then(res => {
      Countries.set(res);
    });
  }
</script>

{#if $isAuthenticated}
  <TopNavigation {links} />
  <div class="vtmn-w-11/12 vtmn-m-auto">
    <slot decorator={OwnDecorator} />
  </div>
{/if}
