import ApiService from './api.service';
import { CountryModel } from '../models/country.model';
export class CountryService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = 'countries';
        this.entity = CountryModel;
        this.baseUrl = MASTER_FI_EXCHANGE_RATE_BASE_URL;
        this.apiKey = MASTER_FI_EXCHANGE_RATE_X_API_KEY;
    }
}
export const countryService = new CountryService();
