<script lang="ts">
  import { getContext } from 'svelte';

  const { open } = getContext('simple-modal');

  export function openModal(
    componentModal,
    componentProps = {},
    options = {},
    callbacks = {}
  ) {
    open(componentModal, componentProps, options, callbacks);
  }
</script>

<slot />
