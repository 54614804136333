<script lang="ts">
  import { OidcContext } from '@dopry/svelte-oidc';
  import Login from './components/Auth/Login.svelte';
  import { Router } from '@roxi/routify';
  import { routes } from '../.routify/routes';
  import { UserInfos } from './stores/userInfo.store';
  import { initI18n } from './services/i18n.service';
  import { SvelteToast } from '@zerodevx/svelte-toast';
  import '@vtmn/css/dist/index.css';
  import '@vtmn/css-loader/dist/index.css';
  import '@vtmn/css-design-tokens/dist/index.css';

  initI18n(navigator.language);
  UserInfos.subscribe(userInfo => {
    initI18n(userInfo?.preferredLanguage as string);
  });
</script>

<main>
  <OidcContext
    client_id="AUTH_CLIENT_ID"
    issuer="AUTH_ISSUER"
    post_logout_redirect_uri=""
    redirect_uri="AUTH_REDIRECT_URI"
    scope="profile email openid">
    <Login />
  </OidcContext>
  <Router {routes} />
  <SvelteToast />
</main>

{#if 'AUTH_ISSUER' === 'https://preprod.idpdecathlon.oxylane.com'}
  <style>
    html {
      background-image: linear-gradient(90deg, #ff9a8b 0%, #ff6a88 55%, #ff99ac 100%);
    }
  </style>
{/if}
