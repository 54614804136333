<!--Small component used for navigation inside the pages-->
<style>
  nav {
    color: var(--vtmn-color_brand-digital);
  }
</style>

<script>
  import Navigation from './Navigation.svelte';

  export let links;
</script>

<div class="vtmn-flex-1 vtmn-flex vtmn-flex-col vtmn-flex-wrap vtmn-m-auto">
  <nav class="vtmn-px-4 vtmn-flex vtmn-bg-white vtmn-h-8 vtmn-mr-0">
    <Navigation {links} />
  </nav>
</div>
