<script lang="ts">
  import AutoComplete from 'simple-svelte-autocomplete';
  import { useForm } from 'svelte-use-form';
  import { _ } from 'svelte-i18n';
  import { getContext, onMount } from 'svelte';
  import { ResponsibilityModel } from '../../../models/responsibility.model';
  import { JobModel } from '../../../models/job.model';
  import { jobResponsibilityService } from '../../../services/jobResponsibility.service';
  import { JobResponsibilityModel } from '../../../models/jobResponsibility.model';
  import { JobResponsibility } from '../../../stores/jobResponsibility.store';
  import { responsibilityService } from '../../../services/responsibility.service';
  import { CountryModel } from '../../../models/country.model';
  import { Countries } from '../../../stores/countries.store';
  import {
    getContributorRightsForBranches,
    isAdmin,
    isBranchLeader,
    isBranchUnitedContributor,
  } from '../../Auth/rights.utils';
  import { UserInfos } from '../../../stores/userInfo.store';
  import { jobService } from '../../../services/job.service';
  import { ResponsibilityCreationStep } from '../../../stores/responsibilityCreationJourney.store';
  import { countryService } from '../../../services/country.service';

  export let selectedJob: JobModel = undefined;
  export let selectedResponsibility: ResponsibilityModel = undefined;
  export let linkAfterResponsibilityCreation = false;
  let canCreateLink = true;
  let perimeter;
  let selectedCountry: CountryModel;
  let countries = $Countries;
  let canCreateUnitedLink = false;
  const { close } = getContext('simple-modal');
  const jobForm = useForm();
  import Close from 'svelte-material-icons/Close.svelte';

  function createLink() {
    let body = {
      responsibility_id: selectedResponsibility?.id,
      job_id: selectedJob?.id,
      country_id: '',
    };
    if (perimeter == 'LOCAL') {
      body.country_id = selectedCountry.code;
    }
    void jobResponsibilityService.create(body).then(res => {
      if (res) {
        let jobResponsibilityCreated = new JobResponsibilityModel(res);
        jobResponsibilityCreated.responsibility = selectedResponsibility;
        jobResponsibilityCreated.job = selectedJob;
        Array.isArray($JobResponsibility[jobResponsibilityCreated.country.code])
          ? $JobResponsibility[jobResponsibilityCreated.country.code].push(
              jobResponsibilityCreated
            )
          : ($JobResponsibility[jobResponsibilityCreated.country.code] = [
              jobResponsibilityCreated,
            ]);
        JobResponsibility.set($JobResponsibility);
        close();
      }
    });
  }

  async function searchResponsibilities(keyword: string) {
    let listResponsibilities = await responsibilityService?.getAllWithPagination({
      params: { label: keyword },
    });
    return listResponsibilities[0];
  }

  async function searchJob(keyword: string) {
    let listJobs = await jobService?.getAllWithPagination({
      params: { translation: keyword },
    });
    return listJobs[0];
  }

  function checkRights() {
    if (
      isAdmin($UserInfos) ||
      isBranchLeader($UserInfos, selectedJob.branches) ||
      isBranchUnitedContributor($UserInfos, selectedJob.branches)
    ) {
      perimeter = 'UNITED';
      canCreateUnitedLink = true;
      canCreateLink = true;
    } else {
      // If the user can't create a united link, he can create a link only for a limited selection of countries
      const branchesContributor = getContributorRightsForBranches(
        $UserInfos,
        selectedJob.branches
      );
      const local_rights = branchesContributor.filter(bc => bc.countries?.length > 0);
      countries = $Countries.filter(country =>
        local_rights.find(r => r.countries.find(c => c === country.code))
      );
      canCreateUnitedLink = false;
      perimeter = 'LOCAL';
      canCreateLink = countries?.length !== 0;
    }
  }

  function skipOrQuit() {
    linkAfterResponsibilityCreation ? $ResponsibilityCreationStep++ : close();
  }

  onMount(() => {
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
        countries = res;
      });
    }
    void checkRights();
  });
</script>

<form use:jobForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {$_('jobs.modification.link')}
    </h2>
  </div>

  <div class="vtmn-flex vtmn-flex-col vtmn-justify-around vtmn-h-4/6 modal-content">
    {#if !linkAfterResponsibilityCreation}
      <p class="control-label">
        {$_('job.name.single')} : {selectedJob?.internalTranslationTranslated}
      </p>
      <div class="input-wrapper">
        <label for="responsibilityToLink" class="control-label"
          >{$_('responsibility.name.single')}</label>
        <AutoComplete
          searchFunction={searchResponsibilities}
          bind:selectedItem={selectedResponsibility}
          labelFieldName="labelTranslated"
          placeholder={$_('responsibility.name.single')}
          delay="200"
          localFiltering="false"
          inputId="responsibilityToLink"
          name="responsibilityToLink">
          <div slot="item" let:item>
            <div>{item?.labelTranslated}</div>
            <div class="vtmn-text-grey-light-1">{item?.fullLabelTranslated}</div>
          </div>
        </AutoComplete>
      </div>
    {:else}
      <p class="control-label">
        {$_('responsibility.name.single')} : {selectedResponsibility?.labelTranslated}
      </p>
      <div class="input-wrapper">
        <label for="jobToLink" class="control-label">{$_('job.name.single')}</label>
        <AutoComplete
          searchFunction={searchJob}
          bind:selectedItem={selectedJob}
          labelFieldName="internalTranslationTranslated"
          placeholder={$_('job.name.single')}
          delay="200"
          localFiltering="false"
          inputId="jobToLink"
          name="jobToLink"
          onChange={checkRights}>
          <div slot="item" let:item>
            <div>{item?.internalTranslationTranslated}</div>
            <div class="vtmn-text-grey-light-1">
              {item?.externalTranslationTranslated}
            </div>
          </div>
        </AutoComplete>
      </div>
    {/if}

    {#if canCreateLink}
      <div class="input-wrapper">
        <label class="control-label">{$_('job.table.perimeter')}</label>
        {#if canCreateUnitedLink}
          <label class="vtmn-pr-2">
            <input
              type="radio"
              bind:group={perimeter}
              name="perimeter"
              value={'UNITED'} />
            UNITED
          </label>
        {/if}

        <label>
          <input type="radio" bind:group={perimeter} name="perimeter" value={'LOCAL'} />
          LOCAL
        </label>
      </div>

      {#if perimeter === 'LOCAL'}
        <div class="input-wrapper">
          <label for="jobRespCountries" class="control-label"
            >{$_('job.modification.countries')}</label>
          <AutoComplete
            items={countries}
            bind:selectedItem={selectedCountry}
            labelFieldName="name"
            maxItemsToShowInList="1000"
            placeholder={$_('job.modification.countries_placeholder')}
            inputId="jobRespCountries"
            inputClassName="form-control">
            <div let:item slot="item">
              <span class="countryFlag">{item.flag}</span>{' ' + item.name}
            </div>
            <div
              slot="tag"
              class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start vtmn-bg-grey-light-6 vtmn-rounded-full"
              let:label
              let:item
              let:unselectItem>
              <span class="vtmn-p-1"
                ><span class="countryFlag">{item.flag}</span>{' ' + item.name}</span>
              <button
                class="vtmn-p-1 vtmn-border-none"
                on:click|preventDefault={unselectItem(item)}>
                <Close color="#404040" />
              </button>
            </div>
          </AutoComplete>
        </div>
      {/if}
    {:else}
      <p class="control-label">{$_('jobs.link.forbidden')}</p>
    {/if}
    <div
      class="vtmn-p-5 vtmn-w-full vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-between">
      <button
        class="vtmn-float-left vtmn-btn vtmn-btn_variant--secondary vtmn-border vtmn-btn_size--small
            vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        on:click|preventDefault={skipOrQuit}>
        {linkAfterResponsibilityCreation ? $_('global.skip') : $_('job.cancel')}
      </button>
      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small vtmn-mr-auto vtmn-my-auto vtmn-ml-auto"
        disabled={!$jobForm.valid || !canCreateLink}
        on:click|preventDefault={createLink}>
        {$_('job.validate')}
      </button>
    </div>
  </div>
</form>
