<style>
  /* Mandatory because my version of vitamin has errors with secondary buttons. I can't update without changing all the style (maybe in another PR)*/
  .div-export-csv {
    height: 6rem;
  }
  .button-export-csv {
    color: var(--vtmn-color_brand-digital);
    background-color: var(--vtmn-color_white);
  }
  .button-export-csv:hover {
    color: var(--vtmn-color_white);
  }
</style>

<script lang="ts">
  import { jobService } from '../../../services/job.service';
  import { skillsService } from '../../../services/skills.service';
  import { _ } from 'svelte-i18n';

  type DataDownloadableTpe = 'job' | 'skill';
  export let dataType: DataDownloadableTpe;

  async function downloadCSV() {
    let csvData;

    if (dataType === 'job') {
      csvData = await jobService.getCSV();
    } else if (dataType === 'skill') {
      csvData = await skillsService.getCSV();
    }

    if (csvData) {
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      const date = new Date()
        .toISOString()
        .replace(/T.*/, '')
        .split('-')
        .reverse()
        .join('');
      a.href = url;
      a.download = dataType + 's' + date + '.csv'; // example :
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }
  }
</script>

<div class="vtmn-m-auto vtmn-w-5/12 vtmn-h-24 div-export-csv">
  <button
    class="vtmn-btn vtmn-w-full vtmn-h-full button-export-csv"
    on:click={downloadCSV}>{$_(dataType + '.title')}</button>
</div>
