<script lang="ts">
  import FlatTd from '../../../Generics/Table/FlatTd.svelte';
  import Plus from 'svelte-material-icons/Plus.svelte';
  import { searchSport } from '../../../../services/sports.service';
  import AutoComplete from 'simple-svelte-autocomplete';
  import { SportModel } from '../../../../models/sport.model';
  import { LoadSkillSport, SkillSports } from '../../../../stores/sports.store';
  import { SkillSportModel } from '../../../../models/skillSport.model';
  import { skillSportService } from '../../../../services/skillsports.service';
  import { _ } from 'svelte-i18n';

  export let skill;
  let selectedSport: SportModel;

  const unsubscribeSkillSport = LoadSkillSport.subscribe(skillSport => {
    selectedSport = skillSport.sport;
  });

  function createSkillSport() {
    let dataNewSkillSport = {
      skill_id: skill.id,
      sport_id: selectedSport?.id,
    };
    let newSkillSport = new SkillSportModel(dataNewSkillSport);
    newSkillSport.sport = selectedSport;
    void skillSportService.create(dataNewSkillSport).then(res => {
      newSkillSport.id = res?.id;
      if (newSkillSport.id) {
        SkillSports.update(skillLeaders => [...skillLeaders, newSkillSport]);
      }
      selectedSport = new SportModel();
      $LoadSkillSport = new SkillSportModel();
    });
  }
</script>

<tr class="vtmn-border-b vtmn-border-grey-light-2 vtmn-py-10">
  <FlatTd>
    <div class="vtmn-flex vtmn-flex-row vtmn-justify-center vtmn-items-center">
      <div class="vtmn-rounded-full vtmn-w-full">
        <AutoComplete
          searchFunction={searchSport}
          bind:selectedItem={selectedSport}
          labelFieldName="name"
          maxItemsToShowInList="10"
          delay="200"
          localFiltering="false"
          placeholder={$_('sport.name.single')} />
      </div>
    </div>
  </FlatTd>
  <FlatTd>
    <div class=" vtmn-flex vtmn-justify-center vtmn-rounded-full">
      <button
        class="vtmn-btn vtmn-btn_variant--ghost"
        on:click={() => {
          createSkillSport();
        }}
        disabled={!selectedSport?.id}>
        <Plus color="#404040" size="1.5em" />
      </button>
    </div>
  </FlatTd>
</tr>
