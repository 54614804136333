<script>
  import CreateResponsibility from './CreateResponsibility.svelte';
  import { ResponsibilityCreationStep } from '../../../stores/responsibilityCreationJourney.store';
  import LinkJobResponsibility from '../JobResponsibility/LinkJobResponsibility.svelte';
  import { Responsibilities } from '../../../stores/responsibilities.store';

  $ResponsibilityCreationStep = 1;
</script>

{#if $ResponsibilityCreationStep === 1}
  <CreateResponsibility />
{/if}
{#if $ResponsibilityCreationStep === 2}
  <LinkJobResponsibility
    linkAfterResponsibilityCreation={true}
    selectedResponsibility={$Responsibilities[$Responsibilities.length - 1]} />
{/if}
