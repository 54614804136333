<script lang="ts">
  import { getContext, onMount } from 'svelte';
  import SortableList from 'svelte-sortable-list';
  import { _ } from 'svelte-i18n';
  import RawSkillOrder from './RawSkillOrder.svelte';
  import {
    ResponsibilitySkillsKey,
    ResponsibilitySkillsNotKey,
  } from '../../../stores/responsibilitySkill.store';
  import { responsibilitySkillService } from '../../../services/responsibilitySkill.service';
  import { ResponsibilitySkillModel } from '../../../models/responsibilitySkill.model';

  const { close } = getContext('simple-modal');
  let loading = true;
  export let key = true;
  export let responsibilitySkillList;

  function handleList(ev = undefined) {
    if (ev) {
      responsibilitySkillList = ev.detail;
    }
    responsibilitySkillList.forEach((responsibilitySkill, i) => {
      responsibilitySkill.order = i + 1;
      if (i === responsibilitySkillList.length - 1) {
        loading = false;
      }
    });
  }

  function patchNewOrder() {
    let body = [];
    responsibilitySkillList.forEach(function (value: ResponsibilitySkillModel) {
      let object = {
        id: value.id,
        skill_order: value.order,
      };
      body.push(object);
    });
    responsibilitySkillService
      .patch('skill_key/' + String(key), body)
      .then(returnCall => {
        if (returnCall) {
          key
            ? ($ResponsibilitySkillsKey = responsibilitySkillList)
            : ($ResponsibilitySkillsNotKey = responsibilitySkillList);
        }
        close();
      });
  }

  function validate() {
    patchNewOrder();
  }

  onMount(() => {
    handleList();
  });
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('global.buttons.order')}
  </h2>
</div>

{#if loading === false}
  <div class="vtmn-flex vtmn-flex-col vtmn-justify-around vtmn-h-4/6 modal-content">
    <SortableList
      list={responsibilitySkillList}
      key="order"
      on:sort={handleList}
      let:item>
      <RawSkillOrder responsibilitySkill={item} />
    </SortableList>

    <div class="vtmn-p-2">
      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
        on:click|preventDefault={validate}>
        {$_('global.buttons.validate')}
      </button>
    </div>
  </div>
{/if}
