import { ResponsibilityTranslationModel } from './responsibilityTranslation.model';
export class ResponsibilityModel {
    constructor(data = {}) {
        var _a, _b, _c, _d;
        this.labelTranslated = '';
        this.fullLabelTranslated = '';
        this.id = data === null || data === void 0 ? void 0 : data.id;
        this.translations = [];
        if (data.responsibility_translations) {
            data.responsibility_translations.forEach((translation) => {
                var _a;
                this.labelTranslated = translation === null || translation === void 0 ? void 0 : translation.label;
                const respTranslation = new ResponsibilityTranslationModel(translation);
                (_a = this.translations) === null || _a === void 0 ? void 0 : _a.push(respTranslation);
            });
        }
        if (data.responsibility_translated) {
            this.labelTranslated = (_a = data.responsibility_translated) === null || _a === void 0 ? void 0 : _a.label;
            this.fullLabelTranslated = (_b = data.responsibility_translated) === null || _b === void 0 ? void 0 : _b.full_label;
        }
        this.skillCount = (_c = data === null || data === void 0 ? void 0 : data.skill_count) !== null && _c !== void 0 ? _c : 0;
        this.jobCount = (_d = data === null || data === void 0 ? void 0 : data.job_count) !== null && _d !== void 0 ? _d : 0;
    }
}
