import ApiService from './api.service';
import { BranchModel } from '../models/branch.model';
export class BranchesService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/branches';
        this.entity = BranchModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const branchesService = new BranchesService();
