<script lang="ts">
  import { BranchContributorModel } from '../../../../models/branchContributor.model';
  import { branchContributorService } from '../../../../services/branchContributor.service';
  import { userService } from '../../../../services/user.service';
  import AutoComplete from 'simple-svelte-autocomplete';
  import { UserInfo } from '../../../../models/userInfo.model';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { BranchContributors } from '../../../../stores/branchContributors.store';
  import FlatTr from '../../../Generics/Table/FlatTr.svelte';
  import FlatTd from '../../../Generics/Table/FlatTd.svelte';
  import AccountPlus from 'svelte-material-icons/AccountPlus.svelte';
  import type { CountryModel } from '../../../../models/country.model';
  import { countryService } from '../../../../services/country.service';
  import { Countries } from '../../../../stores/countries.store';
  import Close from 'svelte-material-icons/Close.svelte';
  import UserImage from '../../User/UserImage.svelte';

  export let branchId: string;
  export let editMode: boolean;

  let selectedUser: UserInfo;
  let selectedCountries: CountryModel[];

  function createContributor() {
    let dataNewContributor = {
      branch_id: branchId,
      member_id: selectedUser?.uuid,
      united: selectedCountries?.length > 0 ? false : true,
      countries: selectedCountries.map(country => country.code),
    };
    let newContributor: BranchContributorModel = new BranchContributorModel(
      dataNewContributor
    );
    newContributor.contributor = selectedUser;
    void branchContributorService.create(dataNewContributor).then(res => {
      newContributor.id = res?.id;
      if (newContributor.id) {
        BranchContributors.update(branchContributors => [
          ...branchContributors,
          newContributor,
        ]);
      }
      selectedUser = new UserInfo();
      selectedCountries = [];
    });
  }

  onMount(() => {
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
      });
    }
  });
</script>

<FlatTr>
  <FlatTd>
    <AutoComplete
      searchFunction={userService.searchUser}
      bind:selectedItem={selectedUser}
      labelFieldName="fullName"
      maxItemsToShowInList="10"
      placeholder={$_('branch_contributor.table.name')}
      delay="200"
      localFiltering="false"
      inputId="User">
      <div
        slot="item"
        let:item
        class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start">
        <UserImage uid={item.uid} />
        <div class="vtmn-py-4">{item.fullName}</div>
      </div>
    </AutoComplete>
  </FlatTd>
  <FlatTd>
    <AutoComplete
      multiple="true"
      items={$Countries}
      bind:selectedItem={selectedCountries}
      labelFieldName="name"
      maxItemsToShowInList="1000"
      placeholder={$_('global.countries')}
      inputId="Country">
      <div let:item slot="item">
        <span class="countryFlag">{item.flag}</span>{' ' + item.name}
      </div>
      <div
        slot="tag"
        class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start vtmn-bg-grey-light-6 vtmn-rounded-full"
        let:label
        let:item
        let:unselectItem>
        <span class="vtmn-p-1"
          ><span class="countryFlag">{item.flag}</span>{' ' + item.name}</span>
        <button
          class="vtmn-p-1 vtmn-border-none"
          on:click|preventDefault={unselectItem(item)}>
          <Close color="#404040" />
        </button>
      </div>
    </AutoComplete>
  </FlatTd>

  <FlatTd>
    <div class="vtmn-flex">
      <button
        class="vtmn-btn vtmn-btn_variant--ghost vtmn-m-auto"
        on:click={() => {
          createContributor();
        }}
        disabled={!selectedUser?.uuid}>
        <AccountPlus color="#404040" size="1.5em" />
      </button>
    </div>
  </FlatTd>
</FlatTr>
