import { BranchModel } from '../../models/branch.model';
/** Check if a user has Admin role */
export function isAdmin(user) {
    var _a;
    return ((_a = user === null || user === void 0 ? void 0 : user.rights) === null || _a === void 0 ? void 0 : _a.find(r => { var _a; return (_a = r.roles) === null || _a === void 0 ? void 0 : _a.includes('ROLE_ADMIN'); })) !== undefined;
}
/** Check if a user has Translator role */
export function isTranslator(user) {
    var _a;
    return ((_a = user === null || user === void 0 ? void 0 : user.rights) === null || _a === void 0 ? void 0 : _a.find(r => { var _a; return (_a = r.roles) === null || _a === void 0 ? void 0 : _a.includes('ROLE_TRANSLATOR'); })) !== undefined;
}
/** Check if a user is branch leader for at least one of the branches in parameter */
export function isBranchLeader(user, branches) {
    var _a;
    const branches_leader = (_a = user === null || user === void 0 ? void 0 : user.rights) === null || _a === void 0 ? void 0 : _a.filter(r => { var _a; return (_a = r.roles) === null || _a === void 0 ? void 0 : _a.includes('BRANCH_LEADER'); });
    return (branches_leader.find(br => branches === null || branches === void 0 ? void 0 : branches.find(branch => branch.id === br.branch_id)) !==
        undefined);
}
/** Check if a user is branch contributor for at least one branch */
export function isBranchLeaderForAtLeastOneBranch(user) {
    var _a;
    return ((_a = user === null || user === void 0 ? void 0 : user.rights) === null || _a === void 0 ? void 0 : _a.find(r => { var _a; return (_a = r.roles) === null || _a === void 0 ? void 0 : _a.includes('BRANCH_LEADER'); })) !== undefined;
}
/** Check if a user is branch contributor for at least one branch */
export function isBranchContributorForAtLeastOneBranch(user) {
    var _a;
    return ((_a = user === null || user === void 0 ? void 0 : user.rights) === null || _a === void 0 ? void 0 : _a.find(r => { var _a; return (_a = r.roles) === null || _a === void 0 ? void 0 : _a.includes('BRANCH_CONTRIBUTOR'); })) !== undefined;
}
/** Check if a user is branch contributor for at least one of the branches in parameter */
export function isBranchContributor(user, branches) {
    return getContributorRightsForBranches(user, branches).length > 0;
}
/** Check if a user is branch united contributor for at least one branch */
export function isBranchUnitedContributorForAtLeastOneBranch(user) {
    var _a;
    const branches_contributor = (_a = user === null || user === void 0 ? void 0 : user.rights) === null || _a === void 0 ? void 0 : _a.filter(r => { var _a; return (_a = r.roles) === null || _a === void 0 ? void 0 : _a.includes('BRANCH_CONTRIBUTOR'); });
    return (branches_contributor.find(bc => { var _a; return !bc.countries || ((_a = bc.countries) === null || _a === void 0 ? void 0 : _a.length) === 0; }) !==
        undefined);
}
/** Check if a user is branch contributor united for at least one of the branches in parameter */
export function isBranchUnitedContributor(user, branches) {
    const all_rights_bc = getContributorRightsForBranches(user, branches);
    return (all_rights_bc.find(bc => { var _a; return !bc.countries || ((_a = bc.countries) === null || _a === void 0 ? void 0 : _a.length) === 0; }) !== undefined);
}
/** Check if a user is branch contributor local for at least one of the branches in parameter */
export function isBranchLocalContributor(user, branches, countries) {
    const all_rights_bc = getContributorRightsForBranches(user, branches);
    const local_rights = all_rights_bc.filter(bc => { var _a; return ((_a = bc.countries) === null || _a === void 0 ? void 0 : _a.length) > 0; });
    return (local_rights.find(br => countries.find(country => br.countries.includes(country.code))) !== undefined);
}
/** Find in the User rights, rights regarding to the branches in parameter */
export function getContributorRightsForBranches(user, branches) {
    var _a;
    const branches_contributor = (_a = user === null || user === void 0 ? void 0 : user.rights) === null || _a === void 0 ? void 0 : _a.filter(r => { var _a; return (_a = r.roles) === null || _a === void 0 ? void 0 : _a.includes('BRANCH_CONTRIBUTOR'); });
    return branches_contributor.filter(br => branches === null || branches === void 0 ? void 0 : branches.find(branch => branch.id === br.branch_id));
}
/** Find in the User rights, the list of branches in which the user is Branch Contributor UNITED or Branch Leader */
export function getBranchesUnitedContributorOrLeader(user) {
    var _a;
    let right_branches = (_a = user === null || user === void 0 ? void 0 : user.rights) === null || _a === void 0 ? void 0 : _a.filter(r => { var _a, _b; return ((_a = r.roles) === null || _a === void 0 ? void 0 : _a.includes('BRANCH_CONTRIBUTOR')) || ((_b = r.roles) === null || _b === void 0 ? void 0 : _b.includes('BRANCH_LEADER')); });
    right_branches = right_branches.filter(bc => { var _a; return !bc.countries || ((_a = bc.countries) === null || _a === void 0 ? void 0 : _a.length) === 0; });
    const branches = [];
    for (const right of right_branches) {
        branches.push(right.branch_id);
    }
    return branches;
}
/** Check if a user is skill leader for at least one of the skills in parameter */
export function isSkillLeader(user, skills) {
    var _a;
    const skills_leader_rights = (_a = user === null || user === void 0 ? void 0 : user.rights) === null || _a === void 0 ? void 0 : _a.filter(r => { var _a; return (_a = r.roles) === null || _a === void 0 ? void 0 : _a.includes('SKILL_LEADER'); });
    return (skills_leader_rights.find(sr => skills === null || skills === void 0 ? void 0 : skills.find(skill => skill.id === sr.skill_id)) !==
        undefined);
}
/** Check if a user can select Human skill type in skill form */
export function checkHumanSkillRights(user, selectedBranch) {
    const humanBranch = new BranchModel({ id: HUMAN_RESOURCES_BRANCH_ID });
    return ((selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.id) === HUMAN_RESOURCES_BRANCH_ID &&
        (isBranchLeader(user, [humanBranch]) ||
            isBranchUnitedContributor(user, [humanBranch]) ||
            isAdmin(user)));
}
/** Check if a user can select Digital skill type in skill form */
export function checkDigitalSkillRights(user, selectedBranch) {
    const digitalBranch = new BranchModel({ id: DIGITAL_BRANCH_ID });
    return ((selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.id) === DIGITAL_BRANCH_ID &&
        (isBranchLeader(user, [digitalBranch]) ||
            isBranchUnitedContributor(user, [digitalBranch]) ||
            isAdmin(user)));
}
export function inferSkillType(isHuman, isDigital) {
    if (isHuman) {
        return 'Human';
    }
    return isDigital ? 'Digital' : 'Technical';
}
