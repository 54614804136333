<script lang="ts">
  import FlatTr from '../../Generics/Table/FlatTr.svelte';
  import FlatTd from '../../Generics/Table/FlatTd.svelte';
  import { url } from '@roxi/routify';
  import { returnCorrectBranchTranslation } from './branches.utils';
  import { UserInfos } from '../../../stores/userInfo.store';
  import { _ } from 'svelte-i18n';
  import ModalButton from '../../Generics/Modal/ModalButton.svelte';
  import Modal from 'svelte-simple-modal';
  import Translate from 'svelte-material-icons/Translate.svelte';
  import AccountGroup from 'svelte-material-icons/AccountGroup.svelte';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import ChevronRight from 'svelte-material-icons/ChevronRight.svelte';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import { tooltip } from '../../Generics/Tooltip/tooltip';
  import ListContributors from './Contributors/ListContributors.svelte';
  import DeleteBranch from './DeleteBranch.svelte';
  import CreateBranch from './CreateBranch.svelte';
  import { isAdmin } from '../../Auth/rights.utils';

  export let branch;
  export let i;
  let openModal;
  $: hasBranchesAdminRights = isAdmin($UserInfos);
</script>

<FlatTr>
  <FlatTd><a href={$url('./' + branch.id)}> {branch.shortName} </a></FlatTd>
  <FlatTd>
    <a href={$url('./' + branch.id)}>
      {branch.labelTranslated !== ''
        ? branch.labelTranslated
        : returnCorrectBranchTranslation(
            branch.branchTranslations,
            $UserInfos.preferredLanguage,
            $UserInfos.preferredLanguageWithoutLocale,
            $_('api_call.translation_not_found')
          )}
    </a>
  </FlatTd>
  <FlatTd>
    {#if branch.responsibleUser?.fullName}
      {branch.responsibleUser.fullName}
    {:else}
      <p class="vtmn-animate-pulse vtmn-bg-grey-light-2 vtmn-h-2 vtmn-w-full" />
    {/if}
  </FlatTd>
  <FlatTd>{branch?.jobsCount}</FlatTd>
  <FlatTd>{branch?.additionalRolesCount}</FlatTd>
  <FlatTd>{branch?.skillsCount}</FlatTd>
  <FlatTd>
    <div class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
      {#if hasBranchesAdminRights}
        <Modal>
          <ModalButton>
            <button
              class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
              use:tooltip={{ text: $_('global.buttons.modify') }}
              on:click={openModal(CreateBranch, { branchIndex: i })}>
              <Pencil color="#404040" size="1.5em" />
            </button>
          </ModalButton>
        </Modal>
      {/if}
      <a
        class="vtmn-m-auto vtmn-relative"
        use:tooltip={{ text: $_('global.translations') }}
        href={$url('./' + branch.id + '/translations')}>
        <Translate color="#404040" size="1.5em" />
      </a>
      <Modal>
        <ModalButton>
          <button
            class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative vtmn-inline-flex vtmn-items-center"
            use:tooltip={{ text: $_('branch_contributor.title') }}
            on:click={openModal(ListContributors, { branch })}>
            <AccountGroup color="#404040" size="1.5em" />
            <div
              class="vtmn-inline-flex vtmn-absolute vtmn--top-0 vtmn--right-0 vtmn-justify-center vtmn-items-center countBadge
                   vtmn-w-3 vtmn-h-3 vtmn-font-semi-bold vtmn-text-white vtmn-rounded-full vtmn-bg-brand-digital vtmn-border-1 vtmn-border-white">
              {branch?.contributorsCount}
            </div>
          </button>
        </ModalButton>
      </Modal>
      {#if hasBranchesAdminRights}
        <Modal>
          <ModalButton>
            <button
              class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
              use:tooltip={{ text: $_('branches.deletion.button') }}
              on:click={openModal(DeleteBranch, { deletedBranch: branch })}>
              <Delete color="#404040" size="1.5em" />
            </button>
          </ModalButton>
        </Modal>
      {/if}
      <a
        class="vtmn-m-auto vtmn-relative"
        use:tooltip={{ text: $_('job.name.plural') }}
        href={$url('./' + branch.id)}>
        <ChevronRight color="#404040" size="1.5em" />
      </a>
    </div>
  </FlatTd>
</FlatTr>

<Modal>
  <ModalButton bind:openModal />
</Modal>
