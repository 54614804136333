import { accessToken } from '@dopry/svelte-oidc';
import { failure, success } from '../components/Toastr/toastr';
import { PaginationModel } from '../models/pagination.model';
import { UserInfos } from '../stores/userInfo.store';
import { UserInfo } from '../models/userInfo.model';
import { _ } from 'svelte-i18n';
import { get } from 'svelte/store';
export class ApiService {
    constructor() {
        this.endpoint = '';
        this.baseUrl = '';
        this.apiKey = '';
        this.token = '';
        this.user = new UserInfo();
        accessToken.subscribe((value) => {
            this.token = value;
        });
        UserInfos.subscribe((value) => {
            this.user = value;
        });
    }
    addHeaders(data, headers) {
        if (!headers) {
            headers = new Headers();
        }
        if (data) {
            headers.append('Content-Type', 'application/json');
        }
        headers.append('Authorization', `Bearer ${this.token}`);
        headers.append('X-Api-Key', this.apiKey);
        return headers;
    }
    async send({ method, path, data, options, headers }) {
        var _a;
        const opts = { method, headers };
        const queryParams = (options === null || options === void 0 ? void 0 : options.params)
            ? new URLSearchParams(options === null || options === void 0 ? void 0 : options.params).toString()
            : null;
        const queryParamsFormat = queryParams ? `?${queryParams}` : '';
        const url = `${this.baseUrl}${(_a = options === null || options === void 0 ? void 0 : options.customEndpoint) !== null && _a !== void 0 ? _a : this.endpoint}${path !== null && path !== void 0 ? path : ''}${queryParamsFormat}`;
        opts.headers = this.addHeaders(data, headers);
        if (data) {
            opts.body = JSON.stringify(data);
        }
        return await fetch(url, opts).then(r => {
            var _a;
            // Prevent errors on empty response such as delete response
            const contentType = r === null || r === void 0 ? void 0 : r.headers.get('content-type');
            const receivingAResponseCondition = contentType &&
                ((contentType === null || contentType === void 0 ? void 0 : contentType.indexOf('application/json')) !== -1 ||
                    (contentType === null || contentType === void 0 ? void 0 : contentType.indexOf('application/ld+json')) !== -1);
            if (r.ok) {
                if (r.status === 201 && method === 'POST') {
                    success(get(_)('api_call.created'));
                }
                else if ((r.status === 204 || r.status === 200) && method === 'DELETE') {
                    success(get(_)('api_call.deleted'));
                    return r.ok;
                }
                else if (r.status === 200 && method === 'PATCH') {
                    success(get(_)('api_call.edited'));
                }
                if (receivingAResponseCondition) {
                    return r.json();
                }
                else if (contentType === null || contentType === void 0 ? void 0 : contentType.indexOf('test/csv')) {
                    return r.text();
                }
                else {
                    return r.ok;
                }
            }
            else {
                void ((_a = r
                    .json()) === null || _a === void 0 ? void 0 : _a.then(content => {
                    var _a, _b;
                    const errorMessage = (_b = (_a = content === null || content === void 0 ? void 0 : content.error_description) !== null && _a !== void 0 ? _a : content === null || content === void 0 ? void 0 : content['hydra:description']) !== null && _b !== void 0 ? _b : (r.statusText ? r.statusText : 'An error has occured');
                    failure(`${errorMessage}`);
                }).catch(error => {
                    r.status === 403
                        ? failure(get(_)('api_call.forbidden'), 30000)
                        : failure(`${error}`);
                }));
                return r.ok;
            }
        });
    }
    get(id, options, headers) {
        return this.send({ method: 'GET', path: `/${id}`, options, headers }).then(response => { var _a, _b; return new this.entity((_b = (_a = response === null || response === void 0 ? void 0 : response.data) !== null && _a !== void 0 ? _a : response === null || response === void 0 ? void 0 : response.content) !== null && _b !== void 0 ? _b : response); });
    }
    getAll(options, headers) {
        return this.send({ method: 'GET', options, headers }).then((response) => {
            var _a, _b;
            if (response.data) {
                return response.data.map((item) => new this.entity(item));
            }
            else if (response.content) {
                return response.content.map((item) => new this.entity(item));
            }
            else if (response['hydra:member']) {
                return response['hydra:member'].map((item) => new this.entity(item));
            }
            else if ((_a = response.user) === null || _a === void 0 ? void 0 : _a.roles) {
                return (_b = response.user) === null || _b === void 0 ? void 0 : _b.roles.map((item) => new this.entity(item));
            }
            else if (response.length > 0) {
                try {
                    return response.map((item) => new this.entity(item));
                }
                catch (e) {
                    return [];
                }
            }
            else {
                return [];
            }
        });
    }
    getAllWithPagination(options) {
        return this.send({ method: 'GET', options }).then((response) => {
            if (response.data) {
                return [
                    response.data.map((item) => new this.entity(item)),
                    new PaginationModel(response.meta),
                ];
            }
            else if (response.content) {
                return [
                    response.content.map((item) => new this.entity(item)),
                    new PaginationModel(response),
                ];
            }
            else if (response['hydra:member']) {
                return [
                    response['hydra:member'].map((item) => new this.entity(item)),
                    new PaginationModel(response),
                ];
            }
            else {
                return [];
            }
        });
    }
    getCSV(options, headers) {
        const dataType = this.endpoint.substring(0, this.endpoint.length - 1); // To respect the structure API, ex: we convert /jobs into /job
        options = {
            customEndpoint: '/export/' + dataType + '/csv',
        };
        return this.send({ method: 'GET', options, headers }).then(response => response);
    }
    create(body, options, headers) {
        return this.send({ method: 'POST', data: body, options, headers });
    }
    update(id, body, options, headers) {
        return this.send({ method: 'PUT', path: `/${id}`, data: body, options, headers });
    }
    patch(id, body, options, headers) {
        return this.send({ method: 'PATCH', path: `/${id}`, data: body, options, headers });
    }
    delete(id, options, headers) {
        return this.send({ method: 'DELETE', path: `/${id}`, options, headers });
    }
}
export default ApiService;
