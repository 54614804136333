<script lang="ts">
  import { BranchContributorModel } from '../../../../models/branchContributor.model';
  import { branchContributorService } from '../../../../services/branchContributor.service';
  import { onMount } from 'svelte';
  import type { CountryModel } from '../../../../models/country.model';
  import { countryService } from '../../../../services/country.service';
  import { Countries } from '../../../../stores/countries.store';
  import AutocompleteEditCountries from '../../../Generics/Autocomplete/AutocompleteEditCountries.svelte';
  import { BranchContributors } from '../../../../stores/branchContributors.store';

  export let contributorToEdit: BranchContributorModel;

  let selectedCountries: CountryModel[];

  function editContributor() {
    let dataEditContributor = {
      united: selectedCountries?.length > 0 ? false : true,
      countries: selectedCountries.map(country => country.code),
    };
    void branchContributorService
      .patch(contributorToEdit.id, dataEditContributor)
      .then(response => {
        if (response) {
          let contributorEdited = $BranchContributors.find(
            branchContributor =>
              branchContributor.contributor.uuid === contributorToEdit.contributor.uuid
          );
          contributorEdited.countries = selectedCountries;
          contributorEdited.united = selectedCountries?.length > 0 ? false : true;
          contributorToEdit = new BranchContributorModel();
        }
      });
  }

  function initCountries() {
    // Mandatory because branchCountributor the API do not returned country name and flag
    for (const country of contributorToEdit.countries) {
      const currentCountry = $Countries.find(
        countries => countries.code === country.code
      );
      if (currentCountry) {
        selectedCountries.push(currentCountry);
      }
    }
  }

  function cancelEdit() {
    contributorToEdit = new BranchContributorModel();
  }

  onMount(() => {
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(allCountries => {
        Countries.set(allCountries);
        initCountries();
      });
    }
    initCountries();
  });
</script>

<AutocompleteEditCountries
  bind:selectedCountries
  {cancelEdit}
  validateEdit={editContributor} />
