import ApiService from './api.service';
import { RightsUserModel } from '../models/rightsUserModel';
export class RightsUserSearchService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/user_rights';
        this.entity = RightsUserModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const rightsUserSearchService = new RightsUserSearchService();
