import ApiService from './api.service';
import { UserInfo } from '../models/userInfo.model';
class UserService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = 'search';
        this.entity = UserInfo;
        this.baseUrl = SEARCH_BASE_URL;
        this.apiKey = SEARCH_X_API_KEY;
    }
    async searchUser(keyword) {
        let listUser = [];
        listUser = await (userService === null || userService === void 0 ? void 0 : userService.getAll({
            params: {
                any: '*' + keyword + '*',
                query_fields: 'first_name,last_name,full_name,preferredName',
                fields: 'full_name,google_info.photo_url,ldap_uuid,ldap_uid,first_name,last_name',
            },
        }));
        return listUser;
    }
}
const userService = new UserService();
export { userService };
