<script lang="ts">
  import { onMount, setContext } from 'svelte';
  import Modal from 'svelte-simple-modal';
  import { _ } from 'svelte-i18n';
  import FlatTable from '../../Generics/Table/FlatTable.svelte';
  import ModalButton from '../../Generics/Modal/ModalButton.svelte';
  import CreateJob from './CreateJob.svelte';
  import { jobService } from '../../../services/job.service';
  import Pagination from '../../Generics/Paginator/Pagination.svelte';
  import { PaginationModel } from '../../../models/pagination.model';
  import type { BranchModel } from '../../../models/branch.model';
  import { params, goto } from '@roxi/routify';
  import { UserInfos } from '../../../stores/userInfo.store';
  import ListJobs from './ListJobs.svelte';
  import { Jobs } from '../../../stores/job.store';
  import { Branches } from '../../../stores/branches.store';
  import { branchesService } from '../../../services/branches.service';
  import { sortBranches } from '../Branches/branches.utils';
  import { isAdmin } from '../../Auth/rights.utils';
  import BranchFilter from '../Branches/BranchFilter.svelte';
  import { Countries } from '../../../stores/countries.store';
  import { countryService } from '../../../services/country.service';
  import { StatusList } from '../../../stores/status.store';
  import BottomPageLogo from '../../Navigation/BottomPageLogo.svelte';

  export let currentBranch: BranchModel = undefined;
  export let title: string;
  export let selectedBranches: BranchModel[] = [];
  export let createOption = true;

  type PerimeterTypes = 'local' | 'united';
  let perimeterFilter: PerimeterTypes | undefined;

  let timer;
  let statusFilter;
  let internalTranslationFilter = '';
  let externalTranslationFilter = '';
  let codeFilter = '';
  let pagination: PaginationModel = new PaginationModel();
  let current = 1;
  let loading = true;
  let openModal;

  $: slug = $params.slug;
  $: StatusList.set([
    { status: 'VALIDATED', translation: $_('validation.validated') },
    { status: 'HISTORIZED', translation: $_('validation.historized') },
    { status: 'UNAVAILABLE', translation: $_('validation.unavailable') },
    { status: 'DRAFT', translation: $_('validation.draft') },
  ]);

  $: hasJobsRights = isAdmin($UserInfos);

  setContext('jobs', Jobs);

  function retrieveJobs(evt?): void {
    loading = true;
    if (evt) {
      current = evt.detail;
    }
    let params = {
      params: {
        page: current - 1,
        internal_translation: internalTranslationFilter,
        external_translation: externalTranslationFilter,
        code: codeFilter,
        status: statusFilter?.status || '',
        perimeter: perimeterFilter || '',
      },
    };

    if (slug) {
      params.params['branch_id'] = slug;
    }
    jobService.getAllWithPagination(params).then(res => {
      Jobs.set(res[0]);
      pagination = res[1];
      loading = false;
      retrievesBranches();
    });
  }

  function retrieveBranchesLinkedToJobs() {
    $Jobs?.forEach(job => {
      job?.branches?.forEach(branchJob => {
        branchJob.labelTranslated = $Branches.find(
          branch => branch.id === branchJob.id
        )?.labelTranslated;
      });
    });
    Jobs.set($Jobs);
  }

  const handleBranchFilter = (branchToRedirect = undefined) => {
    branchToRedirect?.id ? $goto('/jobs/' + branchToRedirect.id) : $goto('/jobs/');
  };

  function handleStatusFilter(status) {
    statusFilter = $StatusList.find(s => s.status === status);
    void retrieveJobs();
  }

  function handlePerimeterFilter(perimeter?: PerimeterTypes) {
    perimeterFilter = perimeter;
    void retrieveJobs();
  }

  function onInputSearches() {
    if (
      internalTranslationFilter === '' &&
      externalTranslationFilter === '' &&
      codeFilter === ''
    ) {
      void retrieveJobs();
    } else {
      //Timeout to avoid multiple API calls
      clearTimeout(timer);
      timer = setTimeout(() => {
        void retrieveJobs();
      }, 500);
    }
  }

  function retrievesBranches() {
    if ($Branches.length === 0) {
      void branchesService.getAll().then((res: Array<BranchModel>) => {
        Branches.set(sortBranches(res));
        retrieveBranchesLinkedToJobs();
      });
    } else {
      retrieveBranchesLinkedToJobs();
    }
  }

  onMount(() => {
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
        void retrieveJobs();
      });
    } else {
      void retrieveJobs();
    }
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  {#if title != undefined}
    <h1 class="vtmn-font-semi-bold vtmn-text-5xl vtmn-text-center">{title}</h1>
  {/if}
  {#if hasJobsRights && createOption}
    <Modal>
      <ModalButton>
        <button
          class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
          on:click={openModal(CreateJob, { selectedBranches, isJob: true })}>
          {$_('job.create')}
        </button>
      </ModalButton>
    </Modal>
  {/if}
</div>
<FlatTable bind:loading col="7">
  <span slot="headers" class="vtmn-contents">
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12">
      <input
        type="search"
        placeholder={$_('job.table.code')}
        bind:value={codeFilter}
        on:input={onInputSearches}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12">
      <div class="dropdown vtmn-font-normal vtmn-w-full vtmn-inline-block vtmn-relative">
        <div
          class="dropbtn vtmn-flex vtmn-px-3 vtmn-py-3 vtmn-w-full vtmn-rounded vtmn-border-none vtmn-bg-white vtmn-text-xs">
          {#if perimeterFilter}
            <span class="vtmn-text-xs vtmn-my-auto">{perimeterFilter.toUpperCase()}</span>
            <button
              class="vtmn-text-black vtmn-m-auto vtmn-mr-0"
              on:click={() => handlePerimeterFilter()}>x</button>
          {:else}
            {$_('job.table.perimeter')}
          {/if}
        </div>
        <div
          class="dropdown-content vtmn-font-thin vtmn-absolute vtmn-bg-grey-light-4 vtmn-hidden">
          <span
            class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black"
            on:click={() => handlePerimeterFilter('united')}>
            {$_('perimeterType.united').toUpperCase()}
          </span>
          <span
            class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black"
            on:click={() => handlePerimeterFilter('local')}>
            {$_('perimeterType.local').toUpperCase()}
          </span>
        </div>
      </div>
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12">
      <input
        type="search"
        placeholder={$_('job.table.decath_name')}
        bind:value={internalTranslationFilter}
        on:input={onInputSearches}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12">
      <input
        type="search"
        placeholder={$_('job.table.outside_decath_name')}
        bind:value={externalTranslationFilter}
        on:input={onInputSearches}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-w-1/12">
      <div class="dropdown vtmn-font-normal vtmn-w-full vtmn-inline-block vtmn-relative">
        <div
          class="dropbtn vtmn-flex vtmn-px-3 vtmn-py-3 vtmn-w-full vtmn-rounded vtmn-border-none vtmn-bg-white vtmn-text-xs">
          {#if statusFilter}
            <span class="vtmn-text-xs vtmn-my-auto">{statusFilter?.translation}</span>
            <button
              class="vtmn-text-black vtmn-m-auto vtmn-mr-0"
              on:click={() => handleStatusFilter()}>x</button>
          {:else}
            {$_('global.validation')}
          {/if}
        </div>
        <div
          class="dropdown-content vtmn-font-thin vtmn-absolute vtmn-bg-grey-light-4 vtmn-hidden vtmn-text-xs">
          <span
            class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black"
            on:click={() => handleStatusFilter('VALIDATED')}>
            {$_('validation.validated').toUpperCase()}
          </span>
          <span
            class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black"
            on:click={() => handleStatusFilter('DRAFT')}>
            {$_('validation.draft').toUpperCase()}
          </span>
          <span
            class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black"
            on:click={() => handleStatusFilter('HISTORIZED')}>
            {$_('validation.historized').toUpperCase()}
          </span>
          <span
            class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black"
            on:click={() => handleStatusFilter('UNAVAILABLE')}>
            {$_('validation.unavailable').toUpperCase()}
          </span>
        </div>
      </div>
    </th>
    <BranchFilter bind:currentBranch {handleBranchFilter} size="1/12" />
    <th class="vtmn-px-3 vtmn-py-2 vtmn-font-thin vtmn-w-1/12 vtmn-text-xs"
      >{$_('responsibility.name.plural')}</th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12">{$_('global.actions')}</th>
  </span>

  <span slot="body" class="vtmn-contents">
    <ListJobs />
  </span>
</FlatTable>

<div class="vtmn-flex">
  <BottomPageLogo />
  <div class="vtmn-ml-auto vtmn-mr-2">
    <Pagination
      small
      bind:current
      num_items={pagination?.numItems}
      fromElement={pagination?.from}
      toElement={pagination?.to}
      per_page={pagination?.perPage}
      on:navigate={retrieveJobs} />
  </div>
</div>

<Modal>
  <ModalButton bind:openModal />
</Modal>
