import { RoleResponsibilityModel } from '../../../models/roleResponsibility.model';
import { roleResponsibilityService } from '../../../services/roleResponsibility.service';
import { RoleResponsibilities } from '../../../stores/roleResponsibility.store';
export async function deleteRoleResponsibility(deletedRoleResponsibility) {
    return roleResponsibilityService.delete(deletedRoleResponsibility.id).then(res => {
        if (!res)
            return;
        RoleResponsibilities.update((roleResponsibilities) => roleResponsibilities.filter((roleResponsibility) => roleResponsibility.id !== deletedRoleResponsibility.id));
        return true;
    });
}
export async function createRoleResponsibility(selectedResponsibility, currentRole) {
    const body = {
        responsibility_id: selectedResponsibility === null || selectedResponsibility === void 0 ? void 0 : selectedResponsibility.id,
        additional_role_id: currentRole === null || currentRole === void 0 ? void 0 : currentRole.id,
    };
    return roleResponsibilityService.create(body).then(res => {
        if (!res)
            return;
        const roleResponsibility = new RoleResponsibilityModel(res);
        roleResponsibility.responsibility = selectedResponsibility;
        roleResponsibility.role = currentRole;
        RoleResponsibilities.update((roleResponsibilities) => [
            ...roleResponsibilities,
            roleResponsibility,
        ]);
        return true;
    });
}
