<script lang="ts">
  import { createEventDispatcher, getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { JobResponsibilityModel } from '../../../models/jobResponsibility.model';
  import { JobResponsibility } from '../../../stores/jobResponsibility.store';
  import { jobResponsibilityService } from '../../../services/jobResponsibility.service';

  export let jobResponsibility: JobResponsibilityModel;
  const dispatch = createEventDispatcher();
  const { close } = getContext('simple-modal');

  function deleteJobResponsibility() {
    jobResponsibilityService.delete(jobResponsibility.id).then(res => {
      if (res) {
        $JobResponsibility[jobResponsibility.country.code] = $JobResponsibility[
          jobResponsibility.country.code
        ].filter(
          (jobResponsibilities: JobResponsibilityModel) =>
            jobResponsibilities.id !== jobResponsibility.id
        );
      }
    });
    close();
  }
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {jobResponsibility.responsibility.labelTranslated}
  </h2>
</div>

<h3 class="vtmn-p-4">
  {$_('jobs.unlink.subtitle')} "{jobResponsibility.job.internalTranslationTranslated}" ?
</h3>

<div class="vtmn-flex vtmn-flex-row vtmn-justify-evenly vtmn-mt-3 modal-content">
  <button
    class=" vtmn-w-2/5 vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
    on:click|preventDefault={close}>
    {$_('global.buttons.cancel')}
  </button>
  <button
    class="vtmn-w-2/5 vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small"
    on:click|preventDefault={deleteJobResponsibility}>
    {$_('global.buttons.validate')}
  </button>
</div>
