import { ResponsibilitySkillsKey, ResponsibilitySkillsNotKey, } from '../../../stores/responsibilitySkill.store';
import { get } from 'svelte/store';
import { responsibilitySkillService } from '../../../services/responsibilitySkill.service';
export function toogleSkillKey(link) {
    responsibilitySkillService
        .patch(link.id, {
        skill_key: !link.key,
    })
        .then(res => {
        if (res) {
            link.key = res.skill_key;
            link.order = res.skill_order;
            if (link.key) {
                ResponsibilitySkillsNotKey.update(responsibilitiesSkill => responsibilitiesSkill.filter((resp) => resp.id !== link.id));
                ResponsibilitySkillsKey.set([...get(ResponsibilitySkillsKey), link]);
            }
            else {
                ResponsibilitySkillsKey.update(responsibilitiesSkill => responsibilitiesSkill.filter((resp) => resp.id !== link.id));
                ResponsibilitySkillsNotKey.set([...get(ResponsibilitySkillsNotKey), link]);
            }
        }
    });
}
export function deleteResponsibilitySkill(deletedResponsibilitySkill) {
    void responsibilitySkillService.delete(deletedResponsibilitySkill.id).then(res => {
        if (res) {
            (deletedResponsibilitySkill.key
                ? ResponsibilitySkillsKey
                : ResponsibilitySkillsNotKey).update((responsibilitySkill) => responsibilitySkill.filter((responsibility) => responsibility.id !== deletedResponsibilitySkill.id));
        }
    });
}
