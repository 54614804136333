<script lang="ts">
  import { userService } from '../../../../services/user.service';
  import AutoComplete from 'simple-svelte-autocomplete';
  import { UserInfo } from '../../../../models/userInfo.model';
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import FlatTr from '../../../Generics/Table/FlatTr.svelte';
  import FlatTd from '../../../Generics/Table/FlatTd.svelte';
  import AccountPlus from 'svelte-material-icons/AccountPlus.svelte';
  import type { CountryModel } from '../../../../models/country.model';
  import { countryService } from '../../../../services/country.service';
  import { Countries } from '../../../../stores/countries.store';
  import { LeaderModel } from '../../../../models/leader.model';
  import { SkillLeaders } from '../../../../stores/leaders.store';
  import { skillLeaderService } from '../../../../services/skillLeader.service';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { SkillModel } from '../../../../models/skill.model';
  import Close from 'svelte-material-icons/Close.svelte';
  import {
    getContributorRightsForBranches,
    isAdmin,
    isBranchLeader,
    isBranchUnitedContributor,
  } from '../../../Auth/rights.utils';
  import { failure } from '../../../Toastr/toastr';
  import { get } from 'svelte/store';
  import UserImage from '../../User/UserImage.svelte';

  export let skill: SkillModel;

  let selectedUser: UserInfo;
  let selectedCountries: CountryModel[];
  let listCountries;
  let canCreateUnitedSkillLeader = false;

  function createLeader() {
    if (!canCreateUnitedSkillLeader && selectedCountries.length === 0) {
      failure(get(_)('api_call.forbidden'), 30000);
      return false;
    }
    let dataNewLeader = {
      skill_id: skill.id,
      member_id: selectedUser?.uuid,
      united: selectedCountries?.length > 0 ? false : true,
      countries: selectedCountries.map(country => country.code),
    };
    let newLeader: LeaderModel = new LeaderModel(dataNewLeader);
    newLeader.member = selectedUser;
    void skillLeaderService.create(dataNewLeader).then(res => {
      newLeader.id = res?.id;
      if (newLeader.id) {
        SkillLeaders.update(skillLeaders => [...skillLeaders, newLeader]);
      }
      selectedUser = new UserInfo();
      selectedCountries = [];
    });
  }

  function adaptCountries() {
    if (
      isAdmin($UserInfos) ||
      isBranchLeader($UserInfos, [skill.branch]) ||
      isBranchUnitedContributor($UserInfos, [skill.branch])
    ) {
      canCreateUnitedSkillLeader = true;
      listCountries = $Countries;
    } else {
      // If the user can't create a united skill leader, he can create a local skill leader only for a limited selection of countries
      const branchesContributor = getContributorRightsForBranches($UserInfos, [
        skill.branch,
      ]);
      const local_rights = branchesContributor.filter(bc => bc.countries?.length > 0);
      listCountries = $Countries.filter(country =>
        local_rights.find(r => r.countries.find(c => c === country.code))
      );
    }
  }

  onMount(() => {
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
        void adaptCountries();
      });
    } else {
      void adaptCountries();
    }
  });
</script>

<FlatTr>
  <FlatTd>
    <AutoComplete
      searchFunction={userService.searchUser}
      bind:selectedItem={selectedUser}
      labelFieldName="fullName"
      maxItemsToShowInList="10"
      placeholder={$_('branch_contributor.table.name')}
      delay="200"
      localFiltering="false"
      inputId="User">
      <div
        slot="item"
        let:item
        class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start">
        <UserImage uid={item.uid} />
        <div class="vtmn-py-4">{item.fullName}</div>
      </div>
    </AutoComplete>
  </FlatTd>
  <FlatTd>
    <AutoComplete
      multiple="true"
      items={listCountries}
      bind:selectedItem={selectedCountries}
      labelFieldName="name"
      maxItemsToShowInList="1000"
      placeholder={$_('global.countries')}
      inputId="Country">
      <div let:item slot="item">
        <span class="countryFlag">{item.flag}</span>{' ' + item.name}
      </div>
      <div
        slot="tag"
        class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start vtmn-bg-grey-light-6 vtmn-rounded-full"
        let:label
        let:item
        let:unselectItem>
        <span class="vtmn-p-1"
          ><span class="countryFlag">{item.flag}</span>{' ' + item.name}</span>
        <button
          class="vtmn-p-1 vtmn-border-none"
          on:click|preventDefault={unselectItem(item)}>
          <Close color="#404040" />
        </button>
      </div>
    </AutoComplete>
  </FlatTd>

  <FlatTd>
    <div class="vtmn-flex">
      <button
        class="vtmn-btn vtmn-btn_variant--ghost vtmn-m-auto"
        on:click={() => {
          createLeader();
        }}
        disabled={!selectedUser?.uuid}>
        <AccountPlus color="#404040" size="1.5em" />
      </button>
    </div>
  </FlatTd>
</FlatTr>
