import { BranchTranslationModel } from './branchTranslation.model';
import { UserInfo } from './userInfo.model';
export class BranchModel {
    constructor(data = {}) {
        var _a, _b, _c, _d, _e, _f;
        this.labelTranslated = '';
        this.id = data.id;
        this.additionalRolesCount = (_a = data.additional_role_count) !== null && _a !== void 0 ? _a : 0;
        this.contributorsCount = (_b = data.branch_contributor_count) !== null && _b !== void 0 ? _b : 0;
        this.skillsCount = (_c = data.skill_count) !== null && _c !== void 0 ? _c : 0;
        this.jobsCount = (_d = data.job_count) !== null && _d !== void 0 ? _d : 0;
        this.responsibleUser = new UserInfo();
        this.responsibleUser.uuid = data.member_id;
        this.shortName = data.short_name;
        this.branchTranslations = [];
        (_e = data.branch_translations) === null || _e === void 0 ? void 0 : _e.forEach((translation) => {
            var _a;
            (_a = this.branchTranslations) === null || _a === void 0 ? void 0 : _a.push(new BranchTranslationModel(translation));
        });
        if (data.branch_translated) {
            this.labelTranslated = (_f = data.branch_translated) === null || _f === void 0 ? void 0 : _f.label;
        }
    }
}
