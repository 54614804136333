<style>
  .active {
    border-bottom: 5px solid white;
    margin-bottom: -5px;
  }
</style>

<!--Component created for navigation-->
<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { isActive, url } from '@roxi/routify';
  import AccountQuestion from 'svelte-material-icons/AccountQuestion.svelte';
  import { UserInfos } from '../../stores/userInfo.store';
  import { isAdmin } from '../Auth/rights.utils';

  export let links;
  export let topNavigation = false;
</script>

<ul class="vtmn-flex vtmn-items-center vtmn-text-center vtmn-mr-0 vtmn-ml-auto">
  {#each links as [path, name]}
    <div class="vtmn-flex vtmn-h-full vtmn-mx-3" class:active={$isActive(path)}>
      <li class="vtmn-m-auto vtmn-text-center">
        <a class="vtmn-m-auto" href={$url(path)}>{$_(name)}</a>
      </li>
    </div>
  {/each}
  {#if topNavigation}
    <div class="vtmn-flex vtmn-h-full vtmn-mx-3">
      <li class="vtmn-m-auto vtmn-text-center">
        <div
          class="dropdown vtmn-font-normal vtmn-w-full vtmn-inline-block vtmn-relative">
          <div
            class="dropbtn vtmn-flex vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm vtmn-rounded vtmn-border-none vtmn-bg-brand-digital vtmn-text-white">
            <AccountQuestion />
          </div>
          <div
            class="dropdown-content vtmn-hidden vtmn-absolute vtmn-bg-grey-light-4 vtmn-z-50 vtmn-w-auto">
            <a
              target="_blank"
              class="hover:vtmn-no-underline"
              href={$url(
                'https://docs.google.com/presentation/d/1r-GJ1mGqe1UZnN0UqH-EacCMY6KwALHjhJrVVPJScHU/edit?usp=sharing'
              )}>
              <span
                class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black vtmn-text-xs">
                {$_('navigation.tutorial')}
              </span>
            </a>
            <a
              target="_blank"
              class="hover:vtmn-no-underline"
              href={$url('https://support.decathlon.net/saw/ess/offeringPage/51751252')}>
              <span
                class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black vtmn-text-xs">
                {$_('navigation.support')}
              </span>
            </a>
            <a href={$url('/me/IA')} class="hover:vtmn-no-underline">
              <span
                class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black vtmn-text-xs">
                {$_('rights.title')}
              </span>
            </a>
            <a href={$url('/export')} class="hover:vtmn-no-underline">
              <span
                class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black vtmn-text-xs">
                {$_('export.title')}
              </span>
            </a>
            {#if isAdmin($UserInfos)}
              <a href={$url('/user_rights')} class="hover:vtmn-no-underline">
                <span
                  class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black vtmn-text-xs">
                  {$_('rights.finder')}
                </span>
              </a>
            {/if}
          </div>
        </div>
      </li>
    </div>
  {/if}
</ul>
