<!-- This component is used to display a table. It takes in parameters
 - headers: an array like this one :
    [{
        size: the size of the column in fraction (e.g : "1/4")
        title: the name of the column (e.g : "Name")
    }]
 - loading: a boolean. When it equals True, a loader is shown. -->
<script lang="ts">
  import { _ } from 'svelte-i18n';
  import TrLoader from '../Loader/TrLoader.svelte';

  export let tableHeaders = [];
  export let col = 0;
  export let row = 10;
  export let loading = false;
</script>

<div class="vtmn-mt-6">
  <table class="vtmn-table-auto vtmn-border-collapse vtmn-w-full">
    <thead>
      <tr
        class="vtmn-rounded-lg vtmn-text-sm vtmn-font-thin vtmn-text-grey-dark-3 vtmn-bg-grey-light-4 vtmn-text-left">
        <slot name="headers">
          {#each tableHeaders as header}
            <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-{header.size}">
              {#if header?.title !== ''}
                {$_(header.title)}
              {/if}
            </th>
          {/each}
        </slot>
      </tr>
    </thead>

    <tbody class="vtmn-text-sm vtmn-font-normal vtmn-text-gray-700">
      {#if !loading}
        <!-- The content is shown only if loading = True -->
        <slot name="body" />
      {:else}
        <TrLoader col={tableHeaders.length > 0 ? tableHeaders.length : col} n={row} />
      {/if}
    </tbody>
  </table>
</div>
