<script lang="ts">
  import AutoComplete from 'simple-svelte-autocomplete';
  import Close from 'svelte-material-icons/Close.svelte';
  import CloseCircle from 'svelte-material-icons/CloseCircle.svelte';
  import CheckboxMarkedCircle from 'svelte-material-icons/CheckboxMarkedCircle.svelte';
  import { _ } from 'svelte-i18n';
  import { CountryModel } from '../../../models/country.model';
  import { Countries } from '../../../stores/countries.store';

  export let selectedCountries: CountryModel[];
  export let cancelEdit;
  export let validateEdit;
</script>

<AutoComplete
  multiple="true"
  items={$Countries}
  bind:selectedItem={selectedCountries}
  labelFieldName="name"
  maxItemsToShowInList="1000"
  placeholder={$_('global.countries')}
  inputId="Country">
  <div let:item slot="item">
    <span class="countryFlag">{item.flag}</span>{' ' + item.name}
  </div>
  <div
    slot="tag"
    class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start vtmn-bg-grey-light-6 vtmn-rounded-full"
    let:label
    let:item
    let:unselectItem>
    <span class="vtmn-p-1"
      ><span class="countryFlag">{item.flag}</span>{' ' + item.name}</span>
    <button
      class="vtmn-p-1 vtmn-border-none"
      on:click|preventDefault={unselectItem(item)}>
      <Close color="#404040" />
    </button>
  </div>
</AutoComplete>

<div class="vtmn-flex">
  <button
    class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-rounded-full"
    on:click={cancelEdit}>
    <CloseCircle color="red" size="1.5em" />
  </button>
  <button
    class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-rounded-full"
    on:click={validateEdit}>
    <CheckboxMarkedCircle color="green" size="1.5em" />
  </button>
</div>
