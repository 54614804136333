export class PaginationModel {
    constructor(data = {}) {
        var _a, _b, _c, _d, _e, _f;
        this.current = (_a = data.current_page) !== null && _a !== void 0 ? _a : data.number;
        this.numItems = (_c = (_b = data.total) !== null && _b !== void 0 ? _b : data['hydra:totalItems']) !== null && _c !== void 0 ? _c : data.total_elements;
        this.perPage = (_d = data.per_page) !== null && _d !== void 0 ? _d : data.size;
        this.from = (_e = data.from) !== null && _e !== void 0 ? _e : data.size * data.number;
        this.to = (_f = data.to) !== null && _f !== void 0 ? _f : data.size * data.number + data.number_of_elements;
    }
}
