<script>
  import { scale, fade } from 'svelte/transition';
  import { BaseTransition } from '@roxi/routify/decorators';

  const configs = [
    {
      condition: ({ routes }) => routes[0].shortPath == '',
      transition: scale,
      inParams: { start: 1.0 },
      outParams: { start: 0.9 },
    },
    {
      condition: ({ routes }) => routes[1].shortPath == '',
      transition: scale,
      inParams: { start: 0.9 },
      outParams: { start: 1.0 },
    },
    {
      condition: meta => true,
      transition: fade,
      inParams: { duration: 300 },
      outParams: { duration: 300 },
    },
  ];
</script>

<BaseTransition {configs}>
  <slot />
</BaseTransition>
