<script lang="ts">
  import { goto, params } from '@roxi/routify';
  import Modal from 'svelte-simple-modal';
  import { onMount, setContext } from 'svelte';
  import FlatTable from '../../../../components/Generics/Table/FlatTable.svelte';
  import FlatTr from '../../../../components/Generics/Table/FlatTr.svelte';
  import FlatTd from '../../../../components/Generics/Table/FlatTd.svelte';
  import ModalButton from '../../../../components/Generics/Modal/ModalButton.svelte';
  import CreateTranslation from '../../../../components/Contents/Jobs/Translations/CreateTranslation.svelte';
  import DeleteTranslation from '../../../../components/Contents/Jobs/Translations/DeleteTranslation.svelte';
  import { _ } from 'svelte-i18n';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import { jobTranslationsService } from '../../../../services/jobTranslations.service';
  import { JobModel } from '../../../../models/job.model';
  import { jobService } from '../../../../services/job.service';
  import { writable } from 'svelte/store';
  import * as localeCodes from 'locale-codes';
  import { JobTranslationModel } from '../../../../models/jobTranslation.model';
  import { sortTranslations, updateLanguageList } from '../../../../components/utils';
  import { allLanguagesList } from '../../../../stores/languages.store';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { tooltip } from '../../../../components/Generics/Tooltip/tooltip';
  import {
    isAdmin,
    isBranchUnitedContributor,
    isBranchLeader,
    isTranslator,
    isBranchContributor,
  } from '../../../../components/Auth/rights.utils';
  import { getFlagFromLanguage } from '../../../../components/Contents/Languages/languages.utils';

  let CurrentJob = writable(new JobModel());
  setContext('CurrentJob', CurrentJob);
  let selectedLanguage;
  let allLanguages: Array<localeCodes.ILocale> = [];
  let languagesList: Array<localeCodes.ILocale> = [];
  let jobTranslationsDisplayed: Array<JobTranslationModel> = []; //The array with translations filtered depending on filters
  let internalTranslationSearch = '';
  let externalTranslationSearch = '';
  let localeSearch = '';
  let loading = false;
  let openModal;
  $: slug = $params.slug;
  $: hasJobsRights =
    isAdmin($UserInfos) ||
    isTranslator($UserInfos) ||
    isBranchLeader($UserInfos, $CurrentJob.branches) ||
    isBranchContributor($UserInfos, $CurrentJob.branches);

  function findJobName(): string {
    return $CurrentJob.shortName;
  }

  function getJobTranslations(): void {
    void jobTranslationsService.getAll({ params: { id: slug } }).then(translations => {
      $CurrentJob.translations = translations;
      languagesList = updateLanguageList(allLanguages, $CurrentJob.translations);
      loading = false;
    });
  }

  // This function is called everytime the store is updated (new translations etc...) or when a user makes a search
  function updateTranslationsDisplay() {
    if (
      internalTranslationSearch === '' &&
      externalTranslationSearch === '' &&
      localeSearch === '' &&
      $CurrentJob.translations.length > 0
    ) {
      // If the search input is empty : we display all the branches
      jobTranslationsDisplayed = $CurrentJob.translations;
    } else {
      // Else, we filter the array depending on the search
      jobTranslationsDisplayed = $CurrentJob.translations.filter(
        (jobTranslated: JobTranslationModel) => {
          return (
            jobTranslated.internal_translation
              .toLowerCase()
              .includes(internalTranslationSearch.toLowerCase()) &&
            jobTranslated.external_translation
              .toLowerCase()
              .includes(externalTranslationSearch.toLowerCase()) &&
            allLanguages
              ?.find(country => country.tag === jobTranslated?.language_id)
              ?.name.toLowerCase()
              .includes(localeSearch.toLowerCase())
          );
        }
      );
    }
    sortTranslations(jobTranslationsDisplayed);
  }

  function getJob() {
    void jobService.get(slug).then(job => {
      if (job?.id) {
        CurrentJob.set(job);
        void getJobTranslations();
      } else {
        $goto('/error/404');
      }
    });
  }

  onMount(() => {
    loading = true;
    allLanguages = $allLanguagesList;
    void getJob();
    CurrentJob.subscribe(() => {
      updateTranslationsDisplay(); //Update translation display everytime the job changes
    });
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-font-semi-bold vtmn-text-5xl vtmn-text-center">
    {$_('job.name.single')} : {$CurrentJob?.internalTranslationTranslated ??
      $CurrentJob?.shortName ??
      ''}
  </h1>
  {#if hasJobsRights}
    <Modal>
      <ModalButton>
        <button
          class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
          on:click={openModal(
            CreateTranslation,
            { jobName: findJobName(), languagesList },
            {}
          )}>
          {$_('translations.create')}
        </button>
      </ModalButton>
    </Modal>
  {/if}
</div>
<h2 class="vtmn-mb-2 vtmn-text-xl vtmn-text-white">
  {$_('global.translations')} :
</h2>

<FlatTable bind:loading col="4">
  <span slot="headers" class="vtmn-contents">
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('branches.translations.table.language')}
        bind:value={localeSearch}
        on:input={updateTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('job.table.decath_name')}
        bind:value={internalTranslationSearch}
        on:input={updateTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('job.table.outside_decath_name')}
        bind:value={externalTranslationSearch}
        on:input={updateTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">{$_('global.actions')}</th>
  </span>
  <span slot="body" class="vtmn-contents">
    {#each jobTranslationsDisplayed as translation}
      <FlatTr>
        <FlatTd>
          {#if allLanguages.length > 0}
            <span class="countryFlag"
              >{getFlagFromLanguage(translation?.language_id)}</span>
            {allLanguages?.find(country => country.tag === translation?.language_id)
              ?.name}
            ({allLanguages?.find(country => country.tag === translation?.language_id)
              .location || 'Standard'})
          {/if}
        </FlatTd>
        <FlatTd>{translation.internal_translation}</FlatTd>
        <FlatTd>{translation.external_translation}</FlatTd>
        {#if hasJobsRights}
          <FlatTd>
            <div
              class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
              <Modal>
                <ModalButton>
                  <button
                    class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                    use:tooltip={{ text: $_('global.buttons.modify') }}
                    on:click={openModal(
                      CreateTranslation,
                      {
                        jobName: findJobName(),
                        translationToModify: translation,
                      },
                      {}
                    )}>
                    <Pencil color="#404040" size="1.5em" />
                  </button>
                </ModalButton>
              </Modal>
              {#if translation?.language_id?.toUpperCase() !== 'EN-001'}
                <!--Remove the english world translation is forbidden-->
                <Modal>
                  <ModalButton>
                    <button
                      class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                      use:tooltip={{ text: $_('branches.deletion.button') }}
                      on:click={openModal(
                        DeleteTranslation,
                        { translationToDelete: translation },
                        {}
                      )}>
                      <Delete color="#404040" size="1.5em" />
                    </button>
                  </ModalButton>
                </Modal>
              {/if}
            </div>
          </FlatTd>
        {/if}
      </FlatTr>
    {/each}
  </span>
</FlatTable>
<!--
  Had to instantiate it once at the end of the component to bind it to its functions
  Wanted to put it in a service but a slot is needed. Better solution may exist.
-->
<Modal>
  <ModalButton bind:openModal />
</Modal>
