<script lang="ts">
  import { UserInfos } from '../../../stores/userInfo.store';
  import { onMount } from 'svelte';
  import { rightsUserService } from '../../../services/rightsUser.service';
  import { RightsUserModel } from '../../../models/rightsUserModel';
  import FlatTable from '../../../components/Generics/Table/FlatTable.svelte';
  import { _ } from 'svelte-i18n';
  import { skillsService } from '../../../services/skills.service';
  import { SkillModel } from '../../../models/skill.model';
  import RawSkill from '../../../components/Contents/Skills/RawSkill.svelte';

  let skills: SkillModel[] = [];
  let loading = true;
  let tableSkillsHeaders = [
    { size: '1/12', title: 'global.type' },
    { size: '3/12', title: 'skill.table.name' },
    { size: '3/12', title: 'skill.table.description' },
    { size: '2/12', title: 'branches.name.single' },
    { size: '1/12', title: 'skill.table.responsibilities' },
    { size: '2/12', title: 'global.actions' },
  ];

  function retrieveSkillsFromRights() {
    for (const right: RightsUserModel of $UserInfos.rights) {
      if (right?.skill_id) {
        skillsService.get(right?.skill_id)?.then(res => {
          if (res?.id) {
            skills = [...skills, res];
          }
        });
      }
    }
    loading = false;
  }

  onMount(() => {
    rightsUserService.getAll()?.then(res => {
      // We check user rights again even if we already did it at the start of the app, because rights can change
      if (res.length > 0 && res[0] instanceof RightsUserModel) {
        $UserInfos.rights = res;
        retrieveSkillsFromRights();
      }
    });
  });
</script>

{#if $UserInfos}
  <h1 class="vtmn-text-xl vtmn-ml-0 vtmn-my-auto vtmn-mr-auto">
    {$_('rights.skills')}
  </h1>
  <FlatTable tableHeaders={tableSkillsHeaders} bind:loading row={3}>
    <span slot="body" class="vtmn-contents">
      {#if skills.length > 0}
        {#each skills as skill}
          <RawSkill bind:skill />
        {/each}
      {:else}
        <td colspan="6" class="vtmn-p-4 vtmn-text-center"> {$_('search.none')} </td>
      {/if}
    </span>
  </FlatTable>
{/if}
