<script lang="ts">
  import { getContext } from 'svelte';
  import {
    login,
    OIDC_CONTEXT_CLIENT_PROMISE,
    OIDC_CONTEXT_REDIRECT_URI,
  } from '@dopry/svelte-oidc/src/components/OidcContext.svelte';
  import { authError, userInfo } from '@dopry/svelte-oidc';
  import { UserInfo } from '../../models/userInfo.model';
  import { UserInfos } from '../../stores/userInfo.store';
  import { rightsUserService } from '../../services/rightsUser.service';
  import { RightsUserModel } from '../../models/rightsUserModel';
  import { _ } from 'svelte-i18n';

  const oidcPromise = getContext(OIDC_CONTEXT_CLIENT_PROMISE);
  export const callback_url = getContext(OIDC_CONTEXT_REDIRECT_URI);
  export const preserveRoute = undefined;
  const errorLogin = [
    'login_required',
    'Authentication is required.',
    'invalid_grant',
    'Frame window timed out',
  ];

  function subscribeLogin() {
    authError.subscribe(value => {
      if (errorLogin.includes(value)) {
        log();
      }
    });
  }

  function subscribeUser() {
    userInfo.subscribe(value => {
      if (value.uid !== undefined || value.uuid !== undefined) {
        let user = new UserInfo(value);
        rightsUserService.getAll()?.then(res => {
          if (res.length > 0 && res[0] instanceof RightsUserModel) {
            user.rights = res;
            UserInfos.set(user);
          }
        });
        UserInfos.set(user);
      }
    });
  }

  function log() {
    login(oidcPromise, preserveRoute, callback_url);
  }

  subscribeLogin();
  subscribeUser();
</script>

{#if $UserInfos.uuid === undefined}
  <div class="vtmn-flex vtmn-flex-wrap vtmn-h-screen">
    <div class="vtmn-loader vtmn-loader_size--medium vtmn-text-white vtmn-m-auto">
      <span class="vtmn-sr-only">{$_('login.loading')}</span>
    </div>
  </div>
{/if}
