<script lang="ts">
  import { setContext } from 'svelte';
  import Translate from 'svelte-material-icons/Translate.svelte';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import ChevronRight from 'svelte-material-icons/ChevronRight.svelte';
  import Modal from 'svelte-simple-modal';
  import { _ } from 'svelte-i18n';
  import FlatTd from '../../Generics/Table/FlatTd.svelte';
  import FlatTr from '../../Generics/Table/FlatTr.svelte';
  import ModalButton from '../../Generics/Modal/ModalButton.svelte';
  import { PaginationModel } from '../../../models/pagination.model';
  import Eye from 'svelte-material-icons/Eye.svelte';
  import { params, url } from '@roxi/routify';
  import { UserInfos } from '../../../stores/userInfo.store';
  import { Roles } from '../../../stores/roles.store';
  import CreateRole from './CreateRole.svelte';
  import DeleteRole from './DeleteRole.svelte';
  import { tooltip } from '../../Generics/Tooltip/tooltip';
  import {
    isAdmin,
    isBranchLeader,
    isBranchUnitedContributor,
  } from '../../Auth/rights.utils';
  import { RoleModel } from '../../../models/role.model';

  $: slug = $params.slug;
  let pagination: PaginationModel = new PaginationModel();
  let loading = true;
  let openModal;
  const colorIcon = '#404040';
  const sizeIcon = '1.5em';
  $: hasRolesRights = isAdmin($UserInfos);
  setContext('roles', Roles);

  function canEditRole(Role: RoleModel): boolean {
    return (
      isAdmin($UserInfos) ||
      isBranchLeader($UserInfos, Role.branches) ||
      isBranchUnitedContributor($UserInfos, Role.branches)
    );
  }
</script>

{#if $Roles?.length > 0}
  {#each $Roles as role}
    {#if role}
      <FlatTr>
        <FlatTd>{role.nameTranslated}</FlatTd>
        <FlatTd>
          <div class="vtmn-flex">
            <div class="vtmn-mr-1 vtmn-ml-auto vtmn-my-auto">{role.branches.length}</div>
            <div class="has-tooltip vtmn-ml-1 vtmn-mr-auto vtmn-my-auto">
              <Eye color="#404040" size="1.5em" />
              <span class="tooltip vtmn-rounded vtmn-shadow-lg vtmn-p-1 vtmn-mt-2">
                {#each role.branches as branche}
                  {branche?.labelTranslated ?? ''}<br />
                {/each}
              </span>
            </div>
          </div>
        </FlatTd>
        <FlatTd
          ><div class="vtmn-w-full vtmn-text-center">
            {role?.responsibilityCount}
          </div></FlatTd>
        <FlatTd>
          <div
            class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
            {#if canEditRole(role)}
              <button
                class="vtmn-m-auto vtmn-cursor-pointer vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                use:tooltip={{ text: $_('global.buttons.modify') }}
                on:click={openModal(CreateRole, {
                  roleToEdit: role,
                })}>
                <Pencil color={colorIcon} size={sizeIcon} />
              </button>
            {/if}
            <a
              class="vtmn-m-auto vtmn-relative"
              use:tooltip={{ text: $_('global.translations') }}
              href={$url('/roles/' + role.id + '/translations')}>
              <Translate color={colorIcon} size={sizeIcon} />
            </a>
            {#if hasRolesRights && !role?.responsibilityCount}
              <Modal>
                <ModalButton>
                  <button
                    class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                    use:tooltip={{ text: $_('branches.deletion.button') }}
                    on:click={openModal(DeleteRole, { deletedRole: role })}>
                    <Delete size="1.5em" />
                  </button>
                </ModalButton>
              </Modal>
            {/if}
            <a
              class="vtmn-m-auto vtmn-relative"
              use:tooltip={{ text: $_('responsibility.name.plural') }}
              href={$url('/responsibilities/' + role.id + '/roles')}>
              <ChevronRight color={colorIcon} size={sizeIcon} />
            </a>
          </div>
        </FlatTd>
      </FlatTr>
    {/if}
  {/each}
{:else}
  <td colspan="7" class="vtmn-p-4 vtmn-text-center">
    {$_('search.none')}
  </td>
{/if}
<Modal>
  <ModalButton bind:openModal />
</Modal>
