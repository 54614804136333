<script lang="ts">
  import AutoComplete from 'simple-svelte-autocomplete';
  import type { UserInfo } from '../../../models/userInfo.model';
  import { maxLength, required, useForm, validators } from 'svelte-use-form';
  import { userService } from '../../../services/user.service';
  import { getContext, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { BranchModel } from '../../../models/branch.model';
  import { branchesService } from '../../../services/branches.service';
  import { Branches } from '../../../stores/branches.store';
  import { branchTranslationsService } from '../../../services/branchTranslations.service';
  import type { BranchTranslationModel } from '../../../models/branchTranslation.model';
  import { sortBranches } from './branches.utils';
  import UserImage from '../User/UserImage.svelte';

  const { close } = getContext('simple-modal');

  let selectedUser: UserInfo;
  let branchCode: string;
  let branchTranslation: string;
  export let branchIndex = -1;
  const branchForm = useForm();

  function createBranch() {
    void branchesService
      .create({
        short_name: branchCode,
        branch_translations: [{ language_id: 'en-001', label: branchTranslation }],
        member_id: selectedUser?.uuid,
      })
      .then((res: BranchModel) => {
        if (res) {
          let branchCreated = new BranchModel(res);
          branchCreated.responsibleUser = selectedUser;
          void branchTranslationsService
            .getAll({ params: { branch_id: branchCreated.id } })
            .then((translations: Array<BranchTranslationModel>) => {
              branchCreated.branchTranslations = translations;
              $Branches = [...$Branches, branchCreated];
              Branches.set(sortBranches($Branches));
            });
          close();
        }
      });
  }

  function modifyBranch() {
    const body = { member_id: selectedUser?.uuid };
    if (branchCode != $Branches[branchIndex].shortName) {
      body['short_name'] = branchCode;
    }
    void branchesService
      .patch($Branches[branchIndex].id, body)
      .then((res: BranchTranslationModel) => {
        if (res?.id) {
          let modifiedBranch = $Branches[branchIndex];
          modifiedBranch.shortName = branchCode;
          modifiedBranch.responsibleUser = selectedUser;
          $Branches[$Branches.findIndex(branch => branch.id === modifiedBranch.id)] =
            modifiedBranch;
          Branches.set(sortBranches($Branches));
          close();
        }
      });
  }

  function actionBranch() {
    if (branchIndex === -1) {
      void createBranch();
    } else {
      void modifyBranch();
    }
  }

  onMount(() => {
    if (branchIndex !== -1) {
      selectedUser = $Branches[branchIndex].responsibleUser?.uuid
        ? $Branches[branchIndex].responsibleUser
        : undefined;
      branchCode = $Branches[branchIndex].shortName;
    }
  });
</script>

<form use:branchForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {#if branchIndex === -1}
        {$_('branches.modification.create')}
      {:else}
        {$_('branches.modification.modify')}
      {/if}
    </h2>
  </div>

  <div class="vtmn-flex vtmn-flex-col vtmn-justify-around vtmn-h-4/6 modal-content">
    <div class="input-wrapper">
      <label for="branchCode" class="control-label"
        >{$_('branches.modification.code')}</label>
      <input
        bind:value={branchCode}
        class="vtmn-block vtmn-w-full vtmn-sm:text-sm form-control"
        id="branchCode"
        name="branchCode"
        placeholder={$_('branches.modification.code_placeholder')}
        type="text"
        use:validators={[required, maxLength(12)]} />
    </div>

    <div
      class="vtmn-flex vtmn-justify-end {branchCode?.length > 12
        ? 'control-label-danger'
        : 'control-label'}">
      {branchCode?.length || 0}/12
    </div>
    <div class="input-wrapper">
      <label for="branchContributor" class="control-label"
        >{$_('skill.modification.leader')}</label>
      <AutoComplete
        bind:selectedItem={selectedUser}
        delay="200"
        labelFieldName="fullName"
        localFiltering="false"
        maxItemsToShowInList="10"
        placeholder={$_('branches.modification.responsible_placeholder')}
        searchFunction={userService.searchUser}
        inputId="branchContributor"
        inputClassName="form-control">
        <div
          class="vtmn-flex vtmn-flex-row vtmn-text-center vtmn-justify-start"
          let:item
          slot="item">
          <UserImage uid={item.uid} />
          <div class="vtmn-flex vtmn-items-center vtmn-text-center">
            {item.fullName}
          </div>
        </div>
      </AutoComplete>
    </div>

    {#if branchIndex === -1}
      <div class="input-wrapper">
        <label for="branchTranslation" class="control-label"
          >{$_('branches.modification.translation')}</label>
        <input
          bind:value={branchTranslation}
          class="vtmn-block vtmn-w-full vtmn-sm:text-sm form-control"
          id="branchTranslation"
          name="branchTranslation"
          placeholder={$_('branches.modification.translation_placeholder')}
          type="text"
          use:validators={[required]} />
      </div>
    {/if}

    <div class="vtmn-p-4">
      <button
        class="vtmn-float-right vtmn-btn vtmn-btn_size--small
        vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
        disabled={!$branchForm.valid}
        on:click|preventDefault={actionBranch}>
        {$_('global.buttons.validate')}
      </button>
    </div>
  </div>
</form>
