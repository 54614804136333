import ApiService from './api.service';
import { SkillSportModel } from '../models/skillSport.model';
class SkillSportService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/skill_sport';
        this.entity = SkillSportModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const skillSportService = new SkillSportService();
