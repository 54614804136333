// Code from https://svelte.dev/repl/780f2bfdbf3449ecae943a312cc0b615?version=3.35.0 adapted for Vitamin
export const tooltip = (node, param) => {
    var _a;
    let alreadyLeft;
    const { text } = param;
    let timer;
    let updatedText;
    const htmlContent = () => text || updatedText;
    const capture = !!((_a = htmlContent()) === null || _a === void 0 ? void 0 : _a.tagName);
    function handleDebounceEnter(event) {
        var _a;
        event.stopPropagation();
        alreadyLeft = false;
        (_a = event.target.querySelector('.js-tooltip')) === null || _a === void 0 ? void 0 : _a.remove();
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (!alreadyLeft)
                render(event);
        }, 100);
    }
    const render = event => {
        if (event.type === 'mouseenter' && (!updatedText || (updatedText === null || updatedText === void 0 ? void 0 : updatedText.tagName))) {
            setTimeout(() => {
                const content = htmlContent();
                const span = document.createElement('span');
                span.classList.add('js-tooltip', 'vtmn-invisible', 'vtmn-scale-null', 'vtmn-origin-top-left', 'vtmn-animated', 'vtmn-delay-200', 'vtmn-pointer-events-auto', 'vtmn-tooltip', 'vtmn-flex', 'vtmn-text-xs', 'vtmn-absolute', 'vtmn-z-10', 'vtmn-top-full');
                span.innerHTML = /*html*/ `
          <span
            style="width: max-content; color: var(--vtmn-color_brand-digital); background-color: white;"
            class="js-tooltip-content vtmn-flex vtmn-shadow-md vtmn-rounded-xl vtmn-origin-top vtmn-z-10 
             vtmn-py-2 vtmn-px-2">
            ${!(content === null || content === void 0 ? void 0 : content.tagName) ? updatedText || text : ''}
          </span>
        `;
                event.target.appendChild(span);
                if (content === null || content === void 0 ? void 0 : content.tagName) {
                    content.classList.remove('vtmn-hidden');
                    content.classList.add('vtmn-flex');
                    event.target.querySelector('.js-tooltip-content').appendChild(content);
                }
                setTimeout(() => {
                    var _a, _b;
                    (_a = event.target
                        .querySelector('.js-tooltip')) === null || _a === void 0 ? void 0 : _a.classList.remove('vtmn-invisible', 'vtmn-scale-null');
                    (_b = event.target
                        .querySelector('.js-tooltip')) === null || _b === void 0 ? void 0 : _b.classList.add('vtmn-visible', 'vtmn-scale-full');
                }, 100);
            });
        }
    };
    const handleLeave = event => {
        var _a, _b;
        (_a = event.target.querySelector('.js-tooltip')) === null || _a === void 0 ? void 0 : _a.classList.add('vtmn-scale-null');
        (_b = event.target
            .querySelector('.js-tooltip')) === null || _b === void 0 ? void 0 : _b.classList.remove('vtmn-delay-200', 'vtmn-visible', 'vtmn-scale-full');
        alreadyLeft = true;
        setTimeout(() => {
            var _a;
            (_a = event.target.querySelector('.js-tooltip')) === null || _a === void 0 ? void 0 : _a.remove();
        }, 100);
    };
    // bind custom event handler to element's keyup event
    node.addEventListener('mouseenter', handleDebounceEnter, capture);
    node.addEventListener('mouseleave', handleLeave, capture);
    // when element is unmounted from the DOM remove the event listener
    return {
        update: ({ text_returned }) => {
            updatedText = text_returned;
            const tooltip_returned = node.querySelector('.js-tooltip-content');
            if (tooltip_returned && (tooltip_returned === null || tooltip_returned === void 0 ? void 0 : tooltip_returned.innerText) !== text_returned) {
                tooltip_returned.innerText = text_returned;
            }
        },
        destroy() {
            node.removeEventListener('mouseover', handleDebounceEnter, capture);
            node.removeEventListener('mouseout', handleLeave, capture);
        },
    };
};
