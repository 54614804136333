import ApiService from './api.service';
import { SportModel } from '../models/sport.model';
export class SportService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = 'sport_groups';
        this.entity = SportModel;
        this.baseUrl = SPORT_BASE_URL;
        this.apiKey = SPORT_X_API_KEY;
    }
}
const sportService = new SportService();
async function searchSport(keyword) {
    var _a;
    let listSport = [];
    await ((_a = sportService === null || sportService === void 0 ? void 0 : sportService.getAll({
        params: {
            type: 'generic',
            name: keyword,
        },
    })) === null || _a === void 0 ? void 0 : _a.then(res => {
        listSport = res;
    }));
    return listSport;
}
export { sportService, searchSport };
