import ApiService from './api.service';
import { ResponsibilityModel } from '../models/responsibility.model';
export class ResponsibilityService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/responsibilities';
        this.entity = ResponsibilityModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const responsibilityService = new ResponsibilityService();
