<script lang="ts">
  import { getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { params } from '@roxi/routify';
  import { responsibilityTranslationService } from '../../../../services/responsibilityTranslations.service';

  let CurrentResponsibility = getContext('CurrentResponsibility');
  export let translationToDelete;
  $: slug = $params.slug;
  const { close } = getContext('simple-modal');

  function deleteTranslation(): void {
    void responsibilityTranslationService.delete(translationToDelete.id).then(value => {
      if (value) {
        $CurrentResponsibility.translations = $CurrentResponsibility.translations.filter(
          translation => translation.id !== translationToDelete.id
        );
      }
      close();
    });
  }
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {`${$_('branches.translations.deletion.title')} - ${translationToDelete.label} (${
      translationToDelete.language_id
    })`}
  </h2>
</div>
<h3 class="vtmn-p-4">{$_('skill.translation.deletion.subtitle')}</h3>

<div class="vtmn-flex vtmn-flex-row vtmn-justify-evenly vtmn-mt-3 modal-content">
  <button
    class=" vtmn-w-2/5 vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
    on:click|preventDefault={close}>
    {$_('global.buttons.cancel')}
  </button>
  <button
    class="vtmn-w-2/5 vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small"
    on:click|preventDefault={deleteTranslation}>
    {$_('skill.translation.deletion.confirm')}
  </button>
</div>
