import { ResponsibilityModel } from './responsibility.model';
import { SkillModel } from './skill.model';
export class ResponsibilitySkillModel {
    constructor(data = {}) {
        var _a;
        this.id = data.id;
        this.responsibility = new ResponsibilityModel({ id: data.responsibility_id });
        this.skill = new SkillModel({ id: data.skill_id });
        this.order = (_a = data === null || data === void 0 ? void 0 : data.skill_order) !== null && _a !== void 0 ? _a : 0;
        this.key = data === null || data === void 0 ? void 0 : data.skill_key;
    }
}
