<script lang="ts">
  import Modal from 'svelte-simple-modal';
  import FlatTable from '../../Generics/Table/FlatTable.svelte';
  import ModalButton from '../../Generics/Modal/ModalButton.svelte';
  import { Skills } from '../../../stores/skills.store';
  import { _ } from 'svelte-i18n';
  import { skillsService } from '../../../services/skills.service';
  import { onMount } from 'svelte';
  import { PaginationModel } from '../../../models/pagination.model';
  import Pagination from '../../Generics/Paginator/Pagination.svelte';
  import ListSkills from './ListSkills.svelte';
  import { UserInfos } from '../../../stores/userInfo.store';
  import CreateSkills from './CreateSkills.svelte';
  import {
    isAdmin,
    isBranchUnitedContributorForAtLeastOneBranch,
    isBranchLeaderForAtLeastOneBranch,
  } from '../../Auth/rights.utils';
  import { Branches } from '../../../stores/branches.store';
  import { branchesService } from '../../../services/branches.service';
  import { goto } from '@roxi/routify';
  import { BranchModel } from '../../../models/branch.model';
  import BranchFilter from '../Branches/BranchFilter.svelte';
  import BottomPageLogo from '../../Navigation/BottomPageLogo.svelte';

  type Types = 'technical' | 'human' | 'digital';
  let typeFilter: Types | undefined;

  export let currentBranch: BranchModel = undefined;
  export let createOption = true;
  let loading = false;
  let timer;
  let labelSearch = '';
  let descriptionSearch = '';
  let pagination: PaginationModel = new PaginationModel();
  let current = 1;
  let openModal;
  $: hasSkillsRights =
    isAdmin($UserInfos) ||
    isBranchLeaderForAtLeastOneBranch($UserInfos) ||
    isBranchUnitedContributorForAtLeastOneBranch($UserInfos);

  async function retrieveSkills(evt = undefined) {
    loading = true;
    if (evt != undefined) {
      current = evt.detail;
    }
    let params = {
      params: {
        page: current - 1,
        branch_id: currentBranch ? currentBranch?.id : '',
        label: labelSearch !== '' ? labelSearch : '',
        fullLabel: descriptionSearch !== '' ? descriptionSearch : '',
        type: typeFilter || '',
      },
    };

    // To transform with getAllByPagination when available
    await skillsService.getAllWithPagination(params).then(res => {
      Skills.set(res[0]);
      pagination = res[1];
      loading = false;
    });
  }

  function onInputSearches() {
    if (labelSearch === '' && descriptionSearch === '') {
      void retrieveSkills();
    } else {
      //Timeout to avoid multiple API calls
      clearTimeout(timer);
      timer = setTimeout(() => {
        current = 1;
        void retrieveSkills();
      }, 500);
    }
  }

  function loadBranchesAndSkills() {
    // Get Branches if not loaded
    if ($Branches.length === 0) {
      branchesService?.getAll()?.then(res => {
        Branches.set(res);
        void retrieveSkills();
      });
    } else {
      void retrieveSkills();
    }
  }

  const handleBranchFilter = (branchToRedirect = undefined) => {
    branchToRedirect?.id
      ? $goto('/skills/branch/' + branchToRedirect.id)
      : $goto('/skills/');
  };

  function handleTypeFilter(type?: Types) {
    typeFilter = type;
    void retrieveSkills();
  }

  onMount(() => {
    void loadBranchesAndSkills();
  });
</script>

{#if createOption}
  <div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
    <h1 class="vtmn-font-semi-bold vtmn-text-5xl vtmn-text-center">
      {$_('skill.title')}
    </h1>
    {#if hasSkillsRights}
      <Modal>
        <ModalButton>
          <button
            class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
            on:click={openModal(CreateSkills)}>
            {$_('skill.modification.create')}
          </button>
        </ModalButton>
      </Modal>
    {/if}
  </div>
{/if}

<FlatTable bind:loading col="6">
  <span slot="headers" class="vtmn-contents">
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12">
      <div class="dropdown vtmn-font-normal vtmn-w-full vtmn-inline-block vtmn-relative">
        <div
          class="dropbtn vtmn-flex vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-rounded vtmn-border-none vtmn-bg-white">
          {#if typeFilter}
            <span class="vtmn-text-xs vtmn-my-auto">{typeFilter.toUpperCase()}</span>
            <button
              class="vtmn-text-black vtmn-m-auto vtmn-mr-0"
              on:click={() => handleTypeFilter()}>x</button>
          {:else}
            {$_('global.type')}
          {/if}
        </div>
        <div
          class="dropdown-content vtmn-font-thin vtmn-w-full vtmn-absolute vtmn-bg-grey-light-4 vtmn-hidden">
          <span
            class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black"
            on:click={() => handleTypeFilter('technical')}>
            {$_('skillType.technical').toUpperCase()}
          </span>
          <span
            class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black"
            on:click={() => handleTypeFilter('human')}>
            {$_('skillType.human').toUpperCase()}
          </span>
          <span
            class="vtmn-cursor-pointer vtmn-block vtmn-p-3 vtmn-text-black"
            on:click={() => handleTypeFilter('digital')}>
            {$_('skillType.digital').toUpperCase()}
          </span>
        </div>
      </div>
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12">
      <input
        type="search"
        placeholder={$_('skill.table.name')}
        bind:value={labelSearch}
        on:input={onInputSearches}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12">
      <input
        type="search"
        placeholder={$_('skill.table.description')}
        bind:value={descriptionSearch}
        on:input={onInputSearches}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full
        vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <BranchFilter bind:currentBranch size="2/12" {handleBranchFilter} />
    <th class="vtmn-px-3 vtmn-py-2 vtmn-font-thin vtmn-w-1/12 vtmn-text-xs"
      >{$_('skill.table.responsibilities')}</th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12">{$_('global.actions')}</th>
  </span>

  <span slot="body" class="vtmn-contents">
    <ListSkills />
  </span>
</FlatTable>

<div class="vtmn-flex">
  <BottomPageLogo />
  <div class="vtmn-ml-auto vtmn-mr-2">
    <Pagination
      small
      bind:current
      num_items={pagination?.numItems}
      fromElement={pagination?.from}
      toElement={pagination?.to}
      per_page={pagination?.perPage}
      on:navigate={retrieveSkills} />
  </div>
</div>

<Modal>
  <ModalButton bind:openModal />
</Modal>
