import ApiService from './api.service';
import { JobTranslationModel } from '../models/jobTranslation.model';
export class JobTranslationsService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/job_translations';
        this.entity = JobTranslationModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const jobTranslationsService = new JobTranslationsService();
