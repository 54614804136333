<script lang="ts">
  import { getContext, onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import { params } from '@roxi/routify';
  import { maxLength, required, useForm, validators } from 'svelte-use-form';
  import { SkillTranslationModel } from '../../../../models/skillTranslation.model';
  import * as localeCodes from 'locale-codes';
  import { failure } from '../../../Toastr/toastr';
  import LanguageIdSelector from '../../../Generics/TranslationUtilities/LanguageIdSelector.svelte';
  import { skillTranslationService } from '../../../../services/skillTranslations.service';

  $: slug = $params.slug;
  const { close } = getContext('simple-modal');
  let CurrentSkill = getContext('CurrentSkill');
  let defaultTranslation: SkillTranslationModel;
  const modifyTranslationForm = useForm();
  export let translationToModify: SkillTranslationModel;
  export let languagesList: Array<localeCodes.ILocale> = [];
  let selectedLanguage: localeCodes.ILocale;
  let creationMode: boolean;

  if (translationToModify) {
    creationMode = false;
  } else {
    creationMode = true;
    translationToModify = new SkillTranslationModel();
  }

  function modifyTranslation(): void {
    void skillTranslationService
      .patch(translationToModify.id, {
        label: $modifyTranslationForm.label.value,
        full_label: $modifyTranslationForm.full_label.value,
      })
      .then((value: SkillTranslationModel) => {
        if (value) {
          const index = $CurrentSkill.translations.findIndex(
            trad => trad.id === translationToModify?.id
          );
          $CurrentSkill.translations[index] = new SkillTranslationModel(value);
          CurrentSkill.set($CurrentSkill);
          close();
        }
      });
  }

  function addTranslation(): void {
    if (
      $CurrentSkill?.translations.findIndex(
        translation => translation.language_id === selectedLanguage.tag
      ) !== -1
    ) {
      failure($_('global.translation_error') + ' (' + selectedLanguage?.name + ')');
    } else {
      void createTranslation();
    }
  }

  function createTranslation(): void {
    void skillTranslationService
      .create({
        label: $modifyTranslationForm.label.value,
        full_label: $modifyTranslationForm.full_label.value,
        language_id: selectedLanguage.tag,
        skill_id: slug,
      })
      .then((response: SkillTranslationModel) => {
        if (response) {
          $CurrentSkill.translations = [
            ...$CurrentSkill.translations,
            new SkillTranslationModel(response),
          ];
          translationToModify = new SkillTranslationModel();
          close();
        }
      });
  }

  onMount(() => {
    defaultTranslation =
      $CurrentSkill?.translations.find(
        translation => translation?.language_id === 'en-001'
      ) ??
      $CurrentSkill?.translations.find(translation => translation?.language_id === 'en');
  });
</script>

<form use:modifyTranslationForm>
  <div class="vtmn-flex modal-title">
    <h2 class="vtmn-my-auto">
      {#if creationMode}
        {`${$_('skill.translation.creation')}`}
      {:else}
        {`${$_('skill.translation.modification')} (${translationToModify.language_id})`}
      {/if}
    </h2>
  </div>

  <div class="modal-content">
    {#if creationMode}
      <div class="input-wrapper">
        <label class="control-label"
          >{$_('branches.translations.placeholders.language')}</label>
        <LanguageIdSelector bind:selectedLanguage bind:languagesList />
      </div>
    {/if}
    <div class="input-wrapper">
      <label for="skillLabel" class="control-label">
        {$_('skill.translation.name')}
        {#if defaultTranslation}
          <span class="vtmn-italic vtmn-text-grey">({defaultTranslation?.label})</span>
        {/if}
      </label>
      <input
        bind:value={translationToModify.label}
        class="vtmn-block vtmn-w-full vtmn-p-2 vtmn-sm:text-smnvtmn-rounded-md"
        id="skillLabel"
        name="label"
        placeholder={$_('branches.translations.placeholders.translation')}
        type="text"
        use:validators={[required, maxLength(60)]} />
    </div>
    <div
      class="vtmn-flex vtmn-justify-end {translationToModify.label?.length > 60
        ? 'control-label-danger'
        : 'control-label'}">
      {translationToModify.label?.length || 0}/60
    </div>

    <div class="input-wrapper">
      <label for="skillFullLabel" class="control-label">
        {$_('skill.translation.description')}
        {#if defaultTranslation}
          <span class="vtmn-italic vtmn-text-grey"
            >({defaultTranslation?.full_label})</span>
        {/if}
      </label>
      <textarea
        bind:value={translationToModify.full_label}
        class="vtmn-block vtmn-w-full vtmn-p-2 vtmn-sm:text-smnvtmn-rounded-md"
        id="skillFullLabel"
        name="full_label"
        placeholder={$_('branches.translations.placeholders.translation')}
        type="text"
        use:validators={[required, maxLength(250)]} />
    </div>
    <div
      class="vtmn-flex vtmn-justify-end {translationToModify?.full_label?.length > 250
        ? 'control-label-danger'
        : 'control-label'}">
      {translationToModify?.full_label?.length || 0}/250
    </div>

    <div class="vtmn-flex-row vtmn-justify-end vtmn-mt-3 vtmn-mr-3 vtmn-text-right">
      <button
        class="vtmn-btn vtmn-btn_size--small"
        disabled={!$modifyTranslationForm.valid}
        on:click|preventDefault={!creationMode
          ? void modifyTranslation()
          : void addTranslation()}>
        {!creationMode ? $_('global.buttons.modify') : $_('global.buttons.create')}
      </button>
    </div>
  </div>
</form>
