
/**
 * @roxi/routify 2.18.8
 * File generated Tue Aug 06 2024 08:43:54 GMT+0000 (Coordinated Universal Time)
 */

export const __version = "2.18.8"
export const __timestamp = "2024-08-06T08:43:54.235Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/branches/:slug/index",
              "id": "_branches__slug_index",
              "component": () => import('../src/pages/branches/[slug]/index.svelte').then(m => m.default)
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/branches/:slug/translations/index",
                  "id": "_branches__slug_translations_index",
                  "component": () => import('../src/pages/branches/[slug]/translations/index.svelte').then(m => m.default)
                }
              ],
              "path": "/branches/:slug/translations"
            }
          ],
          "path": "/branches/:slug"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/branches/index",
          "id": "_branches_index",
          "component": () => import('../src/pages/branches/index.svelte').then(m => m.default)
        }
      ],
      "path": "/branches"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isPage": true,
          "path": "/error/403",
          "id": "_error_403",
          "component": () => import('../src/pages/error/403.svelte').then(m => m.default)
        },
        {
          "isPage": true,
          "path": "/error/404",
          "id": "_error_404",
          "component": () => import('../src/pages/error/404.svelte').then(m => m.default)
        }
      ],
      "path": "/error"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/export/index",
          "id": "_export_index",
          "component": () => import('../src/pages/export/index.svelte').then(m => m.default)
        }
      ],
      "path": "/export"
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/jobs/:slug/index",
              "id": "_jobs__slug_index",
              "component": () => import('../src/pages/jobs/[slug]/index.svelte').then(m => m.default)
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/jobs/:slug/translations/index",
                  "id": "_jobs__slug_translations_index",
                  "component": () => import('../src/pages/jobs/[slug]/translations/index.svelte').then(m => m.default)
                }
              ],
              "path": "/jobs/:slug/translations"
            }
          ],
          "path": "/jobs/:slug"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/jobs/index",
          "id": "_jobs_index",
          "component": () => import('../src/pages/jobs/index.svelte').then(m => m.default)
        }
      ],
      "path": "/jobs"
    },
    {
      "isDir": true,
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/me/branches/index",
              "id": "_me_branches_index",
              "component": () => import('../src/pages/me/branches/index.svelte').then(m => m.default)
            }
          ],
          "path": "/me/branches"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/me/IA/index",
              "id": "_me_IA_index",
              "component": () => import('../src/pages/me/IA/index.svelte').then(m => m.default)
            }
          ],
          "path": "/me/IA"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/me/skills/index",
              "id": "_me_skills_index",
              "component": () => import('../src/pages/me/skills/index.svelte').then(m => m.default)
            }
          ],
          "path": "/me/skills"
        }
      ],
      "isLayout": true,
      "path": "/me",
      "id": "_me__layout",
      "component": () => import('../src/pages/me/_layout.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/responsibilities/:slug/jobs/index",
                  "id": "_responsibilities__slug_jobs_index",
                  "component": () => import('../src/pages/responsibilities/[slug]/jobs/index.svelte').then(m => m.default)
                }
              ],
              "path": "/responsibilities/:slug/jobs"
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/responsibilities/:slug/roles/index",
                  "id": "_responsibilities__slug_roles_index",
                  "component": () => import('../src/pages/responsibilities/[slug]/roles/index.svelte').then(m => m.default)
                }
              ],
              "path": "/responsibilities/:slug/roles"
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/responsibilities/:slug/translations/index",
                  "id": "_responsibilities__slug_translations_index",
                  "component": () => import('../src/pages/responsibilities/[slug]/translations/index.svelte').then(m => m.default)
                }
              ],
              "path": "/responsibilities/:slug/translations"
            }
          ],
          "path": "/responsibilities/:slug"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/responsibilities/index",
          "id": "_responsibilities_index",
          "component": () => import('../src/pages/responsibilities/index.svelte').then(m => m.default)
        }
      ],
      "path": "/responsibilities"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/roles/:slug/index",
              "id": "_roles__slug_index",
              "component": () => import('../src/pages/roles/[slug]/index.svelte').then(m => m.default)
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/roles/:slug/translations/index",
                  "id": "_roles__slug_translations_index",
                  "component": () => import('../src/pages/roles/[slug]/translations/index.svelte').then(m => m.default)
                }
              ],
              "path": "/roles/:slug/translations"
            }
          ],
          "path": "/roles/:slug"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/roles/index",
          "id": "_roles_index",
          "component": () => import('../src/pages/roles/index.svelte').then(m => m.default)
        }
      ],
      "path": "/roles"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isIndex": true,
              "isPage": true,
              "path": "/skills/:slug/index",
              "id": "_skills__slug_index",
              "component": () => import('../src/pages/skills/[slug]/index.svelte').then(m => m.default)
            },
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/skills/:slug/translations/index",
                  "id": "_skills__slug_translations_index",
                  "component": () => import('../src/pages/skills/[slug]/translations/index.svelte').then(m => m.default)
                }
              ],
              "path": "/skills/:slug/translations"
            }
          ],
          "path": "/skills/:slug"
        },
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isDir": true,
              "ext": "",
              "children": [
                {
                  "isIndex": true,
                  "isPage": true,
                  "path": "/skills/branch/:slug/index",
                  "id": "_skills_branch__slug_index",
                  "component": () => import('../src/pages/skills/branch/[slug]/index.svelte').then(m => m.default)
                }
              ],
              "path": "/skills/branch/:slug"
            }
          ],
          "path": "/skills/branch"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/skills/index",
          "id": "_skills_index",
          "component": () => import('../src/pages/skills/index.svelte').then(m => m.default)
        }
      ],
      "path": "/skills"
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isIndex": true,
          "isPage": true,
          "path": "/user_rights/index",
          "id": "_user_rights_index",
          "component": () => import('../src/pages/user_rights/index.svelte').then(m => m.default)
        }
      ],
      "path": "/user_rights"
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

