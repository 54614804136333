import ApiService from './api.service';
import { RoleResponsibilityModel } from '../models/roleResponsibility.model';
export class RoleResponsibilityService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/role_responsibility';
        this.entity = RoleResponsibilityModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const roleResponsibilityService = new RoleResponsibilityService();
