<script lang="ts">
  import { Responsibilities } from '../../../stores/responsibilities.store';
  import { _ } from 'svelte-i18n';
  import RawResponsibility from './RawResponsibility.svelte';

  let openModal;
</script>

{#if $Responsibilities?.length > 0}
  {#each $Responsibilities as responsibility, i}
    <RawResponsibility bind:responsibility />
  {/each}
{:else}
  <td colspan="5" class="vtmn-p-4 vtmn-text-center">
    {$_('search.none')}
  </td>
{/if}
