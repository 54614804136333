<script lang="ts">
  import { onMount } from 'svelte';
  import { _ } from 'svelte-i18n';
  import FlatTr from '../../../Generics/Table/FlatTr.svelte';
  import FlatTable from '../../../Generics/Table/FlatTable.svelte';
  import FlatTd from '../../../Generics/Table/FlatTd.svelte';
  import ValidationQuestion from '../../../Generics/Question/ValidationQuestion.svelte';
  import { userService } from '../../../../services/user.service';
  import type { UserInfo } from '../../../../models/userInfo.model';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import { Countries } from '../../../../stores/countries.store';
  import { countryService } from '../../../../services/country.service';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { SkillModel } from '../../../../models/skill.model';
  import { LeaderModel } from '../../../../models/leader.model';
  import { skillLeaderService } from '../../../../services/skillLeader.service';
  import { SkillLeaders } from '../../../../stores/leaders.store';
  import { PaginationModel } from '../../../../models/pagination.model';
  import Pagination from '../../../Generics/Paginator/Pagination.svelte';
  import CreateSkillLeader from './CreateSkillLeader.svelte';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import {
    isAdmin,
    isBranchContributor,
    isBranchLeader,
    isBranchLocalContributor,
    isBranchUnitedContributor,
  } from '../../../Auth/rights.utils';
  import { getCountryFromCode } from '../../Countries/countries.util';
  import EditLeader from './EditLeader.svelte';
  import UserImage from '../../User/UserImage.svelte';

  export let skill: SkillModel;
  $: canCreateSkillLeader =
    isAdmin($UserInfos) ||
    isBranchLeader($UserInfos, [skill?.branch]) ||
    isBranchContributor($UserInfos, [skill?.branch]);
  let pagination: PaginationModel = new PaginationModel();
  let loading = true;
  let showPagination = false;
  let current = 1;
  let leaderToEdit: LeaderModel;
  let leaderToRemove: LeaderModel;
  let tableHeaders = [
    { size: '2/7', title: 'branch_contributor.table.name' },
    { size: '3/7', title: 'branch_contributor.table.country' },
    { size: '2/7', title: 'global.actions' },
  ];

  function canDeleteSkillLeader(skillLeader: LeaderModel): boolean {
    let canEdit: boolean =
      isAdmin($UserInfos) ||
      isBranchLeader($UserInfos, [skill?.branch]) ||
      isBranchUnitedContributor($UserInfos, [skill?.branch]);
    return skillLeader?.countries?.length > 0
      ? canEdit ||
          isBranchLocalContributor($UserInfos, [skill?.branch], skillLeader?.countries)
      : canEdit;
  }

  function removeContributor() {
    if (leaderToRemove) {
      void skillLeaderService.delete(leaderToRemove?.id).then(value => {
        if (value) {
          $SkillLeaders = $SkillLeaders.filter(
            contrib => contrib.id != leaderToRemove.id
          );
          leaderToRemove = undefined;
        }
      });
    }
  }

  function retrieveLeaderData(skillLeadersData) {
    if (skillLeadersData.member.uuid != undefined) {
      userService
        .getAll({ params: { ldap_uuid: skillLeadersData?.member?.uuid } })
        ?.then((users: UserInfo[]) => {
          if (users.length > 0) {
            skillLeadersData.member = users[0];
          } else {
            skillLeadersData.member.fullName = $_('global.unknown_user');
          }
          SkillLeaders.update(contributors => [...contributors, skillLeadersData]);
          loading = false;
        });
    }
  }

  function retrieveLeaders(evt = undefined): void {
    if (evt != undefined) {
      current = evt.detail;
      loading = true;
    }
    skillLeaderService
      .getAllWithPagination({ params: { skill_id: skill.id, page: current - 1 } })
      ?.then(res => {
        if (res.length !== 0) {
          SkillLeaders.set([]);
          pagination = res[1];
          showPagination = true;
          for (const skillLeadersData of res[0]) {
            retrieveLeaderData(skillLeadersData);
          }
        } else {
          loading = false;
        }
      });
  }

  onMount(() => {
    $SkillLeaders = [];
    if ($Countries.length === 0) {
      countryService?.getAll()?.then(res => {
        Countries.set(res);
      });
    }
    void retrieveLeaders();
  });
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('leader.title')}
  </h2>
</div>

<div class="modal-content">
  <FlatTable {tableHeaders} bind:loading row={3}>
    <span slot="body" class="vtmn-contents">
      {#if canCreateSkillLeader}
        <CreateSkillLeader {skill} />
      {/if}
      {#if $SkillLeaders.length > 0}
        {#each $SkillLeaders as skillLeader, i}
          <FlatTr>
            <FlatTd>
              {#if skillLeader.member?.fullName}
                <div class="vtmn-flex vtmn-flex-row vtmn-items-center">
                  <UserImage uid={skillLeader.member.uid} />
                  <div>{skillLeader.member?.fullName}</div>
                </div>
              {/if}
            </FlatTd>
            {#if leaderToEdit?.member.uuid === skillLeader?.member.uuid}
              <FlatTd>
                <EditLeader bind:leaderToEdit />
              </FlatTd>
            {:else}
              <FlatTd>
                {#if skillLeader?.countries?.length === 0}
                  United
                {:else}
                  {#each skillLeader.countries as country}
                    <span class="countryFlag"
                      >{getCountryFromCode(country?.code)?.flag}</span
                    >{' ' + getCountryFromCode(country?.code)?.name} <br />
                  {/each}
                {/if}
              </FlatTd>
            {/if}
            {#if canDeleteSkillLeader(skillLeader)}
              <FlatTd>
                <div
                  class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
                  {#if leaderToRemove?.member.uuid === skillLeader.member.uuid}
                    <ValidationQuestion
                      on:validate={() => {
                        removeContributor(leaderToRemove);
                      }}
                      on:cancel={() => {
                        leaderToRemove = undefined;
                      }} />
                  {:else}
                    <button
                      class="vtmn-cursor-pointer vtmn-my-auto vtmn-ml-2 vtmn-mr-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full"
                      on:click={() => {
                        leaderToEdit = skillLeader;
                      }}>
                      <Pencil color="#404040" size="1.5em" />
                    </button>

                    <button
                      class="vtmn-cursor-pointer vtmn-my-auto vtmn-ml-auto vtmn-mr-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full"
                      on:click={() => {
                        leaderToRemove = skillLeader;
                      }}>
                      <Delete color="#404040" size="1.5em" />
                    </button>
                  {/if}
                </div>
              </FlatTd>
            {/if}
          </FlatTr>
        {/each}
      {:else}
        <div class="vtmn-p-4 vtmn-text-base">{$_('branch_contributor.empty')}</div>
      {/if}
    </span>
  </FlatTable>

  {#if showPagination}
    <div class="vtmn-flex">
      <div class="vtmn-ml-auto vtmn-mr-2">
        <Pagination
          small
          bind:current
          num_items={pagination?.numItems}
          fromElement={pagination?.from}
          light={false}
          toElement={pagination?.to}
          per_page={pagination?.perPage}
          on:navigate={retrieveLeaders} />
      </div>
    </div>
  {/if}
</div>
