<script lang="ts">
  import { createEventDispatcher, getContext } from 'svelte';
  import { _ } from 'svelte-i18n';
  import type { ResponsibilityModel } from '../../../models/responsibility.model';
  import { responsibilityService } from '../../../services/responsibility.service';
  import { Responsibilities } from '../../../stores/responsibilities.store';

  export let deletedResponsibility: ResponsibilityModel;
  const dispatch = createEventDispatcher();
  const { close } = getContext('simple-modal');

  function deleteResponsibility(deletedResponsibility: ResponsibilityModel) {
    void responsibilityService.delete(deletedResponsibility.id).then(res => {
      if (res) {
        Responsibilities.update((responsibilities: ResponsibilityModel[]) =>
          responsibilities.filter(
            (responsibility: ResponsibilityModel) =>
              responsibility.id !== deletedResponsibility.id
          )
        );
        close();
      }
    });
  }
</script>

<div class="vtmn-flex modal-title">
  <h2 class="vtmn-my-auto">
    {$_('responsibilities.deletion.title')} - {deletedResponsibility?.labelTranslated}
  </h2>
</div>

<h3 class="vtmn-p-4">{$_('responsibilities.deletion.subtitle')}</h3>

<div class="vtmn-flex vtmn-flex-row vtmn-justify-evenly vtmn-mt-3 modal-content">
  <button
    class=" vtmn-w-2/5 vtmn-btn vtmn-btn_variant--secondary vtmn-btn_size--small"
    on:click|preventDefault={close}>
    {$_('global.buttons.cancel')}
  </button>
  <button
    class="vtmn-w-2/5 vtmn-btn vtmn-btn_variant--conversion vtmn-btn_size--small"
    on:click|preventDefault={deleteResponsibility(deletedResponsibility)}>
    {$_('responsibilities.deletion.confirm')}
  </button>
</div>
