<script lang="ts">
  import { params } from '@roxi/routify';
  import Modal from 'svelte-simple-modal';
  import { returnCorrectSkillTranslation } from '../../../../components/Contents/Skills/skills.utils';
  import { UserInfos } from '../../../../stores/userInfo.store';
  import { onMount, setContext } from 'svelte';
  import FlatTable from '../../../../components/Generics/Table/FlatTable.svelte';
  import FlatTr from '../../../../components/Generics/Table/FlatTr.svelte';
  import FlatTd from '../../../../components/Generics/Table/FlatTd.svelte';
  import ModalButton from '../../../../components/Generics/Modal/ModalButton.svelte';
  import { _ } from 'svelte-i18n';
  import Pencil from 'svelte-material-icons/Pencil.svelte';
  import Delete from 'svelte-material-icons/Delete.svelte';
  import { skillsService } from '../../../../services/skills.service';
  import { SkillModel } from '../../../../models/skill.model';
  import * as localeCodes from 'locale-codes';
  import DeleteSkillTranslation from '../../../../components/Contents/Skills/Translations/DeleteSkillTranslation.svelte';
  import CreateSkillTranslation from '../../../../components/Contents/Skills/Translations/CreateSkillTranslation.svelte';
  import { writable } from 'svelte/store';
  import { SkillTranslationModel } from '../../../../models/skillTranslation.model';
  import { sortTranslations, updateLanguageList } from '../../../../components/utils';
  import { allLanguagesList } from '../../../../stores/languages.store';
  import { skillTranslationService } from '../../../../services/skillTranslations.service';
  import { tooltip } from '../../../../components/Generics/Tooltip/tooltip';
  import {
    isAdmin,
    isBranchContributor,
    isBranchLeader,
    isSkillLeader,
    isTranslator,
  } from '../../../../components/Auth/rights.utils';
  import { getFlagFromLanguage } from '../../../../components/Contents/Languages/languages.utils';
  import { goto } from '@roxi/routify';

  $: slug = $params.slug;
  let CurrentSkill = writable(new SkillModel());
  setContext('CurrentSkill', CurrentSkill);
  $: canCreateOrEditTranslation =
    isAdmin($UserInfos) ||
    isTranslator($UserInfos) ||
    isBranchLeader($UserInfos, [$CurrentSkill.branch]) ||
    isBranchContributor($UserInfos, [$CurrentSkill.branch]) ||
    isSkillLeader($UserInfos, [$CurrentSkill]);
  $: canDeleteTranslation = isAdmin($UserInfos);
  let selectedLanguage;
  let allLanguages: Array<localeCodes.ILocale> = [];
  let languagesList: Array<localeCodes.ILocale> = [];
  let newTranslationFullLabel = '';
  let newTranslationLabel = '';
  let localeSearch = '';
  let labelSearch = '';
  let fullLabelSearch = '';
  let skillDisplayed = new SkillModel();
  let loading = true;
  let openModal;
  let tableHeaders = [
    { size: '1/12', title: 'branches.translations.table.language' },
    { size: '2/12', title: 'branches.translations.table.translation' },
    { size: '7/12', title: '' },
    { size: '2/12', title: 'global.actions' },
  ];

  // This function is called everytime the store is updated (new translations etc...) or when a user makes a search
  function updateSkillsTranslationsDisplay() {
    if (
      labelSearch === '' &&
      fullLabelSearch === '' &&
      localeSearch === '' &&
      $CurrentSkill.translations.length > 0
    ) {
      // If the search input is empty : we display all the branches
      skillDisplayed.translations = $CurrentSkill.translations;
    } else {
      // Else, we filter the array depending on the search
      skillDisplayed.translations = $CurrentSkill.translations.filter(
        (skillTranslated: SkillTranslationModel) => {
          return (
            skillTranslated.label.toLowerCase().includes(labelSearch.toLowerCase()) &&
            skillTranslated.full_label
              .toLowerCase()
              .includes(fullLabelSearch.toLowerCase()) &&
            allLanguages
              ?.find(country => country.tag === skillTranslated?.language_id)
              ?.name.toLowerCase()
              .includes(localeSearch.toLowerCase())
          );
        }
      );
    }
    sortTranslations(skillDisplayed.translations);
  }

  function getSkill(): void {
    skillsService.get(slug)?.then(res => {
      if (res?.id) {
        CurrentSkill.set(res);
        skillTranslationService
          .getAll({ params: { skill_id: slug } })
          .then(translations => {
            $CurrentSkill.translations = translations;
            languagesList = allLanguages;
            languagesList = updateLanguageList(languagesList, $CurrentSkill.translations);
            loading = false;
          });
      } else {
        $goto('/error/404');
      }
    });
  }

  onMount(() => {
    allLanguages = $allLanguagesList;
    CurrentSkill.subscribe(() => {
      updateSkillsTranslationsDisplay(); //Update translation display everytime the skill changes
    });
    void getSkill();
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-font-semi-bold vtmn-text-5xl vtmn-text-center">
    {$_('skill.name.single')} :
    {$CurrentSkill.labelTranslated !== ''
      ? $CurrentSkill.labelTranslated
      : returnCorrectSkillTranslation(
          $CurrentSkill,
          true,
          $UserInfos.preferredLanguage,
          $UserInfos.preferredLanguageWithoutLocale,
          $_('api_call.translation_not_found')
        )}
  </h1>
  {#if canCreateOrEditTranslation}
    <Modal>
      <ModalButton>
        <button
          class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
          on:click={openModal(CreateSkillTranslation, { languagesList })}>
          {$_('translations.create')}
        </button>
      </ModalButton>
    </Modal>
  {/if}
</div>
<h2 class="vtmn-mb-2 vtmn-text-xl vtmn-text-white">
  {$_('global.translations')} :
</h2>

<FlatTable bind:loading {tableHeaders}>
  <span slot="headers" class="vtmn-contents">
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('branches.translations.table.language')}
        bind:value={localeSearch}
        on:input={updateSkillsTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('skill.translation.name')}
        bind:value={labelSearch}
        on:input={updateSkillsTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">
      <input
        type="search"
        placeholder={$_('skill.translation.description')}
        bind:value={fullLabelSearch}
        on:input={updateSkillsTranslationsDisplay}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/4">{$_('global.actions')}</th>
  </span>
  <span slot="body" class="vtmn-contents">
    {#each skillDisplayed.translations as translation, i}
      <FlatTr>
        <FlatTd>
          {#if allLanguages.length > 0}
            <span class="countryFlag"
              >{getFlagFromLanguage(translation?.language_id)}</span>
            {allLanguages?.find(country => country.tag === translation.language_id)?.name}
            ({allLanguages?.find(country => country.tag === translation.language_id)
              ?.location || 'Standard'})
          {/if}
        </FlatTd>
        <FlatTd>{translation.label}</FlatTd>
        <FlatTd>{translation.full_label}</FlatTd>
        {#if canCreateOrEditTranslation}
          <FlatTd>
            <div
              class="vtmn-m-auto vtmn-flex vtmn-bg-grey-light-4 vtmn-p-2 vtmn-rounded-full">
              <Modal>
                <ModalButton>
                  <button
                    class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                    use:tooltip={{ text: $_('global.buttons.modify') }}
                    on:click={openModal(CreateSkillTranslation, {
                      translationToModify: translation,
                    })}>
                    <Pencil color="#404040" size="1.5em" />
                  </button>
                </ModalButton>
              </Modal>
              {#if canDeleteTranslation && translation?.language_id?.toUpperCase() !== 'EN-001'}
                <!--Remove the english world translation is forbidden-->
                <Modal>
                  <ModalButton>
                    <button
                      class="vtmn-cursor-pointer vtmn-m-auto vtmn-border-none vtmn-p-1 vtmn-rounded-full vtmn-relative"
                      use:tooltip={{ text: $_('branches.deletion.button') }}
                      on:click={openModal(DeleteSkillTranslation, {
                        translationToDelete: translation,
                      })}>
                      <Delete color="#404040" size="1.5em" />
                    </button>
                  </ModalButton>
                </Modal>
              {/if}
            </div>
          </FlatTd>
        {/if}
      </FlatTr>
    {/each}
  </span>
</FlatTable>

<Modal>
  <ModalButton bind:openModal />
</Modal>
