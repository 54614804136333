<!--This component is used to display loaders in a table-->
<script lang="ts">
  import TextLoader from './TextLoader.svelte';

  export let col;
  export let n;
</script>

{#each { length: n } as _, i}
  <tr
    class="hover:vtmn-bg-grey-light-4 vtmn-border-b vtmn-border-grey-light-2 vtmn-py-10">
    {#each { length: col } as __, f}
      <td class="vtmn-px-4 vtmn-py-4 vtmn-cursor-pointer">
        <TextLoader />
      </td>
    {/each}
  </tr>
{/each}
