// This function is called to filter languages list (used in creation modal) to prevent translation creation when a translation already exists
// It return the languages list without locales that are already translated
export function updateLanguageList(languagesList, translations) {
    var _a;
    languagesList = languagesList.filter(language => language.location !== null); // Remove all languages without location (standard)
    if (translations !== undefined && translations.length > 0) {
        for (const translation of translations) {
            // @ts-ignore
            const language_id = (_a = translation.language_id) !== null && _a !== void 0 ? _a : translation[0]; // Skills translation still use API V1 with a different construction than Job or Branch. It will be updated when skills API V2 will be launched.
            languagesList = languagesList.filter(language => language.tag !== language_id);
        }
    }
    return languagesList;
}
export function sortTranslations(translations) {
    translations.sort((a, b) => {
        if (a.language_id === 'en-001') {
            return -1;
        }
        if (a.language_id === 'fr-FR' && b.language_id !== 'en-001') {
            return -1;
        }
        if (b.language_id === 'en-001') {
            return 1;
        }
        if (b.language_id === 'fr-FR' && a.language_id !== 'en-001') {
            return 1;
        }
        return a.language_id.localeCompare(b.language_id);
    });
}
export function returnCorrectTranslation(entity, isLabel, preferredLanguage = 'en', preferredLanguageWithoutLocale, notFoundMessage = 'Not found') {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    const trad = // 4 cases here : the preferredLanguage (i.e: fr-CA), the preferredLanguage without locale (i.e: fr), english (World) or one english (doesn't matter of the locale)
     (_k = (_h = (_f = (_d = (_b = (_a = entity.translations) === null || _a === void 0 ? void 0 : _a.find(translation => translation.language_id.toLowerCase() === preferredLanguage.toLowerCase())) !== null && _b !== void 0 ? _b : (_c = entity.translations) === null || _c === void 0 ? void 0 : _c.find(translation => translation.language_id.toLowerCase().split('-')[0] ===
        preferredLanguageWithoutLocale.toLowerCase())) !== null && _d !== void 0 ? _d : (_e = entity.translations) === null || _e === void 0 ? void 0 : _e.find(translation => translation.language_id.toLowerCase() === 'en-001')) !== null && _f !== void 0 ? _f : (_g = entity.translations) === null || _g === void 0 ? void 0 : _g.find(translation => translation.language_id.toLowerCase().split('-')[0] === 'en')) !== null && _h !== void 0 ? _h : (_j = entity.translations) === null || _j === void 0 ? void 0 : _j.find(translation => translation.language_id !== null)) !== null && _k !== void 0 ? _k : undefined;
    return trad ? (isLabel ? trad.label : trad.full_label) : notFoundMessage;
}
