<script lang="ts">
  import { UserInfos } from '../../../stores/userInfo.store';
  import { _ } from 'svelte-i18n';
  import RawSkill from '../../../components/Contents/Skills/RawSkill.svelte';
  import FlatTable from '../../../components/Generics/Table/FlatTable.svelte';
  import { ResponsibilityModel } from '../../../models/responsibility.model';
  import { onMount } from 'svelte';
  import { responsibilitySkillService } from '../../../services/responsibilitySkill.service';
  import { ResponsibilitySkillModel } from '../../../models/responsibilitySkill.model';
  import { params } from '@roxi/routify';
  import { skillsService } from '../../../services/skills.service';
  import ModalButton from '../../../components/Generics/Modal/ModalButton.svelte';
  import Modal from 'svelte-simple-modal';
  import EditSkillOrder from '../../../components/Contents/ResponsibilitySkills/EditSkillOrder.svelte';
  import {
    ResponsibilitySkillsKey,
    ResponsibilitySkillsNotKey,
  } from '../../../stores/responsibilitySkill.store';
  import { responsibilityService } from '../../../services/responsibility.service';
  import LinkResponsiblitySkill from '../../../components/Contents/ResponsibilitySkills/LinkResponsiblitySkill.svelte';
  import {
    isAdmin,
    isBranchContributorForAtLeastOneBranch,
    isBranchLeaderForAtLeastOneBranch,
    isBranchUnitedContributorForAtLeastOneBranch,
  } from '../../../components/Auth/rights.utils';
  import { Branches } from '../../../stores/branches.store';
  import { branchesService } from '../../../services/branches.service';
  import { BranchModel } from '../../../models/branch.model';
  import { sortBranches } from '../../../components/Contents/Branches/branches.utils';

  $: slug = $params.slug;
  $: canCreateLink =
    isAdmin($UserInfos) ||
    isBranchLeaderForAtLeastOneBranch($UserInfos) ||
    isBranchContributorForAtLeastOneBranch($UserInfos);
  $: canManageOrder =
    isAdmin($UserInfos) ||
    isBranchLeaderForAtLeastOneBranch($UserInfos) ||
    isBranchContributorForAtLeastOneBranch($UserInfos);
  let currentResponsibility = new ResponsibilityModel({ id: slug });
  let loading = false;
  let openModal;

  $ResponsibilitySkillsKey = [];
  $ResponsibilitySkillsNotKey = [];

  function retrieveSkillsInfos(responsibilitySkillList: ResponsibilitySkillModel[]) {
    responsibilitySkillList.forEach(responsibilitySkill => {
      skillsService.get(responsibilitySkill.skill.id).then(res => {
        responsibilitySkill.responsibility = currentResponsibility;
        responsibilitySkill.skill = res;
        responsibilitySkill.key
          ? ($ResponsibilitySkillsKey = [
              ...$ResponsibilitySkillsKey,
              responsibilitySkill,
            ])
          : ($ResponsibilitySkillsNotKey = [
              ...$ResponsibilitySkillsNotKey,
              responsibilitySkill,
            ]);
        if (
          $ResponsibilitySkillsKey.length + $ResponsibilitySkillsNotKey.length ==
          responsibilitySkillList.length
        ) {
          // When all data has been fetch, order array by order
          $ResponsibilitySkillsKey.sort(function (a, b) {
            // Sort by order
            return a.order - b.order;
          });
          $ResponsibilitySkillsNotKey.sort(function (a, b) {
            // Sort by order
            return a.order - b.order;
          });
        }
      });
    });
  }

  function retrieveResponsibilitySkills() {
    responsibilitySkillService
      .getAll({ params: { responsibility_id: slug } })
      ?.then(res => {
        if (res?.length > 0) {
          retrieveSkillsInfos(res);
        }
      });
  }

  onMount(() => {
    if ($Branches.length === 0) {
      void branchesService.getAll().then((res: Array<BranchModel>) => {
        Branches.set(sortBranches(res));
        retrieveResponsibilitySkills();
      });
    } else {
      retrieveResponsibilitySkills();
    }
    responsibilityService.get(slug).then(res => {
      if (res) {
        currentResponsibility = res;
      }
    });
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-font-semi-bold vtmn-text-5xl">
    {$_('responsibility.name.single')} :
    {currentResponsibility?.labelTranslated}
  </h1>

  {#if canCreateLink}
    <button
      class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
      on:click={openModal(LinkResponsiblitySkill, { currentResponsibility })}>
      {$_('skill.link')}
    </button>
  {/if}
</div>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-text-xl">
    {$_('skill.modification.key.label')} :
  </h1>
  {#if canManageOrder}
    <button
      class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
      on:click={openModal(EditSkillOrder, {
        key: true,
        responsibilitySkillList: $ResponsibilitySkillsKey,
      })}>
      {$_('global.buttons.order')}
    </button>
  {/if}
</div>

{#if $ResponsibilitySkillsKey?.length > 0}
  <FlatTable bind:loading col="5">
    <span slot="headers" class="vtmn-contents">
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12">Type</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
        >{$_('skill.table.name')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
        >{$_('skill.table.description')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12"
        >{$_('branches.name.single')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12"
        >{$_('skill.table.responsibilities')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12"
        >{$_('global.actions')}</th>
    </span>

    <span slot="body" class="vtmn-contents">
      {#each $ResponsibilitySkillsKey as item}
        <RawSkill bind:skill={item.skill} bind:responsibilitySkill={item} />
      {/each}
    </span>
  </FlatTable>
{:else}
  <p class="vtmn-font-thin vtmn-text-white">{$_('skill.not_found.key')}</p>
{/if}

<div class="vtmn-flex vtmn-flex-wrap vtmn-mt-5 vtmn-mb-5">
  <h1 class="vtmn-text-xl">
    {$_('skill.modification.notkey.label')} :
  </h1>

  {#if canManageOrder}
    <button
      class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
      on:click={openModal(EditSkillOrder, {
        key: false,
        responsibilitySkillList: $ResponsibilitySkillsNotKey,
      })}>
      {$_('global.buttons.order')}
    </button>
  {/if}
</div>

{#if $ResponsibilitySkillsNotKey?.length > 0}
  <FlatTable bind:loading col="5">
    <span slot="headers" class="vtmn-contents">
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12">Type</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
        >{$_('skill.table.name')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12"
        >{$_('skill.table.description')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12"
        >{$_('branches.name.single')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12"
        >{$_('skill.table.responsibilities')}</th>
      <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12"
        >{$_('global.actions')}</th>
    </span>

    <span slot="body" class="vtmn-contents">
      {#each $ResponsibilitySkillsNotKey as item}
        <RawSkill bind:skill={item.skill} bind:responsibilitySkill={item} />
      {/each}
    </span>
  </FlatTable>
{:else}
  <p class="vtmn-font-thin vtmn-text-white">{$_('skill.not_found.not_key')}</p>
{/if}

<Modal>
  <ModalButton bind:openModal />
</Modal>
