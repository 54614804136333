<script>
  import ListResponsibilities from './ListResponsibilities.svelte';
  import { Responsibilities } from '../../../stores/responsibilities.store';
  import { PaginationModel } from '../../../models/pagination.model';
  import { responsibilityService } from '../../../services/responsibility.service';
  import { onMount } from 'svelte';
  import Pagination from '../../Generics/Paginator/Pagination.svelte';
  import FlatTable from '../../Generics/Table/FlatTable.svelte';
  import { _ } from 'svelte-i18n';
  import { UserInfos } from '../../../stores/userInfo.store';
  import Modal from 'svelte-simple-modal';
  import ModalButton from '../../Generics/Modal/ModalButton.svelte';
  import {
    isAdmin,
    isBranchContributorForAtLeastOneBranch,
    isBranchLeaderForAtLeastOneBranch,
  } from '../../Auth/rights.utils';
  import ResponsibilityCreationJourney from './ResponsibilityCreationJourney.svelte';
  import BottomPageLogo from '../../Navigation/BottomPageLogo.svelte';

  let loading = false;
  let timer;
  let labelSearch = '';
  let descriptionSearch = '';
  let pagination = new PaginationModel();
  let current = 1;
  let openModal;
  $: hasRespRights =
    isAdmin($UserInfos) ||
    isBranchLeaderForAtLeastOneBranch($UserInfos) ||
    isBranchContributorForAtLeastOneBranch($UserInfos);

  async function retrieveResponsibilities(evt = undefined) {
    loading = true;
    if (evt != undefined) {
      current = evt.detail;
      loading = true;
    }
    const params = {
      params: {
        page: current - 1,
        label: labelSearch !== '' ? labelSearch : '',
        fullLabel: descriptionSearch !== '' ? descriptionSearch : '',
      },
    };
    await responsibilityService.getAllWithPagination(params).then(res => {
      Responsibilities.set(res[0]);
      pagination = res[1];
      loading = false;
    });
  }

  function onInputSearches() {
    if (labelSearch === '' && descriptionSearch === '') {
      void retrieveResponsibilities();
    } else {
      //Timeout to avoid multiple API calls
      clearTimeout(timer);
      timer = setTimeout(() => {
        current = 1;
        void retrieveResponsibilities();
      }, 500);
    }
  }

  onMount(() => {
    void retrieveResponsibilities();
  });
</script>

<div class="vtmn-flex vtmn-flex-wrap vtmn-mb-5">
  <h1 class="vtmn-font-semi-bold vtmn-text-5xl vtmn-text-center">
    {$_('responsibilities.title')}
  </h1>
  {#if hasRespRights}
    <Modal>
      <ModalButton>
        <button
          class="vtmn-btn vtmn-btn_size--small vtmn-mr-0 vtmn-my-auto vtmn-ml-auto"
          on:click={openModal(ResponsibilityCreationJourney)}>
          {$_('responsibilities.modification.create')}
        </button>
      </ModalButton>
    </Modal>
  {/if}
</div>

<FlatTable bind:loading col="5">
  <span slot="headers" class="vtmn-contents">
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-3/12">
      <input
        type="search"
        placeholder={$_('responsibilities.table.name')}
        bind:value={labelSearch}
        on:input={onInputSearches}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-5/12">
      <input
        type="search"
        placeholder={$_('responsibilities.table.description')}
        bind:value={descriptionSearch}
        on:input={onInputSearches}
        class="vtmn-px-3 vtmn-py-2 vtmn-w-full vtmn-text-sm focus:vtmn-outline-none vtmn-border-none vtmn-bg-white" />
    </th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12"
      >{$_('job.name.plural')}</th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-1/12 vtmn-text-xs"
      >{$_('responsibilities.table.skills')}</th>
    <th class="vtmn-px-4 vtmn-py-2 vtmn-font-thin vtmn-w-2/12">{$_('global.actions')}</th>
  </span>
  <span slot="body" class="vtmn-contents">
    <ListResponsibilities {loading} />
  </span>
</FlatTable>

<div class="vtmn-flex">
  <BottomPageLogo />
  <div class="vtmn-ml-auto vtmn-mr-2">
    <Pagination
      small
      bind:current
      num_items={pagination?.numItems}
      fromElement={pagination?.from}
      toElement={pagination?.to}
      per_page={pagination?.perPage}
      on:navigate={retrieveResponsibilities} />
  </div>
</div>

<Modal>
  <ModalButton bind:openModal />
</Modal>
