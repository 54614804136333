<script>
  export let contentCss = false;
</script>

<!--This component is used to add tr element into a Table-->
<tr
  class:vtmn-contents={contentCss}
  class="hover:vtmn-bg-grey-light-4 vtmn-border-b vtmn-border-grey-light-2 vtmn-py-10">
  <slot />
</tr>
