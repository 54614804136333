import ApiService from './api.service';
import { RightsUserModel } from '../models/rightsUserModel';
export class RightsUserService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/me';
        this.entity = RightsUserModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const rightsUserService = new RightsUserService();
