import ApiService from './api.service';
import { RoleTranslationModel } from '../models/roleTranslation.model';
export class RoleTranslationsService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/role_translations';
        this.entity = RoleTranslationModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const roleTranslationsService = new RoleTranslationsService();
