import ApiService from './api.service';
import { SkillModel } from '../models/skill.model';
export class SkillsService extends ApiService {
    constructor() {
        super(...arguments);
        this.endpoint = '/skills';
        this.entity = SkillModel;
        this.baseUrl = HR_JOBS_BASE_URL;
        this.apiKey = HR_JOBS_X_API_KEY;
    }
}
export const skillsService = new SkillsService();
